import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { pipe, map, filter } from 'lodash/fp';
import cn from 'classnames';

import { withRouter } from 'react-router';
import {
  Box,
  ViewLayout,
  ViewLayoutContent,
  List,
  ListItem,
  ToggleSwitch,
  Text,
} from '@jobox/react-components-library/mobile';

import { Head } from './components';
import { withProps, compose } from '../../lib/hocUtils';
import { formatDate } from './utlis';

import classes from './observedHolidaysSelection.module.scss';

const SCROLL_TO_THRESHOLD = 20;

class ObservedHolidaysSelection extends Component {
  constructor(props) {
    super(props);

    this.viewLayoutContentRef = React.createRef();
  }

  componentDidMount() {
    const { holidaysStore, userId, holidayId } = this.props;

    holidaysStore.fetchUserHolidays(userId);

    if (this.viewLayoutContentRef.current && holidayId) {
      const contentElement = this.viewLayoutContentRef.current;
      const elementToScroll = contentElement.querySelector(`#holiday-${holidayId}`);

      if (elementToScroll) {
        contentElement.scrollTo({
          top: elementToScroll.offsetTop - contentElement.offsetTop - SCROLL_TO_THRESHOLD,
          behavior: 'smooth',
        });
      }
    }
  }

  render() {
    const {
      holidaysStore,
      userId,
      calendarId: calendarIdStr,
      holidayId,
    } = this.props;
    const calendarId = parseInt(calendarIdStr, 10);
    const calendar = holidaysStore.observableHolidays.find(({ id }) => id === calendarId) || {};
    const calendarHolidays = calendar.holidays || [];
    const selectedIds = pipe(
      filter({ toggled: true }),
      map('id'),
    )(calendarHolidays);

    return (
      <ViewLayout className={classes.layout}>
        <Head
          title={calendar.name}
          backTo={`/connectTechSettings/${userId}/holidays/select`}
          description={`${selectedIds.length} days selected`}
          className={classes.head}
        />
        <ViewLayoutContent innerRef={this.viewLayoutContentRef}>
          <List className={classes.list}>
            {
              calendarHolidays.map((holiday) => (
                <ListItem
                  id={holiday.id}
                  key={holiday.id}
                  selectable={false}
                  className={cn({
                    [classes['list-item--highlighted']]: holiday.id === parseInt(holidayId, 10),
                  })}
                >
                  <Box
                    className={classes['list-item-content']}
                    id={`holiday-${holiday.id}`}
                  >
                    <Box className={classes['list-item-content__info']}>
                      <Text>{ holiday.name }</Text>
                      <Text type="alt">
                        { formatDate(holiday.startDate, holiday.endDate) }
                      </Text>
                    </Box>
                    <ToggleSwitch
                      className={classes['list-item-content__switch']}
                      enabled={holiday.toggled}
                      onChange={(currentEnabled) => {
                        holidaysStore.toggleHoliday(calendarId, holiday.id, currentEnabled);
                      }}
                    />
                  </Box>
                </ListItem>
              ))
            }
          </List>
        </ViewLayoutContent>
      </ViewLayout>
    );
  }
}

ObservedHolidaysSelection.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  holidaysStore: PropTypes.shape({
    fetchUserHolidays: PropTypes.func,
    toggleHoliday: PropTypes.func,
    observableHolidays: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        holidays: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            startDate: PropTypes.number,
            endDate: PropTypes.number,
            toggled: PropTypes.bool,
          }),
        ),
      }),
    ),
  }),
  userId: PropTypes.string,
  calendarId: PropTypes.string,
  holidayId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default compose(
  withProps(({ match: { params: { userId, calendarId, holidayId } } }) => ({
    userId,
    calendarId,
    holidayId,
  })),
  withRouter,
  inject(({ holidaysStore }) => ({
    holidaysStore,
  })),
  observer,
)(ObservedHolidaysSelection);
