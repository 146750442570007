import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowIcon from '../../../images/ConnectTechSettings/icon-right-arrow.svg';

import './NavSection.scss';

const NavSection = ({
  icon,
  title,
  subtitle,
  className,
  isLink,
}) => (
  <div className={classNames('connect-tech-settings__main-nav-section', className)}>
    {
      icon && (
        <div className="connect-tech-settings__main-nav-section__icon">
          <img src={icon} alt={`${title} icon`} />
        </div>
      )
    }
    <div className="connect-tech-settings__main-nav-section__text-area">
      <div className={classNames('connect-tech-settings__main-nav-section__title', { centered: !subtitle })}>
        {title}
      </div>
      {
        subtitle && (
          <div className="connect-tech-settings__main-nav-section__subtitle">
            {subtitle}
          </div>
        )
      }
    </div>
    {
      isLink && (
        <div className="connect-tech-settings__main-nav-section__arrow">
          <img src={ArrowIcon} alt="edit" />
        </div>
      )
    }
  </div>
);

NavSection.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  subtitle: PropTypes.string,
  className: PropTypes.string,
  isLink: PropTypes.bool,
};

export default NavSection;
