/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Button } from 'reactstrap';

import {
  EstimateBreakdown,
  EstimateTechHeader,
  EstimateReference,
  EstimateSummary,
  EstimateLayoutWrap,
  EstimateLayout,
  EstimateLayoutBody,
  Text,
  WarrantyInfo,
} from '../../components';
import EstimateType from '../../constants/EstimateType';
import { withProps, compose } from '../../lib/hocUtils';

import '../../styles/pages/estimates-common.scss';
import './ApprovedEstimatePage.scss';

class ApprovedEstimatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: null,
    };
  }

  componentDidMount() {
    const {
      userId,
      jobId,
      approvedEstimateStore,
      receiptSettingsStore,
      identificationStore,
      jobStore,
    } = this.props;

    this.setState({ loading: true });
    Promise.all([
      approvedEstimateStore.loadEstimate(userId, jobId),
      receiptSettingsStore.loadReceiptSettings(userId),
      identificationStore.loadIdentification(userId),
      jobStore.loadJob(jobId),
      jobStore.loadJobStatus(jobId),
    ]).catch((loadingError) => {
      this.setState({ loadingError });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const {
      approvedEstimateStore: { estimate },
      receiptSettingsStore: { receiptSettings },
      identificationStore: { identification },
      jobStore: { job, jobStatus },
    } = this.props;
    const { loading, loadingError } = this.state;

    if (loadingError) {
      return (
        <div>Unable to load this document</div>
      );
    }

    if (!estimate || !receiptSettings || !identification || !job || !jobStatus || loading) {
      return null;
    }

    const {
      approved,
      signatureURL,
      serviceProviderPhone,
      jobRefId,
      jobMarketplace,
      notes,
      customerName,
      customerEmail,
      customerAddress,
      customerPhone,
      type,
      warranty,
      subtotal,
      tax,
      parts_price,
      labor,
      serviceProviderCompany,
      descriptionOfWork,
      approved_at,
      partnerBrand,
    } = estimate;

    const {
      showLicense,
      useReceiptContactNumber,
      receiptContactNumber,
    } = receiptSettings;

    const { isEstimateApprovable } = jobStatus;
    const isInvoice = type === EstimateType.Invoice;
    const maybeLicense = identification.find((id) => id.entityType === 'BUSINESS_LICENSE');
    const companyContactPhone = useReceiptContactNumber
      ? receiptContactNumber
      : serviceProviderPhone;
    const companyLicense = showLicense
      ? (maybeLicense && maybeLicense.entityId) : '';

    return (
      <EstimateLayoutWrap>
        {
          !isEstimateApprovable && (
            <div className="estimates-common__call-containter">
              <span>
                This
                {' '}
                { isInvoice ? 'invoice' : 'estimate' }
                {' '}
                is out of date
              </span>
              <Button
                color="primary"
                size="lg"
                block
                className="estimates-common__call-button"
              >
                <a href="tel:123123123">Call +555 111 111</a>
              </Button>
            </div>
          )
        }

        <EstimateLayout isInvoice={isInvoice}>
          <EstimateTechHeader
            logo={partnerBrand?.logoUrl}
            license={companyLicense}
            name={serviceProviderCompany}
            phone={companyContactPhone}
          />

          <EstimateReference
            jobRefId={jobRefId}
            brandColor={partnerBrand?.hexColor}
          />

          <EstimateSummary
            customerName={customerName}
            customerPhone={customerPhone}
            customerEmail={customerEmail}
            customerAddress={customerAddress}
            descriptionOfWork={descriptionOfWork}
            maskPhone={jobMarketplace}
          />

          <EstimateLayoutBody>
            <EstimateBreakdown
              labor={labor}
              partsPrice={parts_price}
              tax={tax}
              subtotal={subtotal}
              isInvoice={isInvoice}
            />
          </EstimateLayoutBody>
          {
            // Display Warranty info or Notes based on the type
            isInvoice ? (
              warranty && (
                <WarrantyInfo
                  className="estimates-common__warranty"
                  content={warranty}
                />
              )
            ) : (
              notes && (
                <div className="estimates-common__notes">
                  <Text content={notes} />
                </div>
              )
            )
          }

          {
            approved && (
              <>
                <div className="approved_estimate-page__signature-container">
                  <div className="approved_estimate-page__signature-info">
                    <span className="approved_estimate-page__signature-info__date">{approved_at}</span>
                    <span className="approved_estimate-page__signature-info__label">Customer Approval</span>
                  </div>
                  <img className="approved_estimate-page__signature-img" alt="customer-signature" src={signatureURL} />
                </div>
                <div className="approved_estimate-page__signature-footnote">
                  With my signature, I authorize the services described above,
                  {' '}
                  and agree to pay the full amount upon completion of the job.
                </div>
              </>
            )
          }
        </EstimateLayout>
      </EstimateLayoutWrap>
    );
  }
}
ApprovedEstimatePage.propTypes = {
  approvedEstimateStore: PropTypes.shape({
    estimate: MobXPropTypes.observableObject,
    loadEstimate: PropTypes.func.isRequired,
  }).isRequired,

  receiptSettingsStore: PropTypes.shape({
    receiptSettings: MobXPropTypes.observableObject,
    loadReceiptSettings: PropTypes.func.isRequired,
  }).isRequired,

  identificationStore: PropTypes.shape({
    identification: MobXPropTypes.observableObject,
    loadIdentification: PropTypes.func.isRequired,
  }).isRequired,

  jobStore: PropTypes.shape({
    job: MobXPropTypes.observableObject,
    jobStatus: MobXPropTypes.observableObject,
    loadJob: PropTypes.func.isRequired,
    loadJobStatus: PropTypes.func.isRequired,
  }).isRequired,

  userId: PropTypes.string,
  jobId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId, jobId } } } = ownProps;

    return {
      userId,
      jobId,
    };
  }),
  inject('approvedEstimateStore'),
  inject('receiptSettingsStore'),
  inject('identificationStore'),
  inject('jobStore'),
  inject('occupationsStore'),
  observer,
)(ApprovedEstimatePage);
