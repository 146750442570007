import { formatPhoneNumber } from '../lib/phone';

class ReceiptSettingsModel {
  constructor(receiptSettings) {
    this.userId = receiptSettings.show_company_name;
    this.showCompanyName = receiptSettings.show_company_name;
    this.showLicense = receiptSettings.show_license;
    this.useReceiptContactNumber = receiptSettings.use_receipt_contact_number;
    this.receiptContactNumber = formatPhoneNumber(receiptSettings.receipt_contact_number);
    this.modifiedAt = receiptSettings.modified_at;
  }
}

export default ReceiptSettingsModel;
