/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Label,
  Input,
  InputMask,
  Formik,
  Form,
  FormGroup,
  FormFeedback,
  ReCaptchaInput,
} from '../../../components';
import Yup from '../../../lib/yup';

import './QuestionForm.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().label('Name').required(),
  email: Yup.string().label('Email').email().required(),
  phone: Yup.string()
    .label('Phone')
    .matches(/\(\d{3}\)\d{3}-\d{4}/i, '${path} should be in format (###)###-####'),
  message: Yup.string().label('Message').required(),
});

class QuestionForm extends Component {
  handleQuestionSubmit = (formValues) => {
    const { onSubmit } = this.props;

    onSubmit(formValues);
  }

  render() {
    const { className, submitting, initialValues } = this.props;
    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          message: '',
          ...initialValues,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={this.handleQuestionSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className={classnames('question-form', className)} noValidate>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Full Name"
                value={values.name}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.name && errors.name)}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                placeholder="email@example.com"
                value={values.email}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.email && errors.email)}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone">Phone (optional)</Label>
              <InputMask
                id="phone"
                name="phone"
                className="question-form__phone"
                placeholder="(###)###-####"
                mask="(999)999-9999"
                value={values.phone}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.phone && errors.phone)}
              />
              <FormFeedback>{errors.phone}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="message">Message</Label>
              <Input
                id="message"
                type="textarea"
                rows="4"
                name="message"
                placeholder="Please describe your question or issue in detail"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.message && errors.message)}
              />
              <FormFeedback>{errors.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="mt-3">
              <ReCaptchaInput
                ref={this.recaptchaRef}
                name="captchaToken"
              />
              <FormFeedback>{errors.captchaToken}</FormFeedback>
            </FormGroup>
            <Button type="submit" color="green" disabled={submitting} className="question-form__submit-button">
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        )}
      />
    );
  }
}

QuestionForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    message: PropTypes.string,
  }),
  submitting: PropTypes.bool,
};

export default QuestionForm;
