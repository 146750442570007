/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import {
  Box,
  Button,
  Card,
  CardHeader,
  ListItem,
  List,
  Text,
  ViewLayout,
  ViewLayoutContent,
  Pill,
} from '@jobox/react-components-library/mobile';

import { ConfirmationModal, Head, linkAdapter } from './components';
import { HolidayType } from '../../constants/HolidayType';
import { withProps, compose } from '../../lib/hocUtils';
import { formatDate, isSameDay } from './utlis';

import classes from './holidaysSettings.module.scss';

class HolidaysSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idToRemove: null,
    };
  }

  componentDidMount() {
    const { holidaysStore, userId } = this.props;

    holidaysStore.fetchUserHolidays(userId, { force: true });
  }

  removePersonalHolidayHandler = (holidayId) => {
    const { holidaysStore } = this.props;

    holidaysStore.deletePersonalHoliday(holidayId);

    this.setState({ idToRemove: null });
  }

  render() {
    const { holidaysStore, userId, location: { pathname: currentPath } } = this.props;
    const { idToRemove } = this.state;
    const holidayToRemove = idToRemove
      ? holidaysStore.upcomingHolidays.find(({ id }) => id === idToRemove)
      : null;

    return (
      <ViewLayout>
        <Head
          title="Holidays & Vacations"
          description="Enter days and periods when you don’t want to receive any new jobs offers from Jobox Connect."
          backTo={`/connectTechSettings/${userId}`}
        />
        <ViewLayoutContent>
          <Button
            color="primary"
            className={cn(classes.cta, classes['cta--select'])}
            component={linkAdapter({ to: `${currentPath}/select` })}
          >
            Edit Observed Holidays
          </Button>
          <Button
            color="primary"
            className={cn(classes.cta, classes['cta--create'])}
            component={linkAdapter({ to: `${currentPath}/create` })}
          >
            Add Personal Day Off
          </Button>

          {
            holidaysStore.upcomingHolidays.length > 0 && (
              <Card className={classes.upcoming}>
                <CardHeader>
                  Upcoming days off
                </CardHeader>
                <List condensed>
                  {
                    holidaysStore.upcomingHolidays.map(({
                      id,
                      startDate,
                      endDate,
                      calendarId,
                      name,
                      calendarName,
                      type,
                    }) => (
                      <ListItem
                        id={id}
                        key={calendarId ? `${calendarId}_${id}` : id}
                        className={cn({
                          [classes['list-item--holiday-personal']]: type === HolidayType.Personal,
                        })}
                        selectable={type === HolidayType.Observed}
                        renderIndicator={() => (
                          <Box className={classes['arrow-indicator']} />
                        )}
                        component={
                          type === HolidayType.Observed
                            ? linkAdapter({ to: `${currentPath}/select/${calendarId}/${id}` })
                            : undefined
                        }
                      >
                        <Box className={classes['upcoming-list-item']}>
                          <Box className={classes['upcoming-list-item__info']}>
                            <Text>
                              { formatDate(startDate, endDate) }
                            </Text>
                            <Text type="alt">
                              { type === HolidayType.Observed ? `${name} | ${calendarName}` : 'Personal Day Off' }
                            </Text>
                          </Box>
                          {
                            type === HolidayType.Personal && (
                              <Pill
                                color="danger"
                                className={classes['upcoming-list-item__action']}
                                onClick={() => { this.setState({ idToRemove: id }); }}
                              >
                                Remove
                              </Pill>
                            )
                          }
                        </Box>
                      </ListItem>
                    ))
                  }
                </List>
              </Card>
            )
          }

          <ConfirmationModal
            isOpen={!!idToRemove}
            actions={(
              <>
                <Button
                  color="danger"
                  onClick={() => { this.removePersonalHolidayHandler(idToRemove); }}
                >
                  {
                    holidayToRemove && isSameDay(holidayToRemove.startDate, holidayToRemove.endDate)
                      ? 'Remove day off'
                      : 'Remove days off'
                  }
                </Button>
                <Button color="primary" onClick={() => { this.setState({ idToRemove: null }); }}>
                  Cancel
                </Button>
              </>
            )}
          >
            <Box className={classes['confirm-modal-content']}>
              <Text className={classes['confirm-modal-content__title']}>
                {
                  holidayToRemove && isSameDay(holidayToRemove.startDate, holidayToRemove.endDate)
                    ? 'Remove this day off?'
                    : 'Remove these days off?'
                }
              </Text>
              <Text className={classes['confirm-modal-content__details']}>
                {
                  holidayToRemove && formatDate(holidayToRemove.startDate, holidayToRemove.endDate)
                }
              </Text>
            </Box>
          </ConfirmationModal>
        </ViewLayoutContent>
      </ViewLayout>
    );
  }
}

HolidaysSettings.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  holidaysStore: PropTypes.shape({
    fetchUserHolidays: PropTypes.func,
    deletePersonalHoliday: PropTypes.func,
    upcomingHolidays: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
      }),
    ),
  }),
  userId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default compose(
  withProps(({ match: { params: { userId } } }) => ({
    userId,
  })),
  withRouter,
  inject(({ holidaysStore }) => ({
    holidaysStore,
  })),
  observer,
)(HolidaysSettings);
