import React from 'react';

export const withProps = (mapper = () => {}) => (
  (component) => function WithPropsWrapper(props) {
    const TargetComponent = component;

    return (
      <TargetComponent
        {...props}
        {...mapper(props)}
      />
    );
  }
);

export const compose = (...funcs) => (
  funcs.reduce((a, b) => (...args) => a(b(...args)), (arg) => arg)
);
