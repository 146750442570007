import { Component } from 'react';
import PropTypes from 'prop-types';

import { logger } from '../lib/logger';
import config from '../config';

class Intercom extends Component {
  componentDidMount() {
    if (!config.INTERCOM_APP_ID) {
      logger.warn('Intercom APP ID is empty - skipping initialization');
    }

    if (config.INTERCOM_APP_ID && !window.Intercom) {
      /* eslint-disable */
      (function(w, d, id, s, x) {
        function i() {
            i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
            i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, config.INTERCOM_APP_ID);
      /* eslint-enable */
    }
  }

  componentDidUpdate({ open: prevOpen }) {
    const { open } = this.props;

    if (prevOpen !== open && open && config.INTERCOM_APP_ID) {
      window.intercomSettings = {
        app_id: config.INTERCOM_APP_ID,
      };
      window.Intercom('boot', window.intercomSettings);
      window.Intercom('show');
    }
  }

  componentWillUnmount() {
    if (window.Intercom) {
      window.Intercom('shutdown');

      delete window.Intercom;
    }
  }

  render() {
    return null;
  }
}

Intercom.propTypes = {
  open: PropTypes.bool,
};

export default Intercom;
