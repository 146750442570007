import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { FeatureFlagRoute, FeatureFlagsProvider } from '@jobox/react-components-library/mobile';

import { logger } from './lib/logger';
import { reportError } from './lib/errorReporter';
import { withAnalytics } from './components';
import ldFlags from './constants/LaunchDarklyFlags';
import config from './config';

import ReceiptFeedbackPage from './pages/ReceiptFeedbackPage';
import ReceiptPage from './pages/ReceiptPage';
import SearchPage from './pages/SearchPage';
import TransactionPage from './pages/TransactionPage';
import RequestSentPage from './pages/RequestSentPage';
import HelpPage from './pages/HelpPage';
import UnsubscribePage from './pages/UnsubscribePage';
import EstimatePage from './pages/EstimatePage';
import EstimatePreviewsPage from './pages/EstimatePreviewsPage';
import ApprovedEstimatePage from './pages/ApprovedEstimatePage/ApprovedEstimatePage';
import RemoteApprovalPage from './pages/RemoteApprovalPage/RemoteApprovalPage';
import EstimatePdf from './pages/EstimatePdf';
import ConnectTechRoutes from './pages/ConnectTechSettings/ConnectTechRoutes';
import OnboardingFlow from './pages/OnboardingAutomation/OnboardingFlow';
import ConnectTechHolidays from './pages/ConnectTechHolidays/ConnectTechHolidays';

import './styles/main.scss';
import './styles/react-components-library/main.scss';

class App extends React.Component {
  componentDidCatch(error) {
    logger.error(error);
    reportError(error);
  }

  render() {
    const { userId } = this.props;

    return (
      <FeatureFlagsProvider
        userId={userId}
        clientId={config.LAUNCHDARKLY_CLIENT_ID}
      >
        <Switch>
          <Route pageName="ReceiptFeedbackPage" path="/receipts/:receiptKey/feedback" component={ReceiptFeedbackPage} />
          <Route pageName="ReceiptPage" path="/receipts/:receiptKey" component={ReceiptPage} />
          <Route pageName="TransactionPage" path="/transactions/:jobRefId" component={TransactionPage} />
          <Route pageName="RequestSentPage" path="/sent" component={RequestSentPage} />
          <Route pageName="HelpPage" path="/help" component={HelpPage} />
          <Route pageName="UnsubscribePage" path="/unsubscribe" component={UnsubscribePage} />
          <Route pageName="EstimatePage" path="/estimates/:userId/:jobId" component={EstimatePage} />
          <Route pageName="EstimatePreviewsPage" path="/v1/estimatePreviews/:userId/:jobId" component={EstimatePreviewsPage} />
          <Route pageName="RemoteApprovalPage" path="/v1/estimatesRemoteSigning/:userId/:jobId" component={RemoteApprovalPage} />
          <Route pageName="ApprovedEstimatePage" path="/v1/estimates/:userId/:jobId" component={ApprovedEstimatePage} />
          <Route pageName="EstimatePdfPage" path="/v1/remoteEstimates/:userId/:jobId" component={EstimatePdf} />
          <Route path="/connectTechSettings/:userId/onboarding" render={(props) => <OnboardingFlow {...props} />} />
          <FeatureFlagRoute
            flag={ldFlags.HolidaysAndBlackoutPeriods}
            path="/connectTechSettings/:userId/holidays"
            render={(props) => <ConnectTechHolidays {...props} />}
          />
          <Route path="/connectTechSettings/:userId" render={(props) => <ConnectTechRoutes {...props} />} />
          {/*
            Dont put new pages after Search page because they will not work
            because a rule for search matches anything after slash to be search term
          */}
          <Route pageName="SearchPage" path="/:jobRefId?" component={SearchPage} />
        </Switch>
      </FeatureFlagsProvider>
    );
  }
}
App.propTypes = {
  userId: PropTypes.string,
};

export default withAnalytics()(App);
