class ApiService {
  constructor(request) {
    this.request = request;
  }

  getOccupations() {
    return this.request
      .get('/occupations')
      .then((response) => response.data);
  }

  getTransactionByJobRefId(jobRefId) {
    return this.request
      .get(`/transactions/${jobRefId}`)
      .then((response) => response.data);
  }

  getTransactionByJobAndLast4(jobRefId, last4hash) {
    return this.request
      .get(`/transactions/${jobRefId}?cc=${last4hash}`)
      .then((response) => response.data);
  }

  createConversation(conversation) {
    return this.request
      .post('/conversations', conversation)
      .then((response) => response.data);
  }

  getReceipt(receiptKey, preview, timezone) {
    return this.request
      .get(`/receipts/${receiptKey}`, {
        headers: {
          Timezone: timezone,
        },
        params: preview ? { preview: true } : null,
      })
      .then((response) => response.data);
  }

  getRating(jobId) {
    return this.request
      .get(`/receipts/ratinglog/${jobId}`)
      .then((response) => response.data);
  }

  submitRating(jobId, payload) {
    return this.request
      .post(`/receipts/ratinglog/${jobId}`, payload)
      .then((response) => response.data);
  }

  updateRating(jobId, payload) {
    return this.request
      .put(`/receipts/ratinglog/${jobId}`, payload)
      .then((response) => response.data);
  }

  getEstimate(userId, jobId) {
    return this.request
      .get(`/estimates/${userId}/${jobId}`)
      .then((response) => response.data);
  }

  getApprovedEstimate(jobId) {
    return this.request
      .get(`/approvedEstimates/${jobId}`)
      .then((response) => response.data);
  }

  getEstimatePreviews(userId, jobId, timezone) {
    return this.request
      .get(`/estimatePreviews/${userId}/${jobId}`, {
        headers: {
          Timezone: timezone,
        },
      })
      .then((response) => response.data);
  }

  getReceiptSettings(userId) {
    return this.request
      .get(`/users/${userId}/receiptsettings`)
      .then((response) => response.data);
  }

  getIdentification(userId) {
    return this.request
      .get(`/identification/${userId}`)
      .then((response) => response.data);
  }

  getJob(jobId, timezone) {
    return this.request
      .get(`/jobs/${jobId}`, {
        headers: {
          Timezone: timezone,
        },
      })
      .then((response) => response.data);
  }

  getJobStatus(jobId, timezone) {
    return this.request
      .get(`/jobs/status/${jobId}`, {
        headers: {
          Timezone: timezone,
        },
      })
      .then((response) => response.data);
  }

  submitSignature(data, timezone) {
    return this.request
      .post('/signature', data, {
        headers: {
          Timezone: timezone,
        },
      })
      .then((response) => response.data);
  }

  getSignatureEstimateLog(userId, jobId, timezone) {
    return this.request
      .get(`/signature/estimate/${userId}/${jobId}`, {
        headers: {
          Timezone: timezone,
        },
      })
      .then((response) => response.data);
  }

  getConnectTechSettings(userId) {
    return this.request
      .get(`/connectTechSettings/${userId}`)
      .then((response) => response.data);
  }

  updateUserAvailability(userId, userAvailability) {
    return this.request
      .put(`connectTechSettings/${userId}/connect_availability/${userAvailability}`)
      .then((response) => response.data);
  }

  updateOptInToMarketplace(userId, optInToMarketplace, overrideAvailability) {
    return this.request
      .put(`connectTechSettings/${userId}/marketplace_opt_in/${optInToMarketplace}?override_availability=${overrideAvailability}`)
      .then((response) => response.data);
  }

  getIndepProfile(userId) {
    return this.request
      .get(`/connectTechSettings/${userId}/indep_profile`)
      .then((response) => response.data);
  }

  updateIndepProfile(userId, profile) {
    return this.request
      .put(`/connectTechSettings/${userId}/indep_profile`, profile)
      .then((response) => response.data);
  }

  getBasicProfile(userId) {
    return this.request
      .get(`/connectTechSettings/${userId}/basic_profile`)
      .then((response) => response.data);
  }

  updateBasicProfile(userId, profile) {
    return this.request
      .put(`/connectTechSettings/${userId}/basic_profile`, profile)
      .then((response) => response.data);
  }

  updateSmsNotification(userId, enabled) {
    return this.request
      .put(`/connectTechSettings/${userId}/sms`, { enabled })
      .then((response) => response.data);
  }

  getAutoBrands() {
    return this.request
      .get('connectTechSettings/autobrands/all')
      .then((response) => response.data);
  }

  getUserSkillAutobrands(userId, skillId) {
    return this.request
      .get(`/connectTechSettings/${userId}/skill/${skillId}/autobrand_with_years`)
      .then((response) => response.data);
  }

  createUserSkillAutobrand(userId, skillId, data) {
    return this.request
      .post(`/connectTechSettings/${userId}/skill/${skillId}/autobrand_with_years`, data)
      .then((response) => response.data);
  }

  bulkCreateSkillAutobrands(userId, skillId, data) {
    return this.request
      .post(`/connectTechSettings/${userId}/skill/${skillId}/autobrands_with_years`, data)
      .then((response) => response.data);
  }

  updateUserSkillAutobrand(userId, skillId, brandId, data) {
    return this.request
      .put(`/connectTechSettings/${userId}/skill/${skillId}/autobrand_with_years/brand/${brandId}`, data)
      .then((response) => response.data);
  }

  removeUserSkillAutobrand(userId, skillId, brandId) {
    return this.request
      .delete(`/connectTechSettings/${userId}/skill/${skillId}/autobrand_with_years/brand/${brandId}`)
      .then((response) => response.data);
  }

  removeAllUserSkillAutobrand(userId, skillId) {
    return this.request
      .delete(`/connectTechSettings/${userId}/skill/${skillId}/autobrand_with_years`)
      .then((response) => response.data);
  }

  getUserOccupation(userId) {
    return this.request
      .get(`/connectTechSettings/${userId}/occupation`)
      .then((response) => response.data);
  }

  getUserHolidays(userId) {
    return this.request
      .get(`/connectTechSettings/holidays/${userId}`)
      .then((response) => response.data);
  }

  createUserHoliday(userId, holidayData) {
    return this.request
      .post(`/connectTechSettings/holidays/${userId}`, holidayData)
      .then((response) => response.data);
  }

  updateUserHolidays(userId, holidaysUpdate) {
    return this.request
      .patch(`/connectTechSettings/holidays/${userId}`, holidaysUpdate)
      .then((response) => response.data);
  }

  deleteUserHoliday(userId, holidayToDelete) {
    return this.request
      .delete(`/connectTechSettings/holidays/${userId}`, {
        data: holidayToDelete,
      })
      .then((response) => response.data);
  }
}

export default ApiService;
