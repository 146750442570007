import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';

import { DefaultPageLayout } from '../../components';
import { HelpForm } from './components';

import './HelpPage.scss';

class HelpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      submittingError: null,
    };
  }

  handleFormSubmit = (formValues) => {
    const { conversationStore, history } = this.props;

    this.setState({
      submitting: true,
      submittingError: null,
    });

    conversationStore.createConversation(formValues).then(() => {
      history.push('/sent');
    }).catch((error) => {
      this.setState({ submittingError: error });
    }).finally(() => {
      this.setState({ submitting: false });
    });
  }

  render() {
    const { submitting, submittingError } = this.state;

    return (
      <DefaultPageLayout className="help-page">
        <h1 className="help-page__title">Please fill out the form so we can locate your transaction.</h1>

        <HelpForm
          className="help-page__help-form"
          onSubmit={this.handleFormSubmit}
          submitting={submitting}
        />

        {submittingError && (
          <div className="help-page__error">Error trying to submit request</div>
        )}

        <div className="help-page__footer">
          <p className="help-page__confirmation">
            By using this website, I confirm that the information
            {' '}
            inputted above is from my card billing statement,
            {' '}
            or I am authorized by the account holder to input
            {' '}
            the information from their billing statement.
          </p>

          <div className="help-page__return-to-main">
            <Link to="/">Return to Main Page</Link>
          </div>
        </div>
      </DefaultPageLayout>
    );
  }
}

HelpPage.propTypes = {
  conversationStore: PropTypes.shape({
    createConversation: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default inject('conversationStore')(HelpPage);
