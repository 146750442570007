import { action, observable, makeObservable } from 'mobx';

import IdentificationModel from '../models/IdentificationModel';

class IdentificationStore {
  identification = null;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      identification: observable,

      loadIdentification: action,
    });
  }

  loadIdentification(userId) {
    return this.apiService.getIdentification(userId)
      .then(action((identification) => {
        this.identification = identification.map(
          (idEntry) => new IdentificationModel(idEntry),
        );
      }));
  }
}

export default IdentificationStore;
