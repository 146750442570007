import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Box,
  UnstyledButton,
  Text,
  Header,
} from '@jobox/react-components-library/mobile';
import { linkAdapter } from './linkAdapter';

import backIcon from '../../../images/ConnectTechSettings/icon-left-arrow.svg';

import classes from './head.module.scss';

export const Head = ({
  title,
  description,
  backTo,
  className,
}) => (
  <Box className={cn(classes.head, className)}>
    {
      backTo && (
        <Box className={classes.head__back}>
          <UnstyledButton component={linkAdapter({ to: backTo })}>
            <img src={backIcon} alt="go back" />
          </UnstyledButton>
        </Box>
      )
    }
    {
      typeof title === 'string' ? (
        <Header className={classes.head__title}>{ title }</Header>
      ) : title
    }
    {
      typeof description === 'string' ? (
        <Text className={classes.head__description} multiline>{ description }</Text>
      ) : description
    }
  </Box>
);
Head.propTypes = {
  title: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.node,
  ),
  description: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.node,
  ),
  backTo: PropTypes.string,
  className: PropTypes.string,
};
