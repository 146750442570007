import {
  action,
  observable,
  makeObservable,
} from 'mobx';
import {
  pipe,
  find,
  get,
} from 'lodash/fp';
import moment from 'moment';

import HolidaysModel from '../models/ConnectTechSettings/HolidaysModel';

const toUtc = (timestamp) => moment(timestamp).utc(true).valueOf();

class HolidaysStore {
  userHolidays = null;
  currentUserId = null;
  isLoading = false;
  error = null;

  get upcomingHolidays() {
    return this.userHolidays ? this.userHolidays.upcomingHolidays : [];
  }

  get observableHolidays() {
    return this.userHolidays ? this.userHolidays.observedHolidays : [];
  }

  set observableHolidays(holidays) {
    if (this.userHolidays) {
      this.userHolidays.observedHolidays = holidays;
    }
  }

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      userHolidays: observable,
      isLoading: observable,
      error: observable,

      createPersonalHoliday: action,
      deletePersonalHoliday: action,
      toggleCalendar: action,
      toggleHoliday: action,
    });
  }

  callMutableApiService(callback) {
    this.isLoading = true;
    return callback().catch(action((error) => {
      this.error = error;
      // refetch the list to rollback anything that went wrong
      this.fetchUserHolidays(this.currentUserId, { force: true });
    })).finally(action(() => {
      this.isLoading = false;
    }));
  }

  fetchUserHolidays(userId, { force } = {}) {
    if (!this.userHolidays || force) {
      this.isLoading = true;
      this.apiService.getUserHolidays(userId)
        .then(action((holidays) => {
          this.currentUserId = userId;
          this.userHolidays = new HolidaysModel(holidays);

          makeObservable(this.userHolidays, {
            observedHolidays: observable,
            upcomingHolidays: observable,
          });
        }))
        .catch(action((error) => {
          this.error = error;
        }))
        .finally(action(() => {
          this.isLoading = false;
        }));
    }
  }

  createPersonalHoliday(userId, { startDate, endDate }) {
    return this.callMutableApiService(
      () => this.apiService.createUserHoliday(userId, {
        start: toUtc(startDate),
        end: toUtc(endDate),
      }),
    );
  }

  toggleCalendar(calendarId, enabled = true) {
    const calendar = this.observableHolidays.find(({ id }) => calendarId === id);

    if (calendar) {
      const updatedCalendarHolidays = calendar.holidays.map((holiday) => ({
        ...holiday,
        toggled: enabled,
      }));
      const holidaysToUpdate = updatedCalendarHolidays.map(({ id, toggled }) => ({
        id,
        toggled,
      }));

      calendar.holidays = updatedCalendarHolidays;

      this.callMutableApiService(
        () => this.apiService.updateUserHolidays(this.currentUserId, holidaysToUpdate),
      );
    }
  }

  toggleHoliday(calendarId, holidayId, enabled) {
    const holidayToUpdate = pipe(
      find({ id: calendarId }),
      get('holidays'),
      find({ id: holidayId }),
    )(this.observableHolidays);

    holidayToUpdate.toggled = enabled;

    this.callMutableApiService(
      () => this.apiService.updateUserHolidays(this.currentUserId, [
        {
          id: holidayToUpdate.id,
          toggled: enabled,
        },
      ]),
    );
  }

  deletePersonalHoliday(holidayId) {
    const holidayToDelete = this.upcomingHolidays.find(({ id }) => id === holidayId);

    if (holidayToDelete) {
      // Optimistically remove the holiday from the array
      this.upcomingHolidays.splice(
        this.upcomingHolidays.indexOf(holidayToDelete),
        1,
      );

      this.callMutableApiService(
        () => this.apiService.deleteUserHoliday(this.currentUserId, {
          id: holidayId,
          start: holidayToDelete.startDate,
          end: holidayToDelete.endDate,
        }),
      );
    }
  }
}

export default HolidaysStore;
