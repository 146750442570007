import moment from 'moment';

export const isSameDay = (startDate, endDate) => {
  const startDateMoment = typeof startDate === 'number' ? moment(startDate) : startDate;
  const endDateMoment = typeof endDate === 'number' ? moment(endDate) : endDate;

  return endDateMoment.diff(startDateMoment, 'hours') <= 24;
};

export const formatDate = (startDate, endDate) => {
  if (endDate) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);

    if (!isSameDay(startDate, endDate)) {
      return `${startDateMoment.utc().format('MMM Do')} - ${endDateMoment.utc().format('MMM Do')}`;
    }
  }
  return moment(startDate).utc().format('MMM Do');
};
