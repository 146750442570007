const config = {
  APP_ENV: process.env.REACT_APP_APP_ENV,
  GOOGLE_ANALYTICS_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
  SENTRY_DSN_SERVER: process.env.REACT_APP_SENTRY_DSN_SERVER,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  LAUNCHDARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
};

export default config;
