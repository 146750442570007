import React from 'react';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import { Input } from './Reactstrap';

const InputMask = ({ mask, value, ...rest }) => (
  <ReactInputMask mask={mask} maskChar=" " value={value} {...rest}>
    {(inputProps) => <Input {...inputProps} value={value} {...rest} />}
  </ReactInputMask>
);

InputMask.propTypes = {
  mask: PropTypes.string,
  value: PropTypes.string,
};

export default InputMask;
