import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatCCBrand } from '../../../lib/creditCardUtils';

import './PaymentSignature.scss';

class PaymentSignature extends Component {
  renderSignature() {
    const {
      signatureUrl,
      creditCardBrand,
      creditCardLast4Digits,
      creditCardBillingName,
      jobRefId,
    } = this.props;

    const signatureText = (
      `I ${creditCardBillingName ? `, ${creditCardBillingName},` : ''} approve paying the above amount
      with my ${creditCardBrand ? formatCCBrand(creditCardBrand) : 'credit card'}
      ${creditCardLast4Digits ? ` ending in ${creditCardLast4Digits} ` : ' '}
      for job reference #${jobRefId || 'N/A'} that was done to my full satisfaction.`
    );

    return (
      <>
        <div className="payment-signature__title">Signature</div>
        <img
          className="payment-signature__image"
          src={signatureUrl}
          alt="signature"
        />
        <div className="payment-signature__approval-text">
          {signatureText}
        </div>
      </>
    );
  }

  render() {
    const { signatureUrl, className } = this.props;

    if (!signatureUrl) {
      return null;
    }

    return (
      <div className={classnames('payment-signature', className)}>
        {this.renderSignature()}
      </div>
    );
  }
}
PaymentSignature.propTypes = {
  jobRefId: PropTypes.string,
  className: PropTypes.string,
  creditCardBrand: PropTypes.string,
  creditCardLast4Digits: PropTypes.string,
  creditCardBillingName: PropTypes.string,
  signatureUrl: PropTypes.string.isRequired,
};

export default PaymentSignature;
