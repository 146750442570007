import { action, observable, makeObservable } from 'mobx';

import EstimateModel from '../models/EstimateModel';

class EstimateStore {
  estimate = null;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      estimate: observable,

      loadEstimate: action,
      setCustomerName: action,
    });
  }

  loadEstimate(userId, jobId) {
    return this.apiService.getEstimate(userId, jobId)
      .then(action((estimate) => {
        this.estimate = new EstimateModel(estimate);
      }));
  }

  setCustomerName(val) {
    this.estimate.customer_name = val;
  }
}

export default EstimateStore;
