import {
  observable,
  makeObservable,
  action,
  computed,
  toJS,
  runInAction,
} from 'mobx';
import _ from 'lodash';

import days from '../lib/days';
import IndependentProfileModel from '../models/ConnectTechSettings/IndependentProfileModel';
import { defaultTimeslot } from '../constants/ConnectTechSettings';

const apiToLocalSchedule = (apiSchedule) => apiSchedule
  .sort((a, b) => a.weekday > b.weekday)
  .map((s, id) => ({
    id,
    timeSlots: s.time_ranges.slice().sort((a, b) => a.start_hour - b.start_hour),
    name: days[s.weekday].name,
    enabled: s.time_ranges.length > 0,
    weekday: s.weekday,
    fullDay: (s.time_ranges.length > 0) && s.time_ranges[0].display === '0:00-23:59',
  }));

const localToApiSchedule = (localSchedule) => localSchedule
  .map((s) => ({
    weekday: s.weekday,
    time_ranges: s.timeSlots,
  }));

const formatTimeForApi = (time) => {
  const [hour, min] = time.split(':').map((v) => parseInt(v, 10));

  if (hour > 12) {
    return `${hour - 12}:${min}:00 PM`;
  }
  return hour === 12 ? `${time}:00 PM` : `${time}:00 AM`;
};

class IndependentProfileStore {
  constructor(apiService, userId, independentProfile) {
    this.apiService = apiService;

    this.userId = userId;
    this.loading = false;
    this.error = false;
    // To make the observable
    // track properly it's nested fields,
    // the observed object needs to have those
    // fields available at creation, it can't go
    // from null -> filledWithData. The tracking won't work
    // in that case
    this.independentProfile = {
      availability: {
        schedule: [],
      },
    };
    this.dayInfo = {
      timeSlots: defaultTimeslot,
    };

    if (independentProfile) {
      Object.assign(
        this.independentProfile,
        new IndependentProfileModel(independentProfile),
      );

      if (!this.independentProfile.availability) {
        this.independentProfile = {
          ...this.independentProfile,
          availability: {
            schedule: [],
          },
        };
      }
    }

    makeObservable(this, {
      loading: observable,
      error: observable,
      independentProfile: observable,
      dayInfo: observable,

      schedule: computed,

      setDayInfo: action,
      setNewSchedule: action,
      addNewWindow: action,
      onScheduleChanged: action,
    });
  }

  setDayInfo = (newDayInfo) => {
    this.dayInfo = newDayInfo;
  }

  // Exposed observables ------------------------
  // This is marked as computed to not recreate
  // a new reference everytime the getter would be called
  get schedule() {
    // Return an array of all weekdays, with the actual
    // schedule filled if profile is loaded
    // day 1 - monday
    const { schedule } = this.independentProfile.availability;
    const outSchedule = ([
      { time_ranges: [], weekday: 1 },
      { time_ranges: [], weekday: 2 },
      { time_ranges: [], weekday: 3 },
      { time_ranges: [], weekday: 4 },
      { time_ranges: [], weekday: 5 },
      { time_ranges: [], weekday: 6 },
      { time_ranges: [], weekday: 7 },
    ]).map((s) => {
      const profileSchedEntry = schedule.find((ps) => ps.weekday === s.weekday);
      // Return the found entry from loaded profile or
      // a default one from the above array otherwise
      return profileSchedEntry || s;
    });

    return apiToLocalSchedule(outSchedule);
  }
  set schedule(newSchedule) {
    const prevSchedule = this.schedule;

    runInAction(() => {
      this.independentProfile.availability.schedule = localToApiSchedule(newSchedule);
    });

    this.onScheduleChanged(newSchedule, prevSchedule);
  }

  set isLoading(loading) {
    runInAction(() => {
      this.loading = loading;
    });
  }

  get isLoading() {
    return this.loading;
  }

  set isError(error) {
    runInAction(() => {
      this.error = error;
    });
  }

  get isError() {
    return this.error;
  }

  // Actions ------------------------------------
  setNewSchedule(newTimeSlots, dayInfo) {
    const newSchedule = this.schedule.map((scheduleDay) => {
      if (scheduleDay === dayInfo) {
        return {
          ...dayInfo,
          enabled: newTimeSlots.length > 0,
          timeSlots: newTimeSlots,
        };
      }
      return scheduleDay;
    });
    // and assign it, the setter and the reaction will handle the update
    this.schedule = newSchedule;
  }

  addNewWindow(dayVal) {
    const dayInfo = this.schedule[dayVal - 1];
    const newTimeSlots = _.cloneDeep(dayInfo.timeSlots);

    if (newTimeSlots.length === 0) {
      // use 8-6pm as default if empty
      newTimeSlots.push(defaultTimeslot[0]);
    } else if (newTimeSlots[0].start_time !== '0:00') {
      // use 12am-start of first window
      const refTime = newTimeSlots[0];
      newTimeSlots.push({
        display: `0:00-${refTime.start_time}`,
        cross_day: null,
        start_time: '0:00',
        end_time: refTime.start_time,
        start_hour: 0,
        end_hour: refTime.start_hour,
        start_min: 0,
        end_min: refTime.start_min,
      });
    } else if (newTimeSlots[newTimeSlots.length - 1].end_time !== '23:59') {
      // use end of last window-11:59pm
      const refTime = newTimeSlots[newTimeSlots.length - 1];
      newTimeSlots.push({
        display: `${refTime.end_time}-23:59`,
        cross_day: null,
        start_time: refTime.end_time,
        end_time: '23:59',
        start_hour: refTime.end_hour,
        end_hour: 23,
        start_min: refTime.end_min,
        end_min: 59,
      });
    } else {
      // use 12:00am-12:00am instead of doing complex search logic, doesn't save on server on reload
      newTimeSlots.push({
        display: '0:00-0:00',
        cross_day: null,
        start_time: '00:00',
        end_time: '00:00',
        start_hour: 0,
        end_hour: 0,
        start_min: 0,
        end_min: 0,
      });
    }

    this.setNewSchedule(newTimeSlots, dayInfo);
  }

  onScheduleChanged(newSchedule, prevSchedule) {
    if (!_.isEqual(newSchedule, prevSchedule)) {
      // Convert form a Mobx proxied object to a plain JS object
      const plainIndepProfile = toJS(this.independentProfile);
      // Filter out the empty schedules
      const indepProfilePayload = {
        ...plainIndepProfile,
        availability_toggle: plainIndepProfile.availabilityToggle,
        availability_toggle_state: plainIndepProfile.availabilityToggleState,
        opt_in_to_marketplace: plainIndepProfile.optInToMarketplace,
        availability: {
          ...plainIndepProfile.availability,
          schedule: plainIndepProfile.availability.schedule
            .filter((s) => s.time_ranges.length > 0)
            .map((s) => ({
              ...s,
              time_ranges: s.time_ranges.map((ts) => (
                {
                  ...ts,
                  start_time: formatTimeForApi(ts.start_time),
                  end_time: formatTimeForApi(ts.end_time),
                }
              )),
            })),
        },
      };

      this.isLoading = true;
      this.isError = false;

      this.apiService.updateIndepProfile(this.userId, indepProfilePayload)
        .catch(action(() => {
          this.isError = true;

          // rollback the state to the previous value
          this.independentProfile.availability.schedule = localToApiSchedule(prevSchedule);
        })).finally(() => {
          this.isLoading = false;
        });
    }
  }

  updateUserAvailability(availabilityToggleState) {
    return this.apiService.updateUserAvailability(
      this.userId,
      availabilityToggleState,
    ).catch(() => {
      this.isError = true;
    });
  }

  updateOptInToMarketplace(optInToMarketplace, overrideAvailability) {
    return this.apiService.updateOptInToMarketplace(
      this.userId,
      optInToMarketplace,
      overrideAvailability,
    ).catch(() => {
      this.isError = true;
    });
  }
}

export default IndependentProfileStore;
