/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';

import { formatDate } from '../../lib/date';
import useResizeObserver from '../../hooks/useResizeObserver';

import signinactiveImage from '../../images/icon_signinactive.svg';
import signinactiveImageEmpty from '../../images/icon_signinactive-empty.svg';

import './SignaturePad.scss';

const SignaturePad = ({ setSignature }) => {
  const [date, setDate] = useState(null);
  const [empty, setEmpty] = useState(true);
  const [canvasWidth, setCanvasWidth] = useState(360);

  const sigCanvas = useRef(null);

  const handleClear = () => {
    setEmpty(true);
    setSignature(sigCanvas.current.clear());
    setDate(null);
  };

  const getSignatureImage = () => {
    setSignature(sigCanvas.current.getCanvas().toDataURL('image/png'));
  };

  const handleClick = () => {
    const now = formatDate(new Date(), 'M/D/YY h:mma');
    setDate(now);
    setEmpty(false);
    getSignatureImage(sigCanvas.current);
  };

  useEffect(() => {
    document.querySelector('#signature__canvas').addEventListener('touchend', handleClick);
    document.querySelector('#signature__canvas').addEventListener('click', handleClick);
  });

  const { refHandler } = useResizeObserver(({ contentRect }) => {
    setCanvasWidth(contentRect.width);
  });

  return (
    <div className="signature__container" ref={refHandler}>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{
          width: canvasWidth, height: 200, className: 'signature__sigCanvas', id: 'signature__canvas',
        }}
      />
      { empty
        ? (
          <>
            <div className="signature__line-empty" />
            <div className="signature__close" onClick={handleClear}>
              <img alt="signinactiveImageEmpty" src={signinactiveImageEmpty} />
            </div>
          </>
        )
        : (
          <>
            <div className="signature__line" />
            <div className="signature__close" onClick={handleClear}>
              <img alt="signinactiveImage" src={signinactiveImage} />
            </div>
          </>
        )}
      <div className="signature__clear" onClick={handleClear}>CLEAR</div>
      <div className="signature__date">{date || 'Please sign below using your mouse or finger.'}</div>
    </div>
  );
};

SignaturePad.propTypes = {
  setSignature: PropTypes.func,
};

export default SignaturePad;
