import HomeIcon from '../../images/ConnectTechSettings/icon-home.svg';
import CommercialIcon from '../../images/ConnectTechSettings/icon-commercial.svg';
import AutoIcon from '../../images/ConnectTechSettings/icon-auto.svg';
import SafeIcon from '../../images/ConnectTechSettings/icon-safe.svg';

class CategoryModel {
  constructor(category, skillset) {
    switch (category.category) {
      case 'Home':
        return {
          id: category.id,
          categoryName: 'Home & Office',
          icon: HomeIcon,
          services: skillset[category.id] ? skillset[category.id].numSkills : 0,
          enabled: false,
        };
      case 'Commercial':
        return {
          id: category.id,
          categoryName: 'Commercial',
          icon: CommercialIcon,
          services: skillset[category.id] ? skillset[category.id].numSkills : 0,
          enabled: false,
        };
      case 'Auto':
        return {
          id: category.id,
          categoryName: 'Auto',
          icon: AutoIcon,
          services: skillset[category.id] ? skillset[category.id].numSkills : 0,
          enabled: false,
        };
      case 'Safe':
        return {
          id: category.id,
          categoryName: 'Safe',
          icon: SafeIcon,
          services: skillset[category.id] ? skillset[category.id].numSkills : 0,
          enabled: false,
        };
      default:
        return {
          id: category.id,
          categoryName: category.category,
          icon: HomeIcon,
          services: skillset[category.id] ? skillset[category.id].numSkills : 0,
          enabled: false,
        };
    }
  }
}

export default CategoryModel;
