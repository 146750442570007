import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputGroup, InputGroupAddon } from 'reactstrap';

import './Input.scss';

const Input = ({
  id,
  label,
  icon,
  setName,
  name = '',
  className,
}) => {
  const [value, setValue] = useState(name);
  const [active, setActive] = useState(false);

  const changeHandler = (ev) => {
    setValue(ev.target.value);
    setName(ev.target.value);
  };

  useEffect(() => {
    if (value) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [active, value]);

  return (
    <div className={classNames('input__container', className)}>
      <InputGroup className="input__input-group">
        <InputGroupAddon
          className="input__input-group-addon"
          addonType="prepend"
        >
          <img src={icon} alt="icon" />
        </InputGroupAddon>
        <div className="input__input-container">
          <div className="input__floating_input_label">
            <div className="input__floating_container">
              <label
                htmlFor={id}
                className={classNames('input__floating_label', {
                  input__floating_label__active: active,
                })}
              >
                {label}
              </label>
              <input
                id={id}
                className={classNames('input__floating_input', { input__floating_input__active: active })}
                onBlur={(event) => setActive((event.target.value.length !== 0))}
                onFocus={() => setActive(true)}
                onChange={changeHandler}
                value={value}
              />
            </div>
          </div>
        </div>
      </InputGroup>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  setName: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
