import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '../../images/ConnectTechSettings/icon-error.svg';
import ErrorTemplate from './ErrorTemplate';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { userId, children, isOnboarding } = this.props;

    if (hasError) {
      // render fallback UI
      return (
        <ErrorTemplate
          userId={userId}
          icon={ErrorIcon}
          isOnboarding={isOnboarding}
          title="Something went wrong..."
          subtitle="Unable to load settings."
          btnText="Go Back"
        />
      );
    }

    return children;
  }
}
ErrorBoundary.propTypes = {
  userId: PropTypes.string,
  children: PropTypes.node,
  isOnboarding: PropTypes.bool,
};

export default ErrorBoundary;
