/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { withProps, compose } from '../../../lib/hocUtils';
import { AvailabilityToggle } from '../../../models/ConnectTechSettings/IndependentProfileModel';
import LoadingIcon from '../../../images/ConnectTechSettings/transparent-spinner.gif';
import BackArrowIcon from '../../../images/ConnectTechSettings/icon-left-arrow.svg';

import './ConnectActivate.scss';

class ConnectActivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  get independentProfile() {
    const { independentProfileStore } = this.props;
    return independentProfileStore ? independentProfileStore.independentProfile : null;
  }

  optInToMarketplace = () => {
    const { independentProfileStore } = this.props;
    this.setState({ isLoading: true });
    independentProfileStore.updateOptInToMarketplace(true, false)
      .then(() => {
        this.setState({ isLoading: false });
        this.independentProfile.optInToMarketplace = true;
        this.independentProfile.availabilityToggle = true;
        this.independentProfile.availabilityToggleState = AvailabilityToggle.DEFAULT;
      });
  }

  render() {
    const { history, independentProfileStore } = this.props;
    const { isLoading } = this.state;

    if (!this.independentProfile) {
      return (<img className="connect-tech-settings__loading" src={LoadingIcon} alt="Loading" />);
    }

    return (
      <div className="connect-tech-settings__activate">
        {
          independentProfileStore.isError && (
            <div className="connect-tech-settings__error">Something went wrong, try again.</div>
          )
        }
        <img
          className="connect-tech-settings__back-arrow"
          src={BackArrowIcon}
          onClick={history.goBack}
          alt="Back"
        />
        <div className="connect-tech-settings__activate__title">
          <div className="connect-tech-settings__title__header">
            Connect Status
          </div>
        </div>
        <div className="connect-tech-settings__activate__body">
          <p>
            Deactivating Connect membership will suspend
            job offers from Jobox, regardless of your work
            schedule or Connect settings.
          </p>
          <ul>
            <li>
              Any open jobs will remain open until marked
              as completed.
            </li>
            <li>
              Any completed jobs will remain in your job
              history for review.
            </li>
            <li>
              Any completed and unpaid jobs will be paid
              out within XX days of deactivating Connect.
            </li>
          </ul>
          <p>
            You will be able to turn Connect back on at any
            time and resume receiving jobs.
          </p>
        </div>
        <div className="connect-tech-settings__activate__bottom-container">
          <button
            type="button"
            className="connect-tech-settings__activate__bottom-container__button"
            onClick={() => this.optInToMarketplace()}
          >
            {
              isLoading && (
              <img className="connect-tech-settings__activate__bottom-container__button__loading" src={LoadingIcon} alt="Loading" />
              )
            }
            {
              !isLoading && (
                'GET JOBS AGAIN'
              )
            }
          </button>
        </div>
      </div>
    );
  }
}

ConnectActivate.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  independentProfileStore: PropTypes.shape({
    isError: PropTypes.bool,
    independentProfile: MobxPropTypes.observableObject,
    updateOptInToMarketplace: PropTypes.func,
  }),
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId } } } = ownProps;

    return {
      userId,
    };
  }),
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(ConnectActivate);
