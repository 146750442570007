import CategoryModel from './CategoryModel';
import GarageDoorIcon from '../../images/ConnectTechSettings/icon_garage-door-opener.svg';
import CarpetCleanerIcon from '../../images/ConnectTechSettings/icon_carpet-cleaner.svg';

const getCategories = (occupation, skillset) => {
  if (occupation.categories) {
    return occupation.categories.map((category) => new CategoryModel(category, skillset));
  }
  return null;
};

const iconsFromId = {
  3: GarageDoorIcon,
  2: CarpetCleanerIcon,
};

const getIcon = (occupationName) => iconsFromId[occupationName];

class SkillsetModel {
  constructor(occupation, skillset) {
    if (occupation) {
      this.id = occupation.id;
      this.name = occupation.name;
      this.categories = getCategories(occupation, skillset);
      this.icon = getIcon(occupation.id);
    }
  }
}

export default SkillsetModel;
