class LocationSettingsModel {
  constructor(locationSettings) {
    if (locationSettings) {
      this.locationServicesEnabled = locationSettings.location_services_enabled;
      this.baseZip = locationSettings.base_zip;
    }
  }
}

export default LocationSettingsModel;
