import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultPageLayout } from '../../components';

import './RequestSentPage.scss';
import sentImage from '../../images/img-sent.svg';

const RequestSentPage = () => (
  <DefaultPageLayout className="request-sent-page">
    <h1 className="request-sent-page__title">Request Sent!</h1>

    <p className="request-sent-page__text">
      We are looking up your transaction and will respond to you shortly.
    </p>

    <img
      className="request-sent-page__image"
      src={sentImage}
      alt="sent"
    />

    <div>
      <Link to="/">Return to Main Page</Link>
    </div>
  </DefaultPageLayout>
);

export default RequestSentPage;
