import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { compose } from '../../../lib/hocUtils';

import HolidaysIcon from '../../../images/ConnectTechSettings/icon-holidays.svg';

import MainNavSection from './NavSection';

const HolidaysSection = ({ userId, holidaysStore }) => {
  useEffect(() => {
    holidaysStore.fetchUserHolidays(userId);
  }, []);

  return (
    <MainNavSection
      title="Holidays & Vacations"
      subtitle={`${holidaysStore.upcomingHolidays.length} days`}
      icon={HolidaysIcon}
      isLink
    />
  );
};

HolidaysSection.propTypes = {
  userId: PropTypes.number,
  holidaysStore: PropTypes.shape({
    fetchUserHolidays: PropTypes.func,
    upcomingHolidays: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
};

export default compose(
  inject('holidaysStore'),
  observer,
)(HolidaysSection);
