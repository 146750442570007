import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Nav, NavLink, TabContent } from 'reactstrap';

import TabPane from './TabPane';

import './Tabs.scss';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
    };
  }

  toggleTab(tab) {
    const { activeTab } = this.state;

    this.setState({ activeTab: activeTab !== tab ? tab : null });
  }

  render() {
    const { children, className } = this.props;
    const { activeTab } = this.state;

    const tabPanes = React.Children
      .toArray(children)
      .filter((child) => child.type === TabPane)
      .map((child) => child.props);

    return (
      <div className={classnames('tabs', className, { 'tabs--active': activeTab !== null })}>
        <Nav pills>
          {
            tabPanes.map((tabPane) => (
              <NavLink
                className={classnames({ active: activeTab === tabPane.tab })}
                onClick={() => { this.toggleTab(tabPane.tab); }}
                key={tabPane.tab}
              >
                {tabPane.tab}
              </NavLink>
            ))
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            tabPanes.map((tabPane) => (
              <TabPane key={tabPane.tab} tabId={tabPane.tab}>
                {tabPane.children}
              </TabPane>
            ))
          }
        </TabContent>
      </div>
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(TabPane),
};

export default Tabs;
