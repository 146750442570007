const NOTICE_HTML_CONTENT = `
  <div id="localfilesystem-notification">
    <strong>Notice:</strong> This page was launched from a local filesystem thus it can't work properly. Functionality is limited.
  </div>
`;

if (window && window.location && window.location.protocol === 'file:') {
  document.getElementById('root').innerHTML = `${NOTICE_HTML_CONTENT}${document.getElementById('root').innerHTML}`;

  throw new Error('This WebApp can\'t be ran from the local filestystem. Functionalities will be disabled.');
}
