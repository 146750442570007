/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';

import HolidaysSettings from './HolidaysSettings';
import CreatePersonalHoliday from './CreatePersonalHoliday';
import ObservedCalendarSelection from './ObservedCalendarSelection';
import ObservedHolidaysSelection from './ObservedHolidaysSelection';

const ConnectTechHolidays = ({ match: { path: currentPath } }) => (
  <Switch>
    <Route
      exact
      path={`${currentPath}/select/:calendarId/:holidayId?`}
      component={ObservedHolidaysSelection}
    />
    <Route
      exact
      path={`${currentPath}/select`}
      component={ObservedCalendarSelection}
    />
    <Route
      exact
      path={`${currentPath}/create`}
      component={CreatePersonalHoliday}
    />
    <Route
      exact
      path={`${currentPath}/`}
      component={HolidaysSettings}
    />
  </Switch>
);

ConnectTechHolidays.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default ConnectTechHolidays;
