import { formatDate } from '../lib/date';
import { formatPhoneNumber } from '../lib/phone';
import PartnerBrandModel from './PartnerBrandModel';

class EstimateModel {
  constructor(estimate) {
    this.id = estimate.id;
    this.type = estimate.type;
    this.jobId = estimate.job_id;
    this.jobRefId = estimate.job_reference_id.slice(3);
    this.jobMarketplace = estimate.marketplace_job || false;
    this.serviceProviderCompany = estimate.user_company_name;
    this.serviceProviderPhone = formatPhoneNumber(estimate.user_phone_number);
    this.customerName = estimate.customer_detail.name;
    this.customerPhone = formatPhoneNumber(estimate.customer_detail.phone_number);
    this.customerEmail = estimate.customer_detail.email;
    this.customerAddress = estimate.customer_detail.address;
    this.notes = estimate.receipt_note;
    this.warranty = estimate.receipt_note;
    this.approved = estimate.approved;
    this.approved_at = formatDate(estimate.approved_at, 'M/D/YY h:mma');
    this.tax = Number.parseFloat(estimate.tax) || 0;
    this.subtotal = Number.parseFloat(estimate.subtotal) || 0;
    this.labor = Number.parseFloat(estimate.labor) || 0;
    this.parts_price = Number.parseFloat(estimate.parts_price) || 0;
    this.signatureURL = estimate.signature_urlpath;
    this.descriptionOfWork = estimate.description_of_work;
    this.partnerBrand = estimate.partner_brand && new PartnerBrandModel(estimate.partner_brand);
  }
}
export default EstimateModel;
