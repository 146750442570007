/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Label,
  Input,
  InputMask,
  Formik,
  Form,
  FormGroup,
  FormFeedback,
} from '../../../components';
import Yup from '../../../lib/yup';
import searchIcon from '../../../images/icon-search.svg';

const validationSchema = Yup.object().shape({
  jobRefId: Yup.string().label('Reference #').required(),
  last4: Yup.string()
    .label('Last 4 digits')
    .matches(/^\d{4}$/i, 'Must be 4 digits')
    .required('${path} are required'),
});

class SearchForm extends Component {
  handleJobComplete = ({ jobRefId, last4 }) => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit({
        jobRefId,
        last4,
      });
    }
  }

  render() {
    const { className, jobRefId, searching } = this.props;
    return (
      <Formik
        initialValues={{
          jobRefId,
          last4: '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={this.handleJobComplete}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className={classnames('search-form', className)} noValidate>
            {!jobRefId && (
              <FormGroup>
                <Label htmlFor="jobRefIdField">Reference #</Label>
                <Input
                  id="jobRefIdField"
                  name="jobRefId"
                  placeholder="example: 5D3R"
                  value={values.jobRefId}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={!!(touched.jobRefId && errors.jobRefId)}
                />
                <FormFeedback>{errors.jobRefId}</FormFeedback>
                <p className="text-gray-metal small mt-1">
                  Find on your credit card statement
                </p>
              </FormGroup>
            )}
            <FormGroup>
              <Label htmlFor="last4Field">Last 4 digits of credit card</Label>
              <InputMask
                id="last4Field"
                name="last4"
                type="tel"
                mask="9999"
                placeholder="####"
                value={values.last4}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.last4 && errors.last4)}
              />
              <FormFeedback>{errors.last4}</FormFeedback>
            </FormGroup>
            <Button type="submit" color="primary" block disabled={searching}>
              <img className="search-form__search-icon" src={searchIcon} alt="seach" />
              {searching ? 'Searching...' : 'Search'}
            </Button>
          </Form>
        )}
      />
    );
  }
}

SearchForm.propTypes = {
  className: PropTypes.string,
  jobRefId: PropTypes.string,
  onSubmit: PropTypes.func,
  searching: PropTypes.bool,
};

export default SearchForm;
