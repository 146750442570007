import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer, inject } from 'mobx-react';

import { withRouter } from 'react-router';
import {
  Box,
  ButtonLoading,
  ViewLayout,
  ViewLayoutContent,
  Text,
} from '@jobox/react-components-library/mobile';

import { Head, DateInput } from './components';
import { withProps, compose } from '../../lib/hocUtils';

import classes from './createPersonalHoliday.module.scss';

class CreatePersonalHoliday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().startOf('day').valueOf(),
      endDate: moment().endOf('day').valueOf(),
    };
  }

  setStartDateHandler = (value) => {
    const { endDate } = this.state;

    if (endDate) {
      const momentEndDate = moment(endDate);

      if (momentEndDate.diff(moment(value), 'hours') < 24) {
        const valueMoment = moment(value);

        this.setState({
          startDate: value,
          endDate: valueMoment.endOf('day').valueOf(),
        });

        return;
      }
    }

    this.setState({ startDate: value });
  }

  setEndDateHandler = (value) => {
    this.setState({ endDate: moment(value).endOf('day').valueOf() });
  }

  createPersonalHolidayHandler = async () => {
    const { holidaysStore, userId, history } = this.props;
    const { startDate, endDate } = this.state;

    await holidaysStore.createPersonalHoliday(userId, { startDate, endDate });

    history.push(`/connectTechSettings/${userId}/holidays`);
  }

  render() {
    const { userId, holidaysStore } = this.props;
    const { startDate, endDate } = this.state;

    // iOS date picker doesn't support min values, which prevents such
    // cases, so we need an additional validator
    const isPastDate = (
      startDate && startDate < moment().startOf('day').valueOf()
    );
    const isEndDateBeforeStartDate = (
      startDate && endDate && (endDate < startDate)
    );

    return (
      <ViewLayout className={classes.layout}>
        <Head
          title="Add new Day Off"
          backTo={`/connectTechSettings/${userId}/holidays`}
          className={classes.head}
        />
        <ViewLayoutContent className={classes.layout__content}>
          <Box className={classes.dates}>
            <DateInput
              id="start-date"
              value={startDate}
              onChange={this.setStartDateHandler}
              label="Start date"
            />
            <DateInput
              id="end-date"
              value={endDate}
              min={startDate}
              onChange={this.setEndDateHandler}
              label="End date"
            />
          </Box>
          <Box className={classes.validation}>
            {
              isPastDate && (
                <Text className={classes.validation__entry}>
                  Start date must be today or in the future
                </Text>
              )
            }
            {
              isEndDateBeforeStartDate && (
                <Text className={classes.validation__entry}>
                  End date needs to be after start date
                </Text>
              )
            }
          </Box>

          <ButtonLoading
            color="primary"
            onClick={this.createPersonalHolidayHandler}
            loading={holidaysStore.isLoading}
            loadingText="Adding..."
            disabled={isPastDate || isEndDateBeforeStartDate}
            className={classes.submit}
          >
            Add time off
          </ButtonLoading>
        </ViewLayoutContent>
      </ViewLayout>
    );
  }
}

CreatePersonalHoliday.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  holidaysStore: PropTypes.shape({
    createPersonalHoliday: PropTypes.func,
    isLoading: PropTypes.bool,
  }),
  userId: PropTypes.string,
};

export default compose(
  withProps(({ match: { params: { userId } } }) => ({
    userId,
  })),
  withRouter,
  inject(({ holidaysStore }) => ({
    holidaysStore,
  })),
  observer,
)(CreatePersonalHoliday);
