import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatMoney } from '../../lib/number';

import './TotalPayment.scss';

const TotalPayment = ({ amount, className }) => {
  let formattedAmount = formatMoney(amount);
  formattedAmount = formattedAmount.startsWith('$') ? formattedAmount.substring(1) : formattedAmount;
  const [paymentDollars, paymentCents] = formattedAmount.split('.');

  return (
    <div className={classnames('total-payment', className)}>
      <div className="total-payment__dollar">$</div>
      <div className="total-payment__amount">
        {paymentDollars}
        .
      </div>
      <div className="total-payment__cents">{paymentCents}</div>
    </div>
  );
};

TotalPayment.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default TotalPayment;
