/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { compose } from '../../../lib/hocUtils';

import './Modal.scss';

function Modal({ children, isOpen }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="modall">
          <motion.div
            className="modal__background"
            key="modal-background"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.35,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
          />
          <motion.div
            className="modal__foreground"
            key="modal-foreground"
            initial={{
              y: '100vh',
            }}
            animate={{
              y: '0',
              transition: {
                duration: 0.3,
                ease: [0.075, 0.82, 0.165, 1],
              },
            }}
            exit={{
              y: '100vh',
              transition: {
                duration: 0.3,
                ease: [0.075, 0.82, 0.165, 1],
              },
            }}
          >
            { children }
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

Modal.propTypes = {
  independentProfileStore: PropTypes.shape({
    independentProfile: MobxPropTypes.observableObject,
    updateUserAvailability: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isOpen: PropTypes.bool,
};

export default compose(
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(Modal);
