class IdentificationModel {
  constructor(identification) {
    this.id = identification.id;
    this.userId = identification.user_id;
    this.entityType = identification.entity_type;
    this.entityId = identification.entity_id;
  }
}

export default IdentificationModel;
