import React, { useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import {
  withRouter,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router';
import PropTypes from 'prop-types';

import { withProps, compose } from '../../lib/hocUtils';
import ConnectTechRoutes from '../ConnectTechSettings/ConnectTechRoutes';

import classes from './OnboardingFlow.module.scss';

const OnboardingFlow = ({
  userId,
  connectTechSettingsStore,
}) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const [categories, setCategoriesState] = useState([]);
  const [nextRoute, setNextRoute] = useState(null);
  const [prevRoutes, setPrevRoutes] = useState([]);
  const finalRoutes = [
    `/connectTechSettings/${userId}/onboarding/dailyavailability`,
    `/connectTechSettings/${userId}/onboarding/availability`,
    `/connectTechSettings/${userId}/onboarding/finalCheck`,
  ];
  const [selectedCategoryRoutes, setSelectedCategoryRoutes] = useState(finalRoutes);

  useEffect(() => {
    if (!connectTechSettingsStore.onboardingAvailableSkillset) {
      connectTechSettingsStore.getJobSkillset(match.params.userId);
    }
  }, []);

  useEffect(() => {
    if (!location.pathname.includes('finalCheck')) {
      setCategories(connectTechSettingsStore.onboardingAvailableSkillset);
    }
  }, [connectTechSettingsStore.onboardingAvailableSkillset]);

  useEffect(() => {
    const configureNextRoute = () => {
      setNextRoute(selectedCategoryRoutes[0]);
    };
    configureNextRoute();
  }, [selectedCategoryRoutes]);

  const setCategories = async (newCategories) => {
    setCategoriesState(newCategories);
    if (newCategories) {
      if (newCategories.length === 0) {
        setSelectedCategoryRoutes(finalRoutes.slice(1));

        history.push(finalRoutes[0]);

        return;
      }
      const enabledCategories = newCategories
        .filter((c) => c.enabled)
        .map((c) => `/connectTechSettings/${userId}/onboarding/skillset/${connectTechSettingsStore.occupationId}/subcategory/${c.id}`);

      setSelectedCategoryRoutes(
        enabledCategories.length > 0
          ? [...enabledCategories, ...finalRoutes]
          : [],
      );
    }
  };

  const next = () => {
    if (selectedCategoryRoutes.length > 0 && categories) {
      const currRoute = nextRoute;

      setSelectedCategoryRoutes(selectedCategoryRoutes.slice(1));

      if (!prevRoutes.includes(currRoute)) {
        setPrevRoutes([...prevRoutes, currRoute]);
      }

      history.push(currRoute);
    }
  };

  const back = () => {
    const lastRoute = prevRoutes[prevRoutes.length - 1];
    if (
      lastRoute
      && !selectedCategoryRoutes.includes(lastRoute)
      && prevRoutes.includes(location.pathname) && !location.pathname.includes('carbrands')
    ) {
      setSelectedCategoryRoutes([lastRoute, ...selectedCategoryRoutes]);
    }
    if (!location.pathname.includes('carbrands')) {
      setPrevRoutes(prevRoutes.slice(0, prevRoutes.length - 1));
    }
    history.goBack();
  };

  const enableBack = prevRoutes.length > 0
    && !location.pathname.includes('finalCheck');

  return (
    <>
      <div className={classes['routing-container']}>
        <ConnectTechRoutes
          categories={categories}
          setCategories={setCategories}
          isOnboarding
        />
      </div>

      <div
        className={`${classes['button-container']} ${
          classes[enableBack ? 'justify-space' : 'justify-center']
        }`}
      >
        {enableBack && (
          <>
            <button
              type="button"
              className={classes['back-button']}
              onClick={back}
            >
              BACK
            </button>
          </>
        )}
        {!location.pathname.includes('finalCheck') ? (
          <button
            type="button"
            className={`${classes['next-button']} ${
              !enableBack && classes['full-button']
            }`}
            onClick={next}
          >
            NEXT
          </button>
        ) : (
          <a href="jobox://connect-setting-complete">
            <button
              type="button"
              className={`${classes['next-button']} ${
                !enableBack && classes['full-button']
              }`}
            >
              LOOKS GOOD!
            </button>
          </a>
        )}
      </div>
    </>
  );
};

OnboardingFlow.propTypes = {
  userId: PropTypes.string,
  connectTechSettingsStore: PropTypes.shape({
    independentProfileStore: MobXPropTypes.observableObject,
    occupationId: PropTypes.number,
    fetchConnectTechSettings: PropTypes.func,
    resetErrors: PropTypes.func,
    getJobSkillset: PropTypes.func,
    onboardingAvailableSkillset: PropTypes.arrayOf(PropTypes.any),
  }),
};

export default compose(
  withRouter,
  withProps((ownProps) => {
    const {
      match: {
        params: { userId },
      },
    } = ownProps;

    return {
      userId,
    };
  }),
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
  })),
  observer,
)(OnboardingFlow);
