import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import qs from 'qs';

import { logger } from '../../lib/logger';
import { withProps, compose } from '../../lib/hocUtils';
import { DefaultPageLayout, Tabs, TabPane } from '../../components';
import { JobCard, QuestionForm } from './components';

import './TransactionPage.scss';

const TABS = {
  UNRECOGNIZED_CHARGE: 'Unrecognized Charge',
  WRONG_AMOUNT: 'Wrong Amount',
  ISSUE_WITH_SERVICE: 'Issue with Service',
  SOMETHING_ELSE: 'Something Else',
};

class TransactionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingError: null,
      submittingQuestion: false,
      tabErrors: {},
    };
  }

  componentDidMount() {
    const {
      transactionStore,
      occupationsStore,
      jobRefId,
      last4hash,
    } = this.props;

    this.setState({ loading: true });
    const loadTransactionPromise = last4hash
      ? transactionStore.loadTransactionByLast4(jobRefId, last4hash)
      : transactionStore.loadTransaction(jobRefId);

    Promise.all([
      loadTransactionPromise,
      occupationsStore.loadOccupations(),
    ]).catch((error) => {
      this.setState({ loadingError: error });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleQuestionSubmit = (question) => {
    const { conversationStore, transactionStore, history } = this.props;
    const { transaction } = transactionStore;

    this.setState({
      tabErrors: {},
      submittingQuestion: true,
    });
    conversationStore.createConversation({
      ...question,
      last4: transaction.payment.last4,
      jobRefId: transaction.jobRefId,
      amount: transaction.payment.amount,
      date: transaction.payment.createdAt,
    }).then(() => {
      this.setState({ submittingQuestion: false });
      history.push('/sent');
    }).catch((error) => {
      this.setState({
        submittingQuestion: false,
        tabErrors: {
          [question.reason]: error,
        },
      });
    });
  }

  renderPageContent = () => {
    const { transactionStore, occupationsStore } = this.props;
    const { transaction } = transactionStore;
    const { allOccupations } = occupationsStore;
    const {
      tabErrors,
      loading,
      loadingError,
      submittingQuestion,
    } = this.state;

    if (loading) {
      return null;
    }

    if (loadingError || !transaction) {
      return <div>Error trying to load transaction...</div>;
    }

    return (
      <div className="transaction-page__content">
        <h1 className="transaction-page__title">Here is your transaction.</h1>

        <JobCard
          className="transaction-page__job-card"
          jobId={transaction.id}
          jobRefId={transaction.jobRefId}
          userId={transaction.userId}
          companyName={transaction.companyName}
          serviceName={transaction.occupationName}
          chargeDate={transaction.payment.createdAt}
          creditCardAmount={transaction.payment.amount}
          creditCardBrand={transaction.payment.brand}
          creditCardLast4={transaction.payment.last4}
        />

        <h2 className="transaction-page__subtitle">Can we help you with anything else?</h2>

        <Tabs className="transaction-page__tabs">
          <TabPane tab={TABS.UNRECOGNIZED_CHARGE}>
            <p>Did you receive any of the following services recently?</p>
            <ul>
              {allOccupations.map((occupation) => (
                <li key={occupation.id}>{occupation.name}</li>
              ))}
            </ul>
            <p>
              Still don’t recognize this charge? Send us a message and we’ll respond shortly.
            </p>
            <QuestionForm
              className="transaction-page__question-form"
              initialValues={{ reason: 'Unrecognized_Charge' }}
              onSubmit={this.handleQuestionSubmit}
              submitting={submittingQuestion}
            />

            {tabErrors[TABS.UNRECOGNIZED_CHARGE] && <div className="transaction-page__tab-error">Error trying to submit request</div>}
          </TabPane>
          <TabPane tab={TABS.WRONG_AMOUNT}>
            <p>
              Were you charged the wrong amount? Send us a message and we’ll respond shortly.
            </p>
            <QuestionForm
              className="transaction-page__question-form"
              initialValues={{ reason: 'Incorrect_Amount' }}
              onSubmit={this.handleQuestionSubmit}
              submitting={submittingQuestion}
            />

            {tabErrors[TABS.WRONG_AMOUNT] && <div className="transaction-page__tab-error">Error trying to submit request</div>}
          </TabPane>
          <TabPane tab={TABS.ISSUE_WITH_SERVICE}>
            <p>
              Do you have an issue with the service you received?
              {' '}
              Send us a message and we’ll respond shortly.
            </p>
            <p className="transaction-page__note">
              <b>Note:</b>
              {' '}
              ViewInvoice is a service of Jobox, a mobile payment company used by
              {' '}
              third-party service providers to accept credit card payments.
              {' '}
              Jobox does not warrant, guarantee, or assume responsibility for
              {' '}
              any product or service offered by a third party that is paid for using Jobox.
            </p>
            <QuestionForm
              className="transaction-page__question-form"
              initialValues={{ reason: 'Issue_With_Service' }}
              onSubmit={this.handleQuestionSubmit}
              submitting={submittingQuestion}
            />

            {tabErrors[TABS.ISSUE_WITH_SERVICE] && <div className="transaction-page__tab-error">Error trying to submit request</div>}
          </TabPane>
          <TabPane tab={TABS.SOMETHING_ELSE}>
            <p>
              Send us a message and we’ll respond shortly.
            </p>
            <QuestionForm
              className="transaction-page__question-form"
              initialValues={{ reason: 'General_Question' }}
              onSubmit={this.handleQuestionSubmit}
              submitting={submittingQuestion}
            />

            {tabErrors[TABS.SOMETHING_ELSE] && <div className="transaction-page__tab-error">Error trying to submit request</div>}
          </TabPane>
        </Tabs>

        <div className="transaction-page__footer">
          <p className="transaction-page__acknowledgement">
            By using this website, I confirm that the information
            {' '}
            inputted above is from my card billing statement,
            {' '}
            or I am authorized by the account holder to input the
            {' '}
            information from their billing statement.
          </p>

          <div className="transaction-page__return-to-main">
            <Link to="/">Return to Main Page</Link>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <DefaultPageLayout className="transaction-page">
        {this.renderPageContent()}
      </DefaultPageLayout>
    );
  }
}

TransactionPage.propTypes = {
  occupationsStore: PropTypes.shape({
    loadOccupations: PropTypes.func.isRequired,
    allOccupations: MobxPropTypes.observableArray,
  }).isRequired,
  transactionStore: PropTypes.shape({
    loadTransactionByLast4: PropTypes.func.isRequired,
    loadTransaction: PropTypes.func.isRequired,
    transaction: MobxPropTypes.observableArray,
  }).isRequired,
  conversationStore: PropTypes.shape({
    createConversation: PropTypes.func.isRequired,
  }).isRequired,
  jobRefId: PropTypes.string,
  last4hash: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default compose(
  withProps((ownProps) => {
    const { location: { search }, match: { params: { jobRefId } } } = ownProps;
    const queryParams = qs.parse(search);

    let last4hash;
    try {
      last4hash = queryParams.cc ? atob(queryParams.cc) : null;
    } catch (e) {
      logger.warn('Invalid hash');
    }

    return {
      jobRefId,
      last4hash,
    };
  }),
  inject('occupationsStore', 'transactionStore', 'conversationStore'),
  observer,
)(TransactionPage);
