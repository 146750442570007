import EstimateModel from '../models/EstimateModel';

class ApprovedEstimateStore {
  constructor(apiService) {
    this.apiService = apiService;
  }

  loadEstimate(userId, jobId) {
    return this.apiService.getApprovedEstimate(jobId)
      .then((estimate) => {
        this.estimate = new EstimateModel(estimate);
      });
  }
}

export default ApprovedEstimateStore;
