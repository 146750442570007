/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { compose } from '../../../lib/hocUtils';
import LoadingIcon from '../../../images/ConnectTechSettings/transparent-spinner.gif';
import Modal from '../Modal/Modal';

import './DeactivateModal.scss';

class DeactivateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  get independentProfile() {
    const { independentProfileStore } = this.props;
    return independentProfileStore ? independentProfileStore.independentProfile : null;
  }

  optOutOfMarketplace = () => {
    const { independentProfileStore, history, handleClose } = this.props;
    this.setState({ isLoading: true });
    independentProfileStore.updateOptInToMarketplace(false, false)
      .then(() => {
        handleClose();
        this.independentProfile.optInToMarketplace = false;
        history.goBack();
      }).finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { isLoading } = this.state;
    const { isOpen, handleClose } = this.props;

    return (
      <Modal
        isOpen={isOpen}
      >
        <div className="deactivate-modal__title">
          Are you sure you want to turn off Marketplace?
        </div>
        <div className="deactivate-modal__description">
          You will not receive jobs while Marketplace is turned off.
        </div>
        <button
          type="button"
          className="deactivate-modal__primary-button"
          onClick={this.optOutOfMarketplace}
        >
          {
            isLoading ? (
              <img className="deactivate-modal__primary-button__loading" src={LoadingIcon} alt="Loading" />
            ) : (
              'DEACTIVATE CONNECT'
            )
          }
        </button>
        <button
          type="button"
          className="deactivate-modal__secondary-button"
          onClick={handleClose}
        >
          CANCEL
        </button>
      </Modal>
    );
  }
}

DeactivateModal.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  independentProfileStore: PropTypes.shape({
    independentProfile: MobxPropTypes.observableObject,
    updateOptInToMarketplace: PropTypes.func,
  }),
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default compose(
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(DeactivateModal);
