import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './EstimateLayout.scss';

export const EstimateLayoutWrap = ({
  className,
  children,
}) => (
  <div className={cn(className, 'estimate-layout-wrap')}>
    { children }
  </div>
);
EstimateLayoutWrap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

/*
  For now we have a bit different layouts
  for displaying invoices vs estimates. For
  invoices EstimateLayoutBody should have larger
  horizontal padding. This should be removed in the future
  when we will be supporting Invoices only
*/
export const EstimateLayout = ({
  className,
  isInvoice,
  children,
}) => (
  <div
    className={cn(className, 'estimate-layout', {
      'estimate-layout--invoice': isInvoice,
    })}
  >
    { children }
  </div>
);
EstimateLayout.propTypes = {
  className: PropTypes.string,
  isInvoice: PropTypes.bool,
  children: PropTypes.node,
};

export const EstimateLayoutBody = ({
  className,
  children,
}) => (
  <div className={cn(className, 'estimate-layout__body')}>
    { children }
  </div>
);
EstimateLayoutBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
