import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatDate } from '../../../lib/date';
import { Button, TotalPayment } from '../../../components';

import receiptImage from '../../../images/img-receipt.svg';

import './JobCard.scss';

const JobCard = ({
  jobId,
  jobRefId,
  userId,
  chargeDate,
  serviceName,
  companyName,
  className,
  creditCardAmount,
  creditCardBrand,
  creditCardLast4,
}) => (
  <div className={classnames('job-card', className)}>
    <img className="job-card__image" src={receiptImage} alt="receipt" />

    <div className="job-card__job-info">
      <div className="job-card__job-id">{`Reference # ${jobRefId}`}</div>
      <div className="job-card__date">{formatDate(chargeDate, 'MMMM D, YYYY')}</div>
      <div className="job-card__company-name">{companyName}</div>
      <div className="job-card__service-name">{serviceName}</div>
    </div>

    {!!creditCardAmount && (
      <div className="job-card__payment">
        {(creditCardBrand || creditCardLast4) ? (
          <div>{`${creditCardBrand || 'Credit Card'} *${creditCardLast4 || 'N/A'}`}</div>
        ) : (
          <div>Total</div>
        )}
        <TotalPayment amount={creditCardAmount} />
      </div>
    )}

    {!!(jobId && userId) && (
      <Button
        size="lg"
        tag="a"
        color="green"
        className="job-card__view-receipt-btn"
        href={`/receipts/${jobId}-${userId}`}
        target="_blank"
      >
        View Receipt
      </Button>
    )}
  </div>
);

JobCard.propTypes = {
  className: PropTypes.string,
  jobId: PropTypes.number,
  jobRefId: PropTypes.string,
  userId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  chargeDate: PropTypes.object,
  companyName: PropTypes.string,
  serviceName: PropTypes.string,
  creditCardAmount: PropTypes.number,
  creditCardLast4: PropTypes.string,
  creditCardBrand: PropTypes.string,
};

export default JobCard;
