import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { withProps, compose } from '../../lib/hocUtils';
import { murmur3 } from '../../lib/crypto';
import { DefaultPageLayout } from '../../components';

import { SearchForm } from './components';

import './SearchPage.scss';

const VISIBLE_OCCUPATION_IDS = [1, 2, 3, 4, 6, 7];

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: null,
      transactionLoading: false,
      transactionLoadingError: null,
    };
  }

  componentDidMount() {
    const { occupationsStore } = this.props;

    this.setState({ loading: true });
    occupationsStore.loadOccupations()
      .then(() => {
        this.setState({ loading: false });
      }).catch((error) => {
        this.setState({
          loading: false,
          loadingError: error,
        });
      });
  }

  get visibleOccupations() {
    const { occupationsStore } = this.props;
    const { allOccupations } = occupationsStore;
    const visibleOccupations = allOccupations
      .filter((occupation) => VISIBLE_OCCUPATION_IDS.includes(occupation.id));

    return visibleOccupations.concat([{
      name: 'Other local services',
    }]);
  }

  handleSearch = ({ jobRefId, last4 }) => {
    const { history, transactionStore } = this.props;
    const last4hash = murmur3(last4);

    this.setState({
      transactionLoading: true,
      transactionLoadingError: null,
    });
    transactionStore.loadTransactionByLast4(jobRefId, last4hash)
      .then(() => {
        history.push(`/transactions/${jobRefId}?cc=${btoa(last4hash)}`);
      })
      .catch((error) => {
        this.setState({ transactionLoadingError: error });
      }).finally(() => {
        this.setState({ transactionLoading: false });
      });
  }

  renderPageContent() {
    const { jobRefId } = this.props;
    const {
      loading,
      loadingError,
      transactionLoadingError,
      transactionLoading,
    } = this.state;

    if (loading) {
      return null;
    }

    if (loadingError) {
      return <div>Error trying to load occupations...</div>;
    }

    return (
      <div className="search-page__content">
        <div className="search-page__card">
          <div className="search-page__section">
            <h2 className="search-page__section-header">Let’s find your transaction</h2>

            {transactionLoadingError && (
              <div className="search-page__error">
                We can&apos;t find any transactions that match the reference ID
                {' '}
                and last 4 digits. Please make sure you entered your information correctly.
              </div>
            )}

            <SearchForm
              className="search-page__search-form"
              jobRefId={jobRefId || ''}
              onSubmit={this.handleSearch}
              searching={transactionLoading}
            />

            <div className="search-page__help-page-link">
              <Link to="/help">Can’t find your transaction?</Link>
            </div>
          </div>

          <div className="search-page__section">
            <h2 className="search-page__section-header">ViewInvoice processes payments for:</h2>

            <div className="search-page__occupations">
              {this.visibleOccupations.map((occupation) => (
                <div key={occupation.name} className="search-page__occupation">
                  {occupation.icon ? (
                    <img className="search-page__occupation-icon" src={occupation.icon} alt={occupation.name} />
                  ) : (
                    <div className="search-page__occupation-icon" />
                  )}
                  <span className="search-page__occupation-name">
                    {occupation.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="search-page__about-section">
          <h2 className="search-page__about-header">What is ViewInvoice?</h2>

          <p className="search-page__about">
            ViewInvoice is a service of Jobox, a mobile payment company for
            {' '}
            third-party service providers to accept credit card payments.
            {' '}
            Jobox does not warrant, guarantee, or assume responsibility for any
            {' '}
            product or service offered by a third party that is paid for using Jobox.
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <DefaultPageLayout
        className="search-page"
        title="Need help with a credit card charge?"
        text="Your satisfaction is our priority. Our customer service team is here to help!"
      >
        {this.renderPageContent()}
      </DefaultPageLayout>
    );
  }
}
SearchPage.propTypes = {
  occupationsStore: PropTypes.shape({
    loadOccupations: PropTypes.func.isRequired,
    allOccupations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
  }).isRequired,
  transactionStore: PropTypes.shape({
    loadTransactionByLast4: PropTypes.func.isRequired,
  }).isRequired,
  jobRefId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  withProps((ownProps) => ({
    jobRefId: ownProps.match.params.jobRefId,
  })),
  inject('occupationsStore', 'transactionStore'),
  observer,
)(SearchPage);
