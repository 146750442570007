/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './selectDrawer.module.scss';

export const SelectDrawer = ({
  onSelection,
  currentService,
  services,
  enabled,
  isOnboarding,
}) => (
  <div
    className={classNames(classes.container, {
      [classes['container-onboarding']]: isOnboarding,
      [classes['container-enabled']]: !enabled,
    })}
  >
    <div className={classes['title-container']}>
      <div className={classes.title}>
        {
          currentService
            ? `What brands do you work on for ${currentService.service} Jobs?`
            : null
        }
      </div>
    </div>
    {
      services && services.map(
        (service) => service.enabled && currentService && service.id !== currentService.id && (
          <div
            key={service.id}
            className={classes.item}
            onClick={() => onSelection(service.id, currentService)}
          >
            <div className={classes['item-text']}>
              Same as
              {' '}
              {service.service}
            </div>
          </div>
        ),
      )
    }
    <div
      className={classes.item}
      onClick={() => onSelection(-1, currentService)}
    >
      <div className={classes['item-text']}>All Brands</div>
    </div>
    <div
      className={classes.item}
      onClick={() => onSelection(-2, currentService)}
    >
      <div className={classes['item-text']}>Customize Brands</div>
    </div>
    <div className={classes.footer} />
  </div>
);

SelectDrawer.propTypes = {
  onSelection: PropTypes.func,
  currentService: PropTypes.shape({
    id: PropTypes.number,
    service: PropTypes.string,
    enabled: PropTypes.bool,
  }),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      service: PropTypes.string,
      enabled: PropTypes.bool,
    }),
  ),
  enabled: PropTypes.bool,
  isOnboarding: PropTypes.bool,
};
