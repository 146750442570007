import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '../Text';

import './WarrantyInfo.scss';

const WarrantyInfo = ({ content, className }) => (
  <div className={cn('war-info', className)}>
    <h5 className="war-info__header">
      Warranty, policies, or guarantees
    </h5>
    <p className="war-info__content">
      <Text content={content} />
    </p>
  </div>
);
WarrantyInfo.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default WarrantyInfo;
