import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatDate } from '../../lib/date';

import './ReceiptPageLayout.scss';

const ReceiptPageLayout = ({
  className,
  children,
  occupation,
  serviceDate,
  paymentProcessed,
  themeColor,
}) => (
  <div className={classnames('receipt-page-layout', className)}>
    <div className="receipt-page-layout__shadow">
      <div className="receipt-page-layout__top" style={{ background: themeColor }}>
        {occupation && (<h1 className="receipt-page-layout__service-company">{occupation}</h1>)}
        {serviceDate && (<div className="receipt-page-layout__service-date">{formatDate(serviceDate, 'MM/DD/YYYY hh:mm A')}</div>)}
      </div>
      <div className="receipt-page-layout__content">
        {children}
      </div>
      <div className="receipt-page-layout__bottom" style={{ background: themeColor }} />
    </div>
    <div className="receipt-page-layout__footer">
      {
        paymentProcessed ? (
          <div>Payment processed by Jobox</div>
        ) : (
          <div>Receipt generated by Jobox</div>
        )
      }
    </div>
  </div>
);

ReceiptPageLayout.propTypes = {
  className: PropTypes.string,
  occupation: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  serviceDate: PropTypes.object,
  paymentProcessed: PropTypes.bool,
  themeColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default ReceiptPageLayout;
