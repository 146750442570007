/* eslint-disable camelcase */
import {
  observable,
  action,
  makeObservable,
} from 'mobx';
import { first } from 'lodash';

import { getTimezone } from '../lib/date';

class RemoteApprovalStore {
  file = null;
  user_id = null;
  job_id = null;
  estimatelog_id = null;
  remote_signed = true;
  customer_name = '';
  estimate = null;
  pdf = '';

  constructor(apiService) {
    this.apiService = apiService;

    this.remoteApproval = {
      file: this.file,
      user_id: this.user_id,
      job_id: this.job_id,
      estimatelog_id: this.estimatelog_id,
      remote_signed: this.remote_signed,
      customer_name: this.customer_name,
    };

    makeObservable(this, {
      file: observable,
      user_id: observable,
      job_id: observable,
      estimatelog_id: observable,
      remote_signed: observable,
      customer_name: observable,
      estimate: observable,
      pdf: observable,
      remoteApproval: observable,

      setCustomerName: action.bound,
      setSignature: action.bound,
      setUserId: action.bound,
      setJobId: action.bound,
      setEstimatelogId: action.bound,
      sendSignature: action.bound,
    });
  }

  loadEstimate(userId, jobId) {
    return this.apiService.getSignatureEstimateLog(userId, jobId, getTimezone())
      .then(action((response) => {
        this.estimate = first(response);
        this.setEstimatelogId(this.estimate.id);
      }));
  }

  setCustomerName(val) {
    this.customer_name = val;
    this.remoteApproval.customer_name = val;
    this.estimate.customer_detail.name = val;
  }

  setSignature(val) {
    this.file = val;
    this.remoteApproval.file = val;
  }

  setUserId(val) {
    this.user_id = val;
    this.remoteApproval.user_id = val;
  }

  setJobId(val) {
    this.job_id = val;
    this.remoteApproval.job_id = val;
  }

  setEstimatelogId(val) {
    this.estimatelog_id = val;
    this.remoteApproval.estimatelog_id = val;
  }

  sendSignature() {
    this.apiService.submitSignature(this.remoteApproval, getTimezone())
      .then(action(() => {
        setTimeout(() => {
          this.loadEstimate(this.user_id, this.job_id);
        }, 5000);
      }));
  }
}

export default RemoteApprovalStore;
