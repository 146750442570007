import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MainNavSection from './NavSection';
import AvailabilityIcon from '../../../images/ConnectTechSettings/icon-availability.svg';
import days from '../../../lib/days';

import './NavSection.scss';

const getDaysTitle = (availableDays) => (
  <div className="connect-tech-settings__main-availability__day-container">
    {
      Object.entries(days).map(([dayIndex, dayInfo]) => (
        <div
          className={classNames(
            'connect-tech-settings__main-availability__day',
            {
              active: availableDays.includes(parseInt(dayIndex, 10)),
            },
          )}
          key={`day-item-${dayIndex}`}
        >
          {dayInfo.letter}
        </div>
      ))
    }
  </div>
);

const AvailabilitySection = ({ availableDays, numWindows, isLink }) => (
  <MainNavSection
    title={getDaysTitle(availableDays)}
    subtitle={`${numWindows || 0} Time Windows`}
    icon={AvailabilityIcon}
    isLink={isLink}
  />
);

AvailabilitySection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  availableDays: PropTypes.array,
  numWindows: PropTypes.number,
  isLink: PropTypes.bool,
};

export default AvailabilitySection;
