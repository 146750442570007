import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { withFeatureFlagProvider } from '@jobox/react-components-library/mobile';

import SkillsetModel from '../../models/ConnectTechSettings/SkillsetModel';
import { withProps, compose } from '../../lib/hocUtils';
import ldFlags from '../../constants/LaunchDarklyFlags';
import AvailabilitySection from '../../components/ConnectTechSettings/SectionTypes/AvalabilitySection';
import SkillsetSection from '../../components/ConnectTechSettings/SectionTypes/SkillsetSection';
import NavSection from '../../components/ConnectTechSettings/SectionTypes/NavSection';
import HolidaysSection from '../../components/ConnectTechSettings/SectionTypes/HolidaysSection';
import LocationIcon from '../../images/ConnectTechSettings/icon-location.svg';
import LoadingIcon from '../../images/loading.gif';
import NotificationIcon from '../../images/ConnectTechSettings/icon-notification.svg';
import { AvailabilityToggle } from '../../models/ConnectTechSettings/IndependentProfileModel';

import './ConnectTechSettings.scss';

class ConnectTechSettings extends Component {
  // Shorthand for the rest of the code
  get independentProfile() {
    const { connectTechSettingsStore: { independentProfileStore } } = this.props;
    return independentProfileStore ? independentProfileStore.independentProfile : null;
  }

  get basicProfile() {
    const { connectTechSettingsStore: { basicProfileStore } } = this.props;
    return basicProfileStore ? basicProfileStore.basicProfile : null;
  }

  getSkillset() {
    const {
      occupationsStore,
      connectTechSettingsStore,
    } = this.props;

    // sort and count tech skills by occupation
    const techSkillset = this.basicProfile.skillSet.reduce((acc, skill) => ({
      ...acc,
      [skill.occupation_id]: {
        ...acc[skill.occupation_id],
        [skill.job_sub_category_id]: {
          numSkills: (
            (
              acc[skill.occupation_id]
              && acc[skill.occupation_id][skill.job_sub_category_id]
            )
              ? acc[skill.occupation_id][skill.job_sub_category_id].numSkills
              : 0
          ) + 1,
        },
      },
    }), {});
    // map occupations and skills to names,
    // tech skillset to overall possible skillset for occupation
    const userOccupation = connectTechSettingsStore.occupationId;
    if (
      userOccupation && !Object.keys(techSkillset).includes(String(userOccupation))
    ) {
      techSkillset[userOccupation] = {};
    }

    return Object.entries(techSkillset).map(([occupationId, skillset]) => {
      const occupation = occupationId && occupationsStore.getOccupation(parseInt(occupationId, 10));
      return new SkillsetModel(occupation, skillset);
    }).filter((s) => s !== null);
  }

  renderHoursSection() {
    const {
      userId,
      flags,
    } = this.props;
    const featHolidaysEnabled = flags[ldFlags.HolidaysAndBlackoutPeriods];
    const availability = this.independentProfile.availability.schedule.reduce((acc, day) => ({
      numWindows: acc.numWindows + day.time_ranges.length,
      availableDays: day.time_ranges.length > 0
        ? [...acc.availableDays, day.weekday]
        : acc.availableDays,
    }), {
      numWindows: 0,
      availableDays: [],
    });

    return (
      <div className="connect-tech-settings__section">
        <h3 className="connect-tech-settings__section-title">PREFERRED HOURS</h3>
        <Link to={`/connectTechSettings/${userId}/availability`} className="text-decoration-none d-block">
          <AvailabilitySection
            availableDays={availability.availableDays}
            numWindows={availability.numWindows}
            isLink
          />
        </Link>
        {
          featHolidaysEnabled && (
            <Link to={`/connectTechSettings/${userId}/holidays`} className="text-decoration-none d-block mt-3">
              <HolidaysSection
                userId={parseInt(userId, 10)}
              />
            </Link>
          )
        }
      </div>
    );
  }

  renderOccupationSections() {
    const { userId } = this.props;
    const skillset = this.getSkillset();
    return (
      skillset && skillset.map((occupation) => {
        if (!occupation.categories && occupation.icon) {
          return (
            <div className="connect-tech-settings__section" key={`${occupation.name}-section`}>
              <h3 className="connect-tech-settings__section-title">{`${occupation.name.toUpperCase()} SKILLSET`}</h3>
              <NavSection
                title={occupation.name}
                subtitle="Enabled"
                icon={occupation.icon}
                className="connect-tech-settings__skillset-section"
              />
            </div>
          );
        }
        if (!occupation.categories) {
          return null;
        }
        return (
          <div className="connect-tech-settings__section" key={`${occupation.name}-section`}>
            <h3 className="connect-tech-settings__section-title">
              {`${occupation.name.toUpperCase()} SKILLSET`}
            </h3>
            <SkillsetSection
              skillset={occupation.categories}
              occupation={occupation}
              userId={userId}
              isLink
            />
          </div>
        );
      })
    );
  }

  renderLocationSection() {
    const {
      userId,
      connectTechSettingsStore: { locationSettings: { baseZip } },
    } = this.props;

    // TODO: replace this with actual location services settings once BE ready
    const locationServices = 'Location always on';

    return (
      <Link to={`/connectTechSettings/${userId}/location`} className="text-decoration-none">
        <div className="connect-tech-settings__section">
          <h3 className="connect-tech-settings__section-title">LOCATION</h3>
          <NavSection
            title={<>{baseZip ? `Base Zip ${baseZip}` : 'Set Base Zip Code'}</>}
            subtitle={locationServices}
            icon={LocationIcon}
            isLink
          />
        </div>
      </Link>
    );
  }

  renderTextAlertSection() {
    const {
      userId,
      connectTechSettingsStore: { notificationSettings: { textEnabled } },
    } = this.props;

    return (
      <Link to={`/connectTechSettings/${userId}/notifications`} className="text-decoration-none">
        <div className="connect-tech-settings__section">
          <h3 className="connect-tech-settings__section-title">NOTIFICATION SETTINGS</h3>
          <NavSection
            title={textEnabled ? 'Text Alerts On' : 'Text Alerts Off'}
            subtitle="Get job updates via text message"
            icon={NotificationIcon}
            isLink
          />
        </div>
      </Link>
    );
  }

  renderConnectStatusSection() {
    const {
      userId,
    } = this.props;

    let status = 'Active';
    if (this.independentProfile.optInToMarketplace === false) {
      status = 'Deactivated';
    } else if (this.independentProfile.availabilityToggleState
      === AvailabilityToggle.OVERRIDE_TO_UNAVAILABLE) {
      status = 'Disabled';
    }

    return (
      <Link to={`/connectTechSettings/${userId}/status`} className="text-decoration-none">
        <div className="connect-tech-settings__section">
          <NavSection
            title="Connect Status"
            subtitle={status}
            isLink
          />
        </div>
      </Link>
    );
  }

  render() {
    const {
      userId,
      connectTechSettingsStore: { isError, occupationId, loading },
      occupationsStore: { occupations, errorOccupations },
      isOnboarding,
    } = this.props;

    if (isError || errorOccupations) {
      return (<Redirect to={`/connectTechSettings/${userId}/error`} />);
    }

    if (!occupations || !this.independentProfile
        || !this.basicProfile || !occupationId || loading) {
      return (
        <img className="connect-tech-settings__loading" src={LoadingIcon} alt="Loading" />
      );
    }

    return (
      <div className="connect-tech-settings">
        <div className="connect-tech-settings__title">
          {isOnboarding
            ? <h1 className="connect-tech-settings__title__header">Here&apos;s what we have for you...</h1>
            : (
              <>
                <h1 className="connect-tech-settings__title__header">Connect Settings</h1>
                <p className="connect-tech-settings__title__subheader">Manage the Connect jobs you receive by setting your working hours and skillset.</p>
              </>
            )}
        </div>
        {this.independentProfile && this.renderHoursSection()}
        {this.renderOccupationSections()}
        {!isOnboarding && this.renderLocationSection()}
        {!isOnboarding && this.renderTextAlertSection()}
        {!isOnboarding && this.renderConnectStatusSection()}
      </div>
    );
  }
}

ConnectTechSettings.propTypes = {
  connectTechSettingsStore: PropTypes.shape({
    independentProfileStore: PropTypes.shape({
      independentProfile: MobxPropTypes.observableObject,
    }),
    basicProfileStore: PropTypes.shape({
      basicProfile: MobxPropTypes.observableObject,
    }),
    locationSettings: PropTypes.shape({
      baseZip: PropTypes.string,
    }),
    notificationSettings: PropTypes.shape({
      textEnabled: PropTypes.bool,
    }),
    statusSettings: PropTypes.shape({
      availabilityToggleState: PropTypes.bool,
      optInToMarketplace: PropTypes.bool,
    }),
    occupationId: PropTypes.number,
    isError: PropTypes.bool,
    loading: PropTypes.bool,
  }),
  occupationsStore: PropTypes.shape({
    occupations: MobxPropTypes.observableArray,
    errorOccupations: PropTypes.bool,
    getOccupation: PropTypes.func,
  }),
  userId: PropTypes.string,
  isOnboarding: PropTypes.bool,
  flags: PropTypes.shape({
    [ldFlags.HolidaysAndBlackoutPeriods]: PropTypes.bool,
  }),
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId } } } = ownProps;

    return {
      userId,
    };
  }),
  withFeatureFlagProvider,
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
  })),
  observer,
)(ConnectTechSettings);
