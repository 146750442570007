import axios from 'axios';
import { logger } from './logger';
import { reportError } from './errorReporter';

export const createAxiosInstance = (params) => {
  const instance = axios.create(params);

  instance.interceptors.response.use((value) => Promise.resolve(value), (error) => {
    logger.error(error);

    if (
      error.response
      && (
        error.response.status >= 500
        || (
          error.response.config
          && error.response.config.method
          && error.response.config.method.toLowerCase() !== 'get'
        )
      )
    ) {
      reportError(error);
    }

    return Promise.reject(error);
  });
  return instance;
};
