import React from 'react';
import PropTypes from 'prop-types';

import './EstimateTechHeader.scss';

const EstimateTechHeader = ({
  logo,
  name,
  license,
  phone,
}) => (
  <div className="estimate-tech-header">
    {
      logo && (
        <div className="partner-brand">
          <div className="partner-brand__affiliate">
            Job estimate provided by an affiliate of
          </div>
          <div className="partner-brand__logo">
            <img
              src={logo}
              className="mw-100 mh-100"
              alt="logo"
            />
          </div>
        </div>
      )
    }
    <div className="tech-info">
      {
        !logo && (
          <h2 className="tech-info__title">
            {name}
          </h2>
        )
      }
      {
        !!license && (
          <div className="tech-info__sub">
            {`License ${license}`}
          </div>
        )
      }
      <div className="tech-info__sub">
        {phone}
      </div>
    </div>
  </div>
);
EstimateTechHeader.propTypes = {
  logo: PropTypes.string,
  license: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
};

export default EstimateTechHeader;
