import moment from 'moment-timezone';

export const formatDate = (date, dateFormat) => {
  const dateMoment = moment.isMoment(date) ? date : moment(date);

  return dateMoment.format(dateFormat);
};

export const parseDate = (date, dateFormat) => moment(date, dateFormat);

export const getTimezone = () => moment.tz.guess() || 'America/Los_Angeles';

export const formatTimeWindow = (window) => {
  const startTime = moment().hour(window.start_hour).minute(window.start_min);
  const endTime = moment().hour(window.end_hour).minute(window.end_min);
  const nextDay = (startTime > endTime) ? 'next day' : '';
  return {
    range: `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')} ${nextDay}`,
    overnight: startTime > endTime,
  };
};
