class OccupationModel {
  constructor(occupation) {
    this.id = occupation.id;
    this.name = occupation.default_company_name;
    this.icon = occupation.icon_url;
    this.categories = occupation.categories
      && occupation.categories.map((category) => ({ ...category, enabled: false }));
  }
}

export default OccupationModel;
