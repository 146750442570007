import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactCurrencyInput from 'react-currency-input-field';

const CurrencyInput = ({
  className,
  invalid,
  valid,
  bsSize,
  ...rest
}) => (
  <ReactCurrencyInput
    className={classnames(
      'form-control',
      className,
      invalid && 'is-invalid',
      valid && 'is-valid',
      bsSize ? `form-control-${bsSize}` : false,
    )}
    decimalsLimit={2}
    decimalSeparator="."
    disableGroupSeparators
    {...rest}
  />
);

CurrencyInput.propTypes = {
  className: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  bsSize: PropTypes.string,
  onChange: PropTypes.func,
};

export default CurrencyInput;
