import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../lib/number';

import './EstimateBreakdown.scss';

const EstimateBreakdown = ({
  partsPrice,
  labor,
  tax,
  subtotal,
  isInvoice,
  className,
}) => (
  <div className={className}>
    {
      (partsPrice || labor) ? (
        <>
          {/* When Parts and Labor are available - display breakdown */}
          {
            (!!labor) && (
              <div className="eb__summary-line eb__summary-line--prominent">
                Labor:
                <span className="float-right">
                  {formatMoney(labor / 100)}
                </span>
              </div>
            )
          }
          {
            (!!partsPrice) && (
              <div className="eb__summary-line eb__summary-line--prominent">
                Parts:
                <span className="float-right">
                  {formatMoney(partsPrice / 100)}
                </span>
              </div>
            )
          }
          {
            (!!tax) && (
              <div className="eb__summary-line eb__summary-line--muted">
                Sales Tax:
                <span className="float-right">
                  {formatMoney(tax / 100)}
                </span>
              </div>
            )
          }
          <div className="eb__divider" />

          <div className="eb__summary-line eb__summary-line--total">
            {isInvoice ? 'Job Total:' : 'Estimate:'}
            <span className="eb__summary-total-number float-right">
              {formatMoney((labor + partsPrice + tax) / 100)}
            </span>
          </div>
        </>
      ) : (
        <>
          {/* Otherwise fallback to Subtotal */}
          {
            tax !== 0 && (
              <div className="eb__summary-line eb__summary-line--prominent">
                Subtotal:
                <span className="float-right">
                  {formatMoney(subtotal / 100)}
                </span>
                <div className="eb__summary-line eb__summary-line--muted">
                  Tax:
                  <span className="float-right">
                    {formatMoney(tax / 100)}
                  </span>
                </div>
              </div>
            )
          }
          <div className="eb__divider" />

          <div className="eb__summary-line eb__summary-line--total">
            {isInvoice ? 'Job Total:' : 'Estimate:'}
            <span className="eb__summary-total-number float-right">
              {formatMoney((subtotal + tax) / 100)}
            </span>
          </div>
        </>
      )
    }
  </div>
);
EstimateBreakdown.propTypes = {
  partsPrice: PropTypes.number,
  labor: PropTypes.number,
  tax: PropTypes.number,
  subtotal: PropTypes.number,
  isInvoice: PropTypes.bool,
  className: PropTypes.string,
};

export default EstimateBreakdown;
