import React, { Component } from 'react';
import { Input } from './Reactstrap';

const INVALID_CHARS = [
  '-',
  '+',
  'e',
];

class NumberInput extends Component {
  handleKeyPress = (e) => {
    if (INVALID_CHARS.includes(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    const { ...rest } = this.props;
    return (
      <Input
        {...rest}
        type="number"
        autoComplete="off"
        pattern="\d*"
        onKeyPress={this.handleKeyPress}
      />
    );
  }
}

export default NumberInput;
