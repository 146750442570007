/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';

import {
  EstimateBreakdown,
  EstimateTechHeader,
  EstimateReference,
  EstimateSummary,
  EstimateLayoutWrap,
  EstimateLayout,
  EstimateLayoutBody,
  Text,
  WarrantyInfo,
} from '../../components';
import EstimateType from '../../constants/EstimateType';
import { withProps, compose } from '../../lib/hocUtils';

import '../../styles/pages/estimates-common.scss';

class EstimatePreviewsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: null,
    };
  }

  componentDidMount() {
    const {
      userId,
      jobId,
      estimatePreviewsStore,
      receiptSettingsStore,
      identificationStore,
    } = this.props;

    this.setState({ loading: true });

    Promise.all([
      estimatePreviewsStore.loadEstimatePreviews(userId, jobId),
      receiptSettingsStore.loadReceiptSettings(userId),
      identificationStore.loadIdentification(userId),
    ]).catch((loadingError) => {
      this.setState({ loadingError });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const {
      estimatePreviewsStore: { estimatePreviews },
      receiptSettingsStore: { receiptSettings },
      identificationStore: { identification },
    } = this.props;
    const { loading, loadingError } = this.state;

    if (loadingError) {
      return (
        <div>Error trying to load previews...</div>
      );
    }

    if (!estimatePreviews || !receiptSettings || !identification || loading) {
      return null;
    }

    const {
      user_company_name,
      description_of_work,
      marketplace_job,
      job_ref_id,
      customer_address,
      warranty,
      notesForCustomer,
      customer_name,
      customer_email,
      customer_phone,
      subtotal,
      labor,
      parts_price,
      tax,
      user_phone,
      partnerBrand,
    } = estimatePreviews;

    const { showLicense } = receiptSettings;
    const maybeLicense = identification.find((id) => id.entityType === 'BUSINESS_LICENSE');
    const companyLicense = showLicense ? maybeLicense && maybeLicense.entityId : '';
    const isInvoice = estimatePreviews.type === EstimateType.Invoice;

    return (
      <EstimateLayoutWrap>
        <EstimateLayout isInvoice={isInvoice}>
          <EstimateTechHeader
            logo={partnerBrand?.logoUrl}
            license={companyLicense}
            name={user_company_name}
            phone={user_phone}
          />

          <EstimateReference
            jobRefId={job_ref_id}
            brandColor={partnerBrand?.hexColor}
          />

          <EstimateSummary
            customerName={customer_name}
            customerPhone={customer_phone}
            customerEmail={customer_email}
            customerAddress={customer_address}
            descriptionOfWork={description_of_work}
            maskPhone={marketplace_job}
          />

          <EstimateLayoutBody>
            <EstimateBreakdown
              labor={labor}
              partsPrice={parts_price}
              tax={tax}
              subtotal={subtotal}
              isInvoice={isInvoice}
            />
          </EstimateLayoutBody>
          {
            // Display Warranty info or Notes based on the type
            isInvoice ? (
              warranty && (
                <WarrantyInfo
                  className="estimates-common__warranty"
                  content={warranty}
                />
              )
            ) : (
              notesForCustomer && (
                <div className="estimates-common__notes">
                  <Text content={notesForCustomer} />
                </div>
              )
            )
          }
        </EstimateLayout>
      </EstimateLayoutWrap>
    );
  }
}

EstimatePreviewsPage.propTypes = {
  estimatePreviewsStore: PropTypes.shape({
    estimatePreviews: MobXPropTypes.observableObject,
    loadEstimatePreviews: PropTypes.func.isRequired,
  }).isRequired,

  receiptSettingsStore: PropTypes.shape({
    receiptSettings: MobXPropTypes.observableObject,
    loadReceiptSettings: PropTypes.func.isRequired,
  }).isRequired,

  identificationStore: PropTypes.shape({
    identification: MobXPropTypes.observableObject,
    loadIdentification: PropTypes.func.isRequired,
  }).isRequired,

  userId: PropTypes.string,
  jobId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId, jobId } } } = ownProps;

    return {
      userId,
      jobId,
    };
  }),
  inject('estimatePreviewsStore'),
  inject('receiptSettingsStore'),
  inject('identificationStore'),
  inject('occupationsStore'),
  observer,
)(EstimatePreviewsPage);
