class BasicProfileModel {
  constructor(basicProfile) {
    if (basicProfile) {
      this.roles = basicProfile.roles;
      this.preferredTechnician = basicProfile.preferred_technician;
      this.skillSet = basicProfile.skill_set;
      this.notes = basicProfile.notes;
      this.complete = basicProfile.complete;
      this.baseZip = basicProfile.base_zip_code;
      this.marketplace = basicProfile.marketplace;
    }
  }
}

export default BasicProfileModel;
