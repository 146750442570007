import React from 'react';
import PropTypes from 'prop-types';
import { TabPane as BSTabPane } from 'reactstrap';

const TabPane = ({ tab, children, ...otherProps }) => (
  <BSTabPane tabId={tab} {...otherProps}>
    { children }
  </BSTabPane>
);

TabPane.propTypes = {
  tab: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default TabPane;
