import config from '../config';

export const ENV = {
  DEV: 'dev',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const isProduction = config.APP_ENV === ENV.PRODUCTION;

export const isDevMode = config.APP_ENV === ENV.DEV;
