import {
  init,
  configureScope,
  captureException,
  captureMessage,
} from '@sentry/browser';
import config from '../config';
import { logger } from './logger';
import { isDevMode } from './env';
import packageJson from '../../package.json';

// Init
if (config.SENTRY_DSN_SERVER) {
  init({
    dsn: config.SENTRY_DSN_SERVER,
    environment: config.APP_ENV,
    release: packageJson.version,
    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') {
        return null;
      }
      return event;
    },
  });

  configureScope((scope) => {
    scope.setTag('app', 'client');
  });
} else {
  logger.warn('Sentry DNS server is not set - skipping initialization');
}

export const reportError = (error, errorInfo) => {
  if (error instanceof Error) {
    captureException(error);
  } else if (typeof error === 'string') {
    captureMessage(error);
  } else {
    configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
  }
  if (isDevMode) {
    logger.log('Reporting error: ', error);
  }
};
