import moment from 'moment-timezone';

const fixSignatureUrlProtocol = (signatureUrl) => {
  if (!signatureUrl.startsWith('http://') && !signatureUrl.startsWith('https://')) {
    return `https://${signatureUrl}`;
  }

  return signatureUrl;
};

const getSignatureUrl = (signatureUrls, paymentLogId) => {
  const signatureUrl = signatureUrls.find((url) => {
    const signaturePaymentLogId = url.split('-').reverse()[0];
    return Number.parseInt(signaturePaymentLogId, 10) === paymentLogId;
  });

  return signatureUrl ? fixSignatureUrlProtocol(signatureUrl) : null;
};

class ReceiptModel {
  constructor(receipt) {
    const receiptBaseData = receipt.receipt_base_data;
    this.id = receiptBaseData.receipt_id;
    this.jobId = receiptBaseData.job_id;
    this.jobRefId = receiptBaseData.job_reference_id.slice(3);
    this.jobIsMarketplace = receiptBaseData.job_marketplace;
    this.notes = receiptBaseData.notes;
    this.descriptionOfwork = receiptBaseData.description_of_work;
    this.address = receiptBaseData.description
      ? receiptBaseData.description.address : null;
    this.phoneNumber = receiptBaseData.description
      ? receiptBaseData.description.phone_number : null;
    this.serviceProviderCompany = receiptBaseData.provider_company;
    this.serviceProviderPhone = receiptBaseData.provider_number;
    this.billingPhone = receiptBaseData.billing_phone;
    this.license = receiptBaseData.license;
    this.occupation = receiptBaseData.occupation || 'Services';
    this.customerName = receiptBaseData.customer_name;
    this.customerCompany = receiptBaseData.customer_company;
    this.createdAt = moment.tz(receiptBaseData.created_at, receiptBaseData.timezone);
    this.addressMapUrl = receiptBaseData.address_map_url || '';
    this.techAffiliation = receiptBaseData.tech_affiliation;
    this.themeColor = receiptBaseData.theme_hex
      ? receiptBaseData.theme_hex
      : 'linear-gradient(to right,#12a5f8 0,#198dfc 100%)';
    this.logoUrl = receiptBaseData.url_logo;

    const cost = Number.parseFloat(receiptBaseData.cost) || 0;
    const tax = Number.parseFloat(receiptBaseData.tax) || 0;
    const tip = Number.parseFloat(receiptBaseData.tip) || 0;
    const partsPrice = Number.parseFloat(receiptBaseData.parts_price) || 0;
    const labor = Number.parseFloat(receiptBaseData.labor) || 0;

    this.payment = {
      amount: receiptBaseData.amount,
      subtotal: cost - tax, // subtracting tax as cost includes it
      tip,
      tax,
      labor,
      partsPrice,
    };
    this.refund = receipt.refund;
    this.paymentLog = (receipt.payment_log || []).map((paymentLogItem) => ({
      id: paymentLogItem.id,
      amount: paymentLogItem.amount,
      tip: paymentLogItem.tip,
      type: paymentLogItem.payment_type,
      transactionType: paymentLogItem.transaction_type,
      refundedPaymentLogId: paymentLogItem.refunded_payment_log_id,
      creditCardDetails: paymentLogItem.card_payment_details ? {
        brand: paymentLogItem.card_payment_details.brand,
        last4digits: paymentLogItem.card_payment_details.last4,
        signatureUrl: getSignatureUrl(receipt.cc_signature_url, paymentLogItem.id),
      } : null,
    }));
  }
}

export default ReceiptModel;
