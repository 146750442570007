import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import classes from './dateInput.module.scss';

export const DateInput = ({
  id,
  label,
  value,
  min,
  onChange,
  className,
}) => {
  const changeHandler = (ev) => {
    onChange(
      moment(ev.target.value).valueOf(),
    );
  };

  return (
    <label htmlFor={id} className={cn(classes.date, className)}>
      <input
        id={id}
        type="date"
        min={moment(min || undefined).format('YYYY-MM-DD')}
        value={moment(value).format('YYYY-MM-DD')}
        onChange={changeHandler}
        className={classes.date__input}
      />
      <span className={classes.date__display}>
        { moment(value).format('M/D/YY') }
      </span>
      {
        label && (
          <span className={classes.date__label}>
            { label }
          </span>
        )
      }
    </label>
  );
};
DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
