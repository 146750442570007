/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { withProps, compose } from '../../lib/hocUtils';
import ToggleItem from '../../components/ConnectTechSettings/ToggleItem';
import BackArrowIcon from '../../images/ConnectTechSettings/icon-left-arrow.svg';

import './Location.scss';

class Notification extends Component {
  onToggle = () => {
    const {
      connectTechSettingsStore: {
        notificationSettings: { textEnabled },
        setNotificationSettings,
      },
    } = this.props;
    setNotificationSettings({ textEnabled: !textEnabled });
  };

  render() {
    const {
      connectTechSettingsStore: {
        notificationSettings: { textEnabled },
      },
      history,
    } = this.props;
    return (
      <div className="connect-tech-settings">
        <img
          className="connect-tech-settings__back-arrow"
          src={BackArrowIcon}
          alt="Back"
          onClick={history.goBack}
        />
        <div className="connect-tech-settings__location__title">
          <div className="connect-tech-settings__title__header">
            Text Alerts
          </div>
        </div>
        <div className="connect-tech-settings__location__body">
          <p className="connect-tech-settings__location__body__info">
            Make sure you don’t miss a job update by turning on text alerts.
            {' '}
            You’ll get a notification and a text,
            {' '}
            <strong>even if you have poor network connection.</strong>
          </p>
        </div>
        <ToggleItem
          title="Text Alerts"
          enabled={textEnabled}
          onToggle={this.onToggle}
        />
        <div className="connect-tech-settings__location__body__subtext">
          Carrier message and data rates may apply.
        </div>
      </div>
    );
  }
}
Notification.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  connectTechSettingsStore: PropTypes.shape({
    notificationSettings: PropTypes.shape({
      textEnabled: PropTypes.bool,
    }),
    setNotificationSettings: PropTypes.func,
  }),
};
export default compose(
  withProps((ownProps) => {
    const {
      match: {
        params: { userId },
      },
      history,
    } = ownProps;

    return {
      userId,
      history,
    };
  }),
  withRouter,
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
  })),
  observer,
)(Notification);
