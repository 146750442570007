import React from 'react';
import PropTypes from 'prop-types';

const DescriptionOfWork = ({ text }) => (
  <>
    {
      text.split('\n').map((line, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={key}>
          {line}
          <br />
        </p>
      ))
    }
  </>
);
DescriptionOfWork.propTypes = {
  text: PropTypes.string,
};

export default DescriptionOfWork;
