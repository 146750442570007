import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PaymentSignature from './PaymentSignature';

import PaymentType from '../../../constants/PaymentType';
import TransactionType from '../../../constants/TransactionType';
import { formatMoney } from '../../../lib/number';
import { getCCIcon, formatCCBrand } from '../../../lib/creditCardUtils';

import iconCash from '../../../images/icon-cash.svg';
import iconCheck from '../../../images/iconCheck.png';
import iconExternal from '../../../images/icon-external.svg';

import './PaymentLogDetails.scss';

const PaymentLogItem = ({
  label,
  icon,
  amount,
  tip,
  className,
}) => (
  <div className={classnames('payment-log-details__payment', className)}>
    <div className="payment-log-details__row">
      <div className="payment-log-details__info">
        {icon && <img className="payment-log-details__icon" src={icon} alt={label} />}
        <div className="payment-log-details__type">{label}</div>
      </div>
      <div className="payment-log-details__amount">
        {formatMoney(amount + (tip || 0))}
        {
          tip > 0 && (
            <span className="payment-log-details__amount__tip-breakdown">
              {`(${formatMoney(amount)} + ${formatMoney(tip)} tip)`}
            </span>
          )
        }
      </div>
    </div>
  </div>
);

PaymentLogItem.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  icon: PropTypes.string,
  tip: PropTypes.number,
  className: PropTypes.string,
};

const PaymentLogDetails = ({
  paymentLog,
  className,
  jobRefId,
  customerName,
  previewMode,
}) => {
  const paymentPaymentLog = paymentLog
    .filter((paymentLogItem) => paymentLogItem.transactionType === TransactionType.PAYMENT);
  const refundPaymentLog = paymentLog
    .filter((paymentLogItem) => (
      paymentLogItem.transactionType === TransactionType.REFUND
      && !paymentLogItem.refundedPaymentLogId
    ));

  const creditCardPaymentLog = paymentPaymentLog
    .filter((paymentLogItem) => paymentLogItem.type === PaymentType.CARD);

  const cashPaymentAmount = paymentPaymentLog
    .filter((paymentLogItem) => paymentLogItem.type === PaymentType.CASH)
    .reduce((sum, paymentLogItem) => sum + paymentLogItem.amount, 0);
  const externalPaymentAmount = paymentPaymentLog
    .filter((paymentLogItem) => paymentLogItem.type === PaymentType.EXTERNAL)
    .reduce((sum, paymentLogItem) => sum + paymentLogItem.amount, 0);
  const checkPaymentAmount = paymentPaymentLog
    .filter((paymentLogItem) => paymentLogItem.type === PaymentType.CHECK)
    .reduce((sum, paymentLogItem) => sum + paymentLogItem.amount, 0);

  return (
    <div className={classnames('payment-log-details', className)}>
      {!!paymentLog.length && (
        <div className="payment-log-details__payments">
          {!!cashPaymentAmount && (
            <PaymentLogItem
              amount={cashPaymentAmount}
              label="cash"
              icon={iconCash}
            />
          )}
          {!!externalPaymentAmount && (
            <PaymentLogItem
              amount={externalPaymentAmount}
              label="external payment"
              icon={iconExternal}
            />
          )}
          {!!checkPaymentAmount && (
            <PaymentLogItem
              amount={checkPaymentAmount}
              label="check"
              icon={iconCheck}
            />
          )}
          {creditCardPaymentLog.map((paymentLogItem) => {
            let label;
            let icon;

            if (paymentLogItem.creditCardDetails) {
              const { last4digits } = paymentLogItem.creditCardDetails;
              label = last4digits ? `ending in ${last4digits}` : formatCCBrand(paymentLogItem.creditCardDetails.brand);
              icon = getCCIcon(paymentLogItem.creditCardDetails.brand);
            }

            const ccRefundPaymentLog = paymentLog.filter(
              (pli) => pli.refundedPaymentLogId === paymentLogItem.id,
            );

            return (
              <Fragment key={paymentLogItem.id}>
                <PaymentLogItem
                  amount={paymentLogItem.amount}
                  tip={paymentLogItem.tip}
                  label={label || 'credit card'}
                  icon={icon}
                />
                {ccRefundPaymentLog.map((refundPaymentLogItem) => (
                  <PaymentLogItem
                    key={refundPaymentLogItem.id}
                    className="payment-log-details__payment--ccrefund"
                    amount={-(refundPaymentLogItem.amount + refundPaymentLogItem.tip)}
                    label="Refund"
                  />
                ))}
              </Fragment>
            );
          })}
          {/* This is necessary for old receipts where refunds was not attached to paymentLogId */}
          {refundPaymentLog.map((refundPaymentLogItem) => (
            <PaymentLogItem
              key={refundPaymentLogItem.id}
              className="payment-log-details__payment--refund"
              amount={-refundPaymentLogItem.amount}
              label="Refund"
            />
          ))}
        </div>
      )}

      {creditCardPaymentLog.map((payment) => (
        payment.creditCardDetails?.signatureUrl && (
          <PaymentSignature
            key={payment.id}
            className="payment-log-details__signature"
            jobRefId={jobRefId}
            previewMode={previewMode}
            signatureUrl={payment.creditCardDetails?.signatureUrl}
            creditCardBrand={payment.creditCardDetails?.brand}
            creditCardLast4Digits={payment.creditCardDetails?.last4digits}
            creditCardBillingName={customerName}
          />
        )
      ))}
    </div>
  );
};

PaymentLogDetails.propTypes = {
  jobRefId: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  previewMode: PropTypes.bool,
  paymentLog: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
    tip: PropTypes.number,
    type: PropTypes.string,
    creditCardDetails: PropTypes.shape({
      brand: PropTypes.string,
      last4digits: PropTypes.string,
      signatureUrl: PropTypes.string,
    }),
  })),
  className: PropTypes.string,
};

export default PaymentLogDetails;
