import './safeStart';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';
import cookieParser from 'cookie';
import moment from 'moment';

import ApiService from './services/ApiService';

import { createAxiosInstance } from './lib/axios';

import TransactionStore from './stores/TransactionStore';
import OccupationsStore from './stores/OccupationsStore';
import ConversationStore from './stores/ConversationStore';
import IdentificationStore from './stores/IdentificationStore';
import ReceiptStore from './stores/ReceiptStore';
import ApprovedEstimateStore from './stores/ApprovedEstimateStore';
import EstimateStore from './stores/EstimateStore';
import EstimatePreviewsStore from './stores/EstimatePreviewsStore';
import ReceiptSettingsStore from './stores/ReceiptSettingsStore';
import RemoteApprovalStore from './stores/RemoteApprovalStore';
import JobStore from './stores/JobStore';
import ConnectTechSettingsStore from './stores/ConnectTechSettingsStore';
import HolidaysStore from './stores/HolidaysStore';

import App from './App';

// Polyfill for Microsoft Edge <= 17
import 'core-js/features/promise/finally';

const apiService = new ApiService(createAxiosInstance({ baseURL: '/api/v1' }));

const cookies = cookieParser.parse(document.cookie);
const authApiService = new ApiService(createAxiosInstance({
  baseURL: '/api/v1',
  headers: {
    Authorization: cookies.Authorization || '',
    'User-Id': cookies['User-Id'] || '',
    Timestamp: cookies.Timestamp || '',
    Timezone: cookies.Timezone || moment.tz.guess() || 'America/Los_Angeles',
  },
}));

const occupationsStore = new OccupationsStore(apiService);
const stores = {
  occupationsStore,
  transactionStore: new TransactionStore(apiService),
  conversationStore: new ConversationStore(apiService),
  receiptStore: new ReceiptStore(apiService),
  estimateStore: new EstimateStore(authApiService),
  approvedEstimateStore: new ApprovedEstimateStore(authApiService),
  estimatePreviewsStore: new EstimatePreviewsStore(authApiService),
  receiptSettingsStore: new ReceiptSettingsStore(authApiService),
  identificationStore: new IdentificationStore(authApiService),
  jobStore: new JobStore(authApiService),
  remoteApprovalStore: new RemoteApprovalStore(apiService),
  connectTechSettingsStore: new ConnectTechSettingsStore(authApiService, occupationsStore),
  holidaysStore: new HolidaysStore(authApiService),
};

ReactDOM.render((
  <Provider {...stores}>
    <Router>
      <App userId={cookies['User-Id'] || ''} />
    </Router>
  </Provider>
), document.getElementById('root'));
