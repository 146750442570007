class JobModel {
  constructor(job) {
    this.id = job.id;
    this.occupationId = job.occupation_id;
    this.active = job.active;
    this.createdAt = job.createdAt;
    this.created_id = job.created_id;
    this.description = job.description;
    this.notes = job.notes;
    this.reference_id = job.reference_id.substr(3);
  }
}

export default JobModel;
