import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

import LoadingIcon from '../../images/loading.gif';
import ToggleItem from '../../components/ConnectTechSettings/ToggleItem';
import { withProps, compose } from '../../lib/hocUtils';

import './ConnectTechSettings.scss';

class JobTypes extends Component {
  // Shorthand for the rest of the code
  get independentProfile() {
    const {
      connectTechSettingsStore: { independentProfileStore },
    } = this.props;
    return independentProfileStore
      ? independentProfileStore.independentProfile
      : null;
  }

  get basicProfile() {
    const {
      connectTechSettingsStore: { basicProfileStore },
    } = this.props;
    return basicProfileStore ? basicProfileStore.basicProfile : null;
  }

  render() {
    const {
      userId,
      categories,
      setCategories,
      connectTechSettingsStore: { isError, isLoading },
      occupationsStore: { errorOccupations },
    } = this.props;

    if (isError || errorOccupations) {
      return <Redirect to={`/connectTechSettings/${userId}/onboarding/error`} />;
    }

    if (isLoading || !categories || categories.length === 0) {
      return (
        <img
          className="connect-tech-settings__loading"
          src={LoadingIcon}
          alt="Loading"
        />
      );
    }

    return (
      <div className="connect-tech-settings">
        <div className="connect-tech-settings__title__question">
          <h1 className="connect-tech-settings__title__header">
            What kind of jobs do you do?
          </h1>
        </div>
        <div className="connect-tech-settings__section">
          {
            categories && categories.map((category) => (
              <ToggleItem
                ref={category.id}
                key={category.id}
                title={category.categoryName}
                enabled={category.enabled}
                icon={category.icon}
                onToggle={() => setCategories(
                  categories.map((x) => (x.id === category.id ? { ...x, enabled: !x.enabled } : x)),
                )}
              />
            ))
          }
        </div>
      </div>
    );
  }
}
JobTypes.propTypes = {
  connectTechSettingsStore: PropTypes.shape({
    isError: PropTypes.bool,
    independentProfileStore: PropTypes.shape({
      independentProfile: MobxPropTypes.observableObject,
    }),
    basicProfileStore: PropTypes.shape({
      basicProfile: MobxPropTypes.observableObject,
    }),
    isLoading: PropTypes.bool,
  }).isRequired,
  occupationsStore: PropTypes.shape({
    occupations: MobxPropTypes.observableArray,
    errorOccupations: PropTypes.bool,
  }).isRequired,
  userId: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryName: PropTypes.string,
      enabled: PropTypes.bool,
      icon: PropTypes.string,
    }),
  ),
  setCategories: PropTypes.func,
};

export default compose(
  withProps((ownProps) => {
    const {
      match: {
        params: { userId },
      },
    } = ownProps;

    return {
      userId,
    };
  }),
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
  })),
  observer,
)(JobTypes);
