import { observable, makeObservable, action } from 'mobx';
import { getTimezone } from '../lib/date';
import { reportError } from '../lib/errorReporter';
import ReceiptModel from '../models/ReceiptModel';

const receiptKeyFormatRegEx = /^\d+-\d+(-\w+)?$/gm;

class ReceiptStore {
  receipt = null;
  rating = null;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      receipt: observable,
      rating: observable,

      loadReceipt: action,
      loadRating: action,
      submitFeedback: action,
    });
  }

  loadReceipt(receiptKey, preview) {
    return this.apiService.getReceipt(receiptKey, preview, getTimezone())
      .then(action((receipt) => {
        this.receipt = new ReceiptModel(receipt);
      })).then(() => this.loadRating(this.receipt.jobId))
      .catch((exc) => {
        // If failed to load check the receiptKey format and
        // notify sentry if it is off
        if (!receiptKeyFormatRegEx.test(receiptKey)) {
          const formatError = new Error('Receipt key has invalid format.');

          reportError(formatError, { receiptKey });

          throw formatError;
        }
        throw exc;
      });
  }

  loadRating(jobId) {
    return this.apiService.getRating(jobId)
      .then(action((data) => {
        this.rating = data;
      })).catch(() => {});
  }

  submitFeedback(jobId, rating, feedbackOptionsArray, notes) {
    return this.apiService.submitRating(jobId, {
      rating,
      notes,
      category: feedbackOptionsArray,
    });
  }

  updateFeedback(jobId, rating, feedbackOptionsArray, notes, captchaToken) {
    return this.apiService.updateRating(jobId, {
      rating,
      notes,
      category: feedbackOptionsArray,
      captchaToken,
    });
  }
}

export default ReceiptStore;
