import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatMoney } from '../../../lib/number';

import './PaymentDetails.scss';

const PaymentDetails = ({
  subtotal,
  tip,
  tax,
  labor,
  partsPrice,
  subtotalRefund,
  taxRefund,
  tipRefund,
  className,
}) => (
  <div className={classnames('payment-details', className)}>
    {
      /* Display the breakdown only when there is partsPrice or tax available */
      !!(partsPrice || tax) && (
        <>
          {
            (partsPrice || labor) ? (
              <>
                {/* Parts & Labor job breakdown */}
                {!!labor && (
                  <div className="payment-details__row payment-details__row--prominent">
                    <div className="payment-details__type">Labor</div>
                    <div className="payment-details__amount">{formatMoney(labor)}</div>
                  </div>
                )}
                {!!partsPrice && (
                  <div className="payment-details__row payment-details__row--prominent">
                    <div className="payment-details__type">Parts</div>
                    <div className="payment-details__amount">{formatMoney(partsPrice)}</div>
                  </div>
                )}
                {!!tax && (
                  <div className="payment-details__row payment-details__row--muted">
                    <div className="payment-details__type">Sales Tax</div>
                    <div className="payment-details__amount">{formatMoney(tax)}</div>
                  </div>
                )}
              </>
            ) : (
              <>
                {/* Subtotal job breakdown */}
                <div className="payment-details__row payment-details__row--prominent">
                  <div className="payment-details__type">Subtotal</div>
                  <div className="payment-details__amount">{formatMoney(subtotal)}</div>
                </div>
                {!!tax && (
                  <div className="payment-details__row payment-details__row--muted">
                    <div className="payment-details__type">Tax</div>
                    <div className="payment-details__amount">{formatMoney(tax)}</div>
                  </div>
                )}
              </>
            )
          }
          <div className="receipt-page__divider" />
        </>
      )
    }
    <div className="payment-details__row payment-details__row--total">
      <div className="payment-details__type">Job Total</div>
      <div className="payment-details__amount">{formatMoney(subtotal + tax)}</div>
    </div>
    {
      !!tip && (
        <div className="payment-details__row">
          <div className="payment-details__type">Tip</div>
          <div className="payment-details__amount">{formatMoney(tip)}</div>
        </div>
      )
    }
    {
      !!(tipRefund || subtotalRefund || taxRefund) && (
        <div className="payment-details__row payment-details__row--refund">
          <div className="payment-details__type">{(!!tax || !!tip) ? 'Total Refund' : 'Refund'}</div>
          <div className="payment-details__amount">{formatMoney(-1 * (tipRefund + subtotalRefund + taxRefund))}</div>
        </div>
      )
    }
  </div>
);

PaymentDetails.propTypes = {
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number,
  tip: PropTypes.number,
  labor: PropTypes.number,
  partsPrice: PropTypes.number,
  subtotalRefund: PropTypes.number,
  taxRefund: PropTypes.number,
  tipRefund: PropTypes.number,
  className: PropTypes.string,
};

export default PaymentDetails;
