/* eslint-disable camelcase */
class TransactionModel {
  constructor(transaction) {
    this.id = transaction.job.id;
    this.jobRefId = transaction.job.reference_id.slice(3);
    this.occupationName = transaction.occupation.name;
    this.userId = transaction.user && transaction.user.id;
    this.companyName = transaction.user && transaction.user.company_name;

    if (transaction.payment_log) {
      const { amount, credit_card_payment } = transaction.payment_log;
      this.payment = {
        amount,
        last4: credit_card_payment && credit_card_payment.last4,
        brand: credit_card_payment && credit_card_payment.brand,
      };
    } else {
      this.payment = {
        amount: transaction.payment.cost,
      };
    }

    this.payment.createdAt = new Date(transaction.payment.createdAt);
  }
}

export default TransactionModel;
