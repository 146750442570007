import { observable, action, makeObservable } from 'mobx';

import { getTimezone } from '../lib/date';
import EstimatePreviewsModel from '../models/EstimatePreviewsModel';

class EstimatePreviewsStore {
  estimatePreviews = null;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      estimatePreviews: observable,

      loadEstimatePreviews: action,
      setCustomerName: action,
    });
  }

  loadEstimatePreviews(userId, jobId) {
    return this.apiService.getEstimatePreviews(userId, jobId, getTimezone())
      .then(action((estimatePreviews) => {
        this.estimatePreviews = new EstimatePreviewsModel(estimatePreviews);
      }));
  }

  setCustomerName(val) {
    this.estimatePreviews.customer_name = val;
  }
}

export default EstimatePreviewsStore;
