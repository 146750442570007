/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';

import {
  EstimateBreakdown,
  EstimateTechHeader,
  EstimateReference,
  EstimateSummary,
  EstimateLayoutWrap,
  EstimateLayout,
  EstimateLayoutBody,
  Text,
  WarrantyInfo,
} from '../../components';
import EstimateType from '../../constants/EstimateType';
import { withProps, compose } from '../../lib/hocUtils';

import '../../styles/pages/estimates-common.scss';
import './EstimatePage.scss';

class EstimatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: null,
    };
  }

  componentDidMount() {
    const {
      userId,
      jobId,
      estimateStore,
      receiptSettingsStore,
      identificationStore,
      jobStore,
      occupationsStore,
    } = this.props;

    this.setState({ loading: true });
    Promise.all([
      estimateStore.loadEstimate(userId, jobId),
      receiptSettingsStore.loadReceiptSettings(userId),
      identificationStore.loadIdentification(userId),
      jobStore.loadJob(jobId),
      occupationsStore.loadOccupations(),
    ]).catch((loadingError) => {
      this.setState({ loadingError });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const {
      estimateStore: { estimate },
      receiptSettingsStore: { receiptSettings },
      identificationStore: { identification },
      jobStore: { job },
      occupationsStore: { allOccupations },
    } = this.props;
    const { loading, loadingError } = this.state;

    if (loadingError) {
      return (
        <div>Error trying to load estimate...</div>
      );
    }

    if (!estimate || !receiptSettings || !identification || !job || !allOccupations || loading) {
      return null;
    }
    const {
      approved,
      approved_at,
      signatureURL,
      jobMarketplace,
      serviceProviderPhone,
      serviceProviderCompany,
      jobRefId,
      type,
      notes,
      customerName,
      customerEmail,
      customerAddress,
      customerPhone,
      warranty,
      subtotal,
      tax,
      labor,
      parts_price,
      descriptionOfWork,
      partnerBrand,
    } = estimate;

    const {
      showCompanyName,
      showLicense,
      useReceiptContactNumber,
      receiptContactNumber,
    } = receiptSettings;

    const { occupationId } = job;

    const maybeOccupation = allOccupations.find((occ) => occ.id === occupationId);
    const companyNameOrJobType = (showCompanyName && serviceProviderCompany !== '')
      ? serviceProviderCompany
      : (maybeOccupation && maybeOccupation.name);
    const maybeLicense = identification.find((id) => id.entityType === 'BUSINESS_LICENSE');
    const companyLicense = showLicense ? maybeLicense && maybeLicense.entityId : '';
    const companyContactPhone = useReceiptContactNumber
      ? receiptContactNumber
      : serviceProviderPhone;
    const isInvoice = type === EstimateType.Invoice;

    return (
      <EstimateLayoutWrap>
        {
          !!approved && (
            <div className="estimate-page__approved-container">
              <div className="estimate-page__approved">
                <span className="estimate-page__approved-date float-left">{approved_at}</span>
                <span className="estimate-page__approved-color">APPROVED</span>
              </div>
            </div>
          )
        }

        <EstimateLayout isInvoice={isInvoice}>
          <EstimateTechHeader
            logo={partnerBrand?.logoUrl}
            license={companyLicense}
            name={companyNameOrJobType}
            phone={companyContactPhone}
          />

          <EstimateReference
            jobRefId={jobRefId}
            brandColor={partnerBrand?.hexColor}
          />

          <EstimateSummary
            customerName={customerName}
            customerPhone={customerPhone}
            customerEmail={customerEmail}
            customerAddress={customerAddress}
            descriptionOfWork={descriptionOfWork}
            maskPhone={jobMarketplace}
          />

          <EstimateLayoutBody>
            <EstimateBreakdown
              labor={labor}
              partsPrice={parts_price}
              tax={tax}
              subtotal={subtotal}
              isInvoice={isInvoice}
            />
          </EstimateLayoutBody>
          {
            // Display Warranty info or Notes based on the type
            isInvoice ? (
              warranty && (
                <WarrantyInfo
                  className="estimates-common__warranty"
                  content={warranty}
                />
              )
            ) : (
              notes && (
                <div className="estimates-common__notes">
                  <Text content={notes} />
                </div>
              )
            )
          }

          {
            approved && (
              <>
                <div className="estimate-page__signature-container">
                  <div className="estimage-page__signature-text">Customer Approval</div>
                  <img className="estimate-page__signature-img" alt="customer-signature" src={signatureURL} />
                </div>
                <div className="estimate-page__signature-footnote">
                  With my signature, I authorize the services described above,
                  {' '}
                  and agree to pay the full amount upon completion of the job.
                </div>
              </>
            )
          }
        </EstimateLayout>
      </EstimateLayoutWrap>
    );
  }
}
EstimatePage.propTypes = {
  estimateStore: PropTypes.shape({
    estimate: MobXPropTypes.observableObject,
    loadEstimate: PropTypes.func.isRequired,
  }).isRequired,
  receiptSettingsStore: PropTypes.shape({
    receiptSettings: MobXPropTypes.observableObject,
    loadReceiptSettings: PropTypes.func.isRequired,
  }).isRequired,
  identificationStore: PropTypes.shape({
    identification: MobXPropTypes.observableObject,
    loadIdentification: PropTypes.func.isRequired,
  }).isRequired,
  jobStore: PropTypes.shape({
    job: MobXPropTypes.observableObject,
    loadJob: PropTypes.func.isRequired,
  }).isRequired,
  occupationsStore: PropTypes.shape({
    allOccupations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
    loadOccupations: PropTypes.func.isRequired,
  }).isRequired,
  userId: PropTypes.string,
  jobId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId, jobId } } } = ownProps;

    return {
      userId,
      jobId,
    };
  }),
  inject('estimateStore'),
  inject('receiptSettingsStore'),
  inject('identificationStore'),
  inject('jobStore'),
  inject('occupationsStore'),
  observer,
)(EstimatePage);
