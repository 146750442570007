import { action, makeObservable } from 'mobx';

class ConversationStore {
  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      createConversation: action,
    });
  }

  createConversation(conversation) {
    return this.apiService.createConversation(conversation);
  }
}

export default ConversationStore;
