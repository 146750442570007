/* eslint-disable camelcase */
import React, { Component } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

import PdfViewer from '../../components/PdfViewer';
import { formatPhoneNumber } from '../../lib/phone';
import { withProps, compose } from '../../lib/hocUtils';

import './EstimatePdf.scss';

class EstimatePdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: null,
    };
  }

  componentDidMount() {
    const { userId, jobId, remoteApprovalStore } = this.props;

    this.setState({ loading: true });
    remoteApprovalStore.loadEstimate(userId, jobId)
      .catch((loadingError) => {
        this.setState({ loadingError });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { remoteApprovalStore } = this.props;
    const { loading, loadingError } = this.state;

    if (loadingError) {
      return (
        <div>Error trying to load PDF...</div>
      );
    }

    if (!remoteApprovalStore.estimate || loading) {
      return null;
    }

    const {
      user_phone_number,
      approved,
      signed_pdf_urlpath,
    } = remoteApprovalStore.estimate;

    return (
      <>
        {
          (!approved) ? (
            <div className="remote-approval-page__call-containter">
              <span>This estimate is out of date</span>
              {
                user_phone_number && (
                  <a
                    className="btn btn-primary btn-lg d-block remote-approval-page__call-button"
                    href={`tel:${user_phone_number}`}
                  >
                    Call
                    {' '}
                    {formatPhoneNumber(user_phone_number)}
                  </a>
                )
              }
            </div>
          ) : (
            <PdfViewer
              url={signed_pdf_urlpath}
            />
          )
        }
      </>
    );
  }
}

EstimatePdf.propTypes = {
  remoteApprovalStore: PropTypes.shape({
    estimate: MobXPropTypes.observableObject,
    loadEstimate: PropTypes.func.isRequired,
  }).isRequired,

  userId: PropTypes.string,
  jobId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId, jobId } } } = ownProps;

    return {
      userId,
      jobId,
    };
  }),
  inject('remoteApprovalStore'),
  observer,
)(EstimatePdf);
