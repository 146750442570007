import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Header.scss';

const Header = ({
  className,
  email,
  title,
  text,
}) => (
  <header className={classNames('header', className)}>
    <div className="header__row">
      <a className="text-link header__brand-name" href="/">View Invoice</a>
      <div className="header__contacts">
        <a
          className="text-link header__email"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${email}`}
        >
          { email }
        </a>
      </div>
    </div>
    {
      title && (
        <h1 className="header__title">{title}</h1>
      )
    }
    {
      text && (
        <div className="header__text">{text}</div>
      )
    }
  </header>
);

Header.propTypes = {
  email: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

Header.defaultProps = {
  email: 'help@viewinvoice.com',
};

export default Header;
