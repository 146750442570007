import React from 'react';
import PropTypes from 'prop-types';

const Text = ({ content }) => {
  const textParts = (content || '').split('\n');

  return textParts.map((text, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      {text}
      {index < textParts.length - 1 && <br />}
    </React.Fragment>
  ));
};
Text.propTypes = {
  content: PropTypes.string,
};

export default Text;
