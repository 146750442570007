/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { compose } from '../../../lib/hocUtils';
import LoadingIcon from '../../../images/ConnectTechSettings/transparent-spinner.gif';
import { AvailabilityToggle } from '../../../models/ConnectTechSettings/IndependentProfileModel';
import Modal from '../Modal/Modal';

import './AvailabilityModal.scss';

class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  get independentProfile() {
    const { independentProfileStore } = this.props;
    return independentProfileStore ? independentProfileStore.independentProfile : null;
  }

  toggleAvailability = () => {
    const { independentProfileStore, handleClose } = this.props;
    const available = this.independentProfile.availabilityToggleState
    !== AvailabilityToggle.OVERRIDE_TO_UNAVAILABLE;
    this.setState({ isLoading: true });
    const availabilityToggleState = available ? AvailabilityToggle.OVERRIDE_TO_UNAVAILABLE
      : AvailabilityToggle.OVERRIDE_TO_AVAILABLE;
    independentProfileStore.updateUserAvailability(availabilityToggleState)
      .then(() => {
        handleClose();
        this.independentProfile.availabilityToggleState = availabilityToggleState;
        this.independentProfile.availabilityToggle = !available;
      }).finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const available = this.independentProfile.availabilityToggleState
      !== AvailabilityToggle.OVERRIDE_TO_UNAVAILABLE;
    const { isLoading } = this.state;
    const { isOpen, handleClose } = this.props;

    return (
      <Modal
        isOpen={isOpen}
      >
        <div className="availability-modal__title">
          {available ? 'Are you sure you want to turn off Connect for today?' : 'Turn on Connect'}
        </div>
        <div className="availability-modal__description">
          {available ? 'You will not receive any jobs for the remainder of the day. Connect will turn back on at the beginning of tomorrow’s schedule. '
            : 'Resume today’s schedule and start receiving jobs again. '}
        </div>
        {
          available && (
          <button
            type="button"
            className="availability-modal__primary-button-disable"
            onClick={this.toggleAvailability}
          >
            {
            isLoading ? (
              <img className="availability-modal__primary-button-disable__loading" src={LoadingIcon} alt="Loading" />
            ) : (
              'TURN OFF CONNECT'
            )
          }
          </button>
          )
        }
        {
          !available && (
            <button
              type="button"
              className="availability-modal__primary-button-enable"
              onClick={this.toggleAvailability}
            >
              {
                isLoading ? (
                  <img className="availability-modal__primary-button-enable__loading" src={LoadingIcon} alt="Loading" />
                ) : (
                  'GET JOBS AGAIN'
                )
              }
            </button>
          )
        }
        <button
          type="button"
          className="availability-modal__secondary-button"
          onClick={handleClose}
        >
          CANCEL
        </button>
      </Modal>
    );
  }
}

AvailabilityModal.propTypes = {
  independentProfileStore: PropTypes.shape({
    independentProfile: MobxPropTypes.observableObject,
    updateUserAvailability: PropTypes.func,
  }),
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default compose(
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(AvailabilityModal);
