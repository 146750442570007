import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';

import './PdfViewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ url }) => {
  const [pageCount, setPageCount] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };

  return (
    <div>
      {
        url && (
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Please wait, the pdf is still loading"
            renderMode="svg"
          >
            {Array.from({ length: pageCount }, (_, i) => <Page key={i} pageNumber={i + 1} />)}
          </Document>
        )
      }
    </div>
  );
};
PdfViewer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PdfViewer;
