import React from 'react';
import PropTypes from 'prop-types';

import { maskPhoneNumber } from '../../lib/phone';
import DescriptionOfWork from '../DescriptionOfWork';

import './EstimateSummary.scss';

const EstimateSummary = ({
  customerName,
  customerPhone,
  customerEmail,
  customerAddress,
  descriptionOfWork,
  maskPhone,
}) => (
  <div className="estimate-summary">
    <div className="estimate-summary__fields">
      {
        customerName && <p>{customerName}</p>
      }
      {
        customerPhone && (
          <p>
            {maskPhone ? maskPhoneNumber(customerPhone) : customerPhone}
          </p>
        )
      }
      {
        customerEmail && <p>{customerEmail}</p>
      }
      {
        customerAddress && <p>{customerAddress}</p>
      }
    </div>
    {
      descriptionOfWork && (
        <div className="estimate-summary__notes">
          <DescriptionOfWork text={descriptionOfWork} />
        </div>
      )
    }
  </div>
);
EstimateSummary.propTypes = {
  customerName: PropTypes.string,
  customerPhone: PropTypes.string,
  customerEmail: PropTypes.string,
  customerAddress: PropTypes.string,
  descriptionOfWork: PropTypes.string,
  maskPhone: PropTypes.bool,
};

export default EstimateSummary;
