// All JS phone parser/formatter libraries like libphonenumber-js,
// google-libphonenumber are about 400KB in size and support many
// countries phone number formats.
// Right now we are supporting only US so use simple RegEx here.

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (phoneNumberString || '').replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  return match ? `${match[2]}-${match[3]}-${match[4]}` : null;
};

export const maskPhoneNumber = (phoneNumber) => phoneNumber.replace(/\d(?!\d{0,3}$)/g, '*');
