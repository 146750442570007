import { useCallback, useEffect, useRef } from 'react';

function useResizeObserver(callback = () => {}) {
  const resizeObserverRef = useRef(null);
  const targetElement = useRef(null);

  const handleResizeObserver = (entries) => {
    entries.forEach((entry) => {
      if (entry.contentRect) {
        callback(({ contentRect: entry.contentRect }));
      }
    });
  };

  const handleWindowResize = () => {
    const contentRect = targetElement.current.getBoundingClientRect();

    callback({ contentRect });
  };

  const cleanup = useCallback(() => {
    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    } else {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const initialize = useCallback((elementRef) => {
    if (elementRef && elementRef !== targetElement.current) {
      if (targetElement.current) {
        cleanup();
        targetElement.current = null;
      }

      targetElement.current = elementRef;

      if (window.ResizeObserver) {
        resizeObserverRef.current = new ResizeObserver(handleResizeObserver);
        resizeObserverRef.current.observe(elementRef);
      } else {
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
      }
    }
  }, [cleanup]);

  useEffect(() => cleanup, []);

  return { refHandler: initialize };
}

export default useResizeObserver;
