import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ErrorTemplate = ({
  userId,
  icon,
  title,
  subtitle,
  btnText,
  isOnboarding = false,
}) => (
  <div className="connect-tech-settings">
    <div className="connect-tech-settings__error-page">
      <img className="connect-tech-settings__error-page__icon" src={icon} alt="error" />
      <div className="connect-tech-settings__error-page__title">{title}</div>
      <div className="connect-tech-settings__error-page__subtitle">{subtitle}</div>
      <Link to={`/connectTechSettings/${userId}${isOnboarding ? '/onboarding' : ''}`} className="text-decoration-none">
        <button type="button" className="connect-tech-settings__error-page__button">
          {btnText}
        </button>
      </Link>
    </div>
  </div>
);

ErrorTemplate.propTypes = {
  userId: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btnText: PropTypes.string,
  isOnboarding: PropTypes.bool,
};

export default ErrorTemplate;
