import { action, makeObservable } from 'mobx';
import ReceiptSettingsModel from '../models/ReceiptSettingsModel';

class ReceiptSettingsStore {
  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      loadReceiptSettings: action,
    });
  }

  loadReceiptSettings(userId) {
    return this.apiService.getReceiptSettings(userId)
      .then(action((receiptSettings) => {
        this.receiptSettings = new ReceiptSettingsModel(receiptSettings);
      }));
  }
}

export default ReceiptSettingsStore;
