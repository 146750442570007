/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import _ from 'lodash';

import { withProps, compose } from '../../lib/hocUtils';
import { SelectDrawer } from '../../components/ConnectTechSettings/SelectDrawer/SelectDrawer';
import ToggleItem from '../../components/ConnectTechSettings/ToggleItem';
import LoadingIcon from '../../images/ConnectTechSettings/transparent-spinner.gif';
import BackArrowIcon from '../../images/ConnectTechSettings/icon-left-arrow.svg';
import ToggleOnIcon from '../../images/ConnectTechSettings/icon-toggle-on.svg';
import ToggleOffIcon from '../../images/ConnectTechSettings/icon-toggle-off.svg';

import './Skillset.scss';

const getTitle = (title) => {
  switch (title) {
    case 'Home':
      return 'Home & Office';
    case 'Auto':
      return 'Automotive';
    default:
      return title;
  }
};

class Skillset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewDraw: false,
      recentlyToggledOnService: null,
      category: null,
    };
  }

  componentDidMount() {
    const { occupationId, subcategoryId, occupationsStore } = this.props;
    const occupation = occupationId
      && occupationsStore.getOccupation(parseInt(occupationId, 10));
    const subcategory = occupation.categories.find((c) => c.id === subcategoryId);
    this.setState({ category: subcategory.category });
  }

  onToggle(service) {
    const { subcategoryId, occupationId, basicProfileStore } = this.props;
    const { category } = this.state;

    let newSkillset = _.cloneDeep(basicProfileStore.skillset);
    if (!service.enabled) {
      if (category === 'Auto') {
        this.setState({ viewDraw: true, recentlyToggledOnService: service });
      }

      newSkillset.push({
        occupation_id: occupationId,
        job_sub_category_id: subcategoryId,
        job_service_id: service.id,
      });
    } else {
      this.setState({ viewDraw: false });

      newSkillset = newSkillset.filter((s) => s.job_service_id !== service.id);
    }
    basicProfileStore.skillset = newSkillset;
  }

  getPageItems() {
    const {
      occupationId,
      subcategoryId,
      occupationsStore,
      basicProfileStore,
    } = this.props;

    if (occupationsStore) {
      const occupation = occupationId
        && occupationsStore.getOccupation(parseInt(occupationId, 10));
      const subcategory = occupation.categories.find(
        (c) => c.id === subcategoryId,
      );
      const activeServices = subcategory.services.filter((s) => s.active);

      if (basicProfileStore.skillset.length > 0) {
        return activeServices.map((service) => {
          const matchSkill = basicProfileStore.skillset.find(
            (s) => service.id === s.job_service_id,
          );
          const res = {
            ...service,
            skillId: matchSkill ? matchSkill.id : null,
            enabled: !!matchSkill,
          };
          return res;
        });
      }
      return [];
    }
    return null;
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  exitDraw = () => {
    const { recentlyToggledOnService } = this.state;

    recentlyToggledOnService.enabled = true;
    this.onToggle(recentlyToggledOnService);

    this.setState({ viewDraw: false });
  };

  onDrawSelect = (matchId, service) => {
    const { basicProfileStore, history } = this.props;
    const skill = basicProfileStore.skillset.find(
      (s) => s.job_service_id === service.id,
    );
    const skillId = skill ? skill.id : null;
    if (!skillId) {
      this.setState({ viewDraw: false });
      return;
    }
    if (matchId === -2) {
      history.push({
        pathname: `../../${skillId}/carbrands`,
        state: { title: service.service },
      });
    } else if (matchId === -1) {
      basicProfileStore.selectAllBrands(skillId);
      this.setState({ viewDraw: false });
    } else {
      const matchSkillId = basicProfileStore.skillset.find(
        (s) => s.job_service_id === matchId,
      ).id;
      basicProfileStore.selectBrandsFromSkill(skillId, matchSkillId);
      this.setState({ viewDraw: false });
    }
  };

  renderToggles(services) {
    const { category } = this.state;
    const { basicProfileStore } = this.props;
    if (category === 'Auto') {
      return services.map((service) => {
        const skill = basicProfileStore.skillset.find(
          (s) => s.job_service_id === service.id,
        );
        const skillId = skill ? skill.id : null;
        const brandsForSkill = skillId
          ? basicProfileStore.autobrandsBySkill[skillId]
          : null;
        const selectedBrands = `${
          brandsForSkill
            ? brandsForSkill.filter((brand) => brand.enabled).length
            : 0
        } out of ${basicProfileStore.autobrands === null ? 0 : basicProfileStore.autobrands.length}`;
        return (
          <ToggleItem
            ref={service.service}
            key={service.service}
            title={service.service}
            link={{
              pathname: `../../${skillId}/carbrands`,
              state: { title: service.service },
            }}
            subcomponent={<div>{selectedBrands}</div>}
            enabled={service.enabled}
            onToggle={() => this.onToggle(service)}
          />
        );
      });
    }
    return services.map((service) => (
      <div
        className="connect-tech-settings__skillset__service"
        key={`service-${service.id}`}
      >
        {service.service}
        <button
          type="button"
          onClick={() => this.onToggle(service)}
          className="connect-tech-settings__skillset__service__toggle"
        >
          <img
            src={service.enabled ? ToggleOnIcon : ToggleOffIcon}
            alt={service.enabled ? 'on' : 'off'}
          />
        </button>
      </div>
    ));
  }

  render() {
    const {
      occupationId,
      subcategoryId,
      occupationsStore,
      basicProfileStore,
      connectTechSettingsStore,
      isOnboarding,
    } = this.props;
    const {
      category,
      viewDraw,
      recentlyToggledOnService,
    } = this.state;

    if (!basicProfileStore) {
      return (
        <img
          className="connect-tech-settings__loading"
          src={LoadingIcon}
          alt="Loading"
        />
      );
    }

    const title = occupationsStore.occupations ? getTitle(category) : 'Skillset';

    return (
      <div className="connect-tech-settings__skillset">
        {basicProfileStore.isError && (
          <div className="connect-tech-settings__error">
            Something went wrong, try again.
          </div>
        )}
        {
          !isOnboarding ? (
            <img
              className="connect-tech-settings__back-arrow"
              src={BackArrowIcon}
              onClick={this.goBack}
              alt="Back"
            />
          ) : (
            <div className="m-4" />
          )
        }
        <div className="connect-tech-settings__skillset__title">
          <div className="connect-tech-settings__title__header">{isOnboarding ? `Which ${title} jobs?` : title}</div>
        </div>
        {
          this.renderToggles(
            connectTechSettingsStore.getPageItems(occupationId, subcategoryId),
          )
        }
        {
          viewDraw && (
            <div
              className="connect-tech-settings__skillset__backdrop"
              onClick={this.exitDraw}
            />
          )
        }
        {basicProfileStore.isLoading && (
          <div className="connect-tech-settings__skillset__backdrop-high">
            <img
              className="connect-tech-settings__loading"
              src={LoadingIcon}
              alt="Loading"
            />
          </div>
        )}
        <SelectDrawer
          currentService={recentlyToggledOnService}
          enabled={viewDraw}
          services={connectTechSettingsStore.getPageItems(
            occupationId,
            subcategoryId,
          )}
          onSelection={this.onDrawSelect}
          isOnboarding={isOnboarding}
        />
      </div>
    );
  }
}

Skillset.propTypes = {
  occupationId: PropTypes.number,
  subcategoryId: PropTypes.number,
  basicProfileStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    autobrands: MobxPropTypes.observableArray,
    autobrandsBySkill: MobxPropTypes.observableObject,
    skillset: MobxPropTypes.observableArray,
    selectBrandsFromSkill: PropTypes.func,
    selectAllBrands: PropTypes.func,
  }),
  occupationsStore: PropTypes.shape({
    getOccupation: PropTypes.func,
    occupations: MobxPropTypes.observableArray,
  }),
  connectTechSettingsStore: PropTypes.shape({
    getPageItems: PropTypes.func,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  isOnboarding: PropTypes.bool,
};

export default compose(
  withProps((ownProps) => {
    const {
      match: {
        params: { userId, occupationId, subcategoryId },
      },
    } = ownProps;

    return {
      userId,
      occupationId: parseInt(occupationId, 10),
      subcategoryId: parseInt(subcategoryId, 10),
    };
  }),
  inject((stores) => ({
    basicProfileStore: stores.connectTechSettingsStore.basicProfileStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
    connectTechSettingsStore: stores.connectTechSettingsStore,
  })),
  observer,
)(Skillset);
