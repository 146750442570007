import {
  observable,
  makeObservable,
  computed,
  action,
  toJS,
  runInAction,
} from 'mobx';
import _ from 'lodash';

import BasicProfileModel from '../models/ConnectTechSettings/BasicProfileModel';

const apiToLocalBrands = (apiBrands, userBrands) => apiBrands
  .sort((a, b) => a.brand_name > b.brand_name)
  .map((brand) => {
    const userBrand = userBrands.find((b) => b.auto_brand.id === brand.id);
    return {
      id: brand.id,
      brand_name: brand.brand_name,
      enabled: !!userBrand,
      year_to: userBrand && userBrand.year_to,
      year_from: userBrand && userBrand.year_from,
    };
  });

class BasicProfileStore {
  autobrands = null;
  autobrandsBySkill = {};

  constructor(apiService, userId, basicProfile) {
    this.apiService = apiService;
    // To make the observable
    // track properly it's nested fields,
    // the observed object needs to have those
    // fields available at creation, it can't go
    // from null -> filledWithData. The tracking won't work
    // in that case
    this.basicProfile = {
      roles: [],
      skillSet: [],
    };
    this.userId = userId;
    this.loading = false;
    this.error = false;
    this.getAutoBrands();

    if (basicProfile) {
      Object.assign(this.basicProfile, new BasicProfileModel(basicProfile));
    }

    makeObservable(this, {
      loading: observable,
      error: observable,
      basicProfile: observable,
      autobrands: observable,
      autobrandsBySkill: observable,

      skillset: computed,

      onSkillsetChange: action,
      setAutobrandsForSkill: action,
      fetchAutobrands: action,
      getAutoBrands: action,
      clearBrands: action,
    });
  }

  // Exposed observables ------------------------
  get isLoading() {
    return this.loading;
  }

  set isLoading(loading) {
    runInAction(() => {
      this.loading = loading;
    });
  }

  get isError() {
    return this.error;
  }

  set isError(error) {
    runInAction(() => {
      this.error = error;
    });
  }

  get skillset() {
    return this.basicProfile.skillSet;
  }

  set skillset(newSkillset) {
    const prevSkillset = this.skillset;

    runInAction(() => {
      this.basicProfile.skillSet = newSkillset;
    });

    this.onSkillsetChange(newSkillset, prevSkillset);
  }

  getAutoBrands() {
    this.apiService.getAutoBrands()
      .then(action((brands) => {
        this.autobrands = brands;
      }));
  }

  async onSkillsetChange(newSkillset, prevSkillset) {
    if (!_.isEqual(newSkillset, prevSkillset)) {
      const prevAutoBrandsBySkill = _.cloneDeep(this.autobrandsBySkill);
      try {
        this.isLoading = true;
        this.isError = false;
        // Convert form a Mobx proxied object to a plain JS object
        const plainBasicProfile = toJS(this.basicProfile);
        const basicProfilePayload = {
          ...plainBasicProfile,
          skill_set: newSkillset,
          base_zip_code: plainBasicProfile.baseZip,
        };
        await this.apiService.updateBasicProfile(
          this.userId,
          basicProfilePayload,
        );
        const profile = await this.apiService.getBasicProfile(this.userId);
        runInAction(() => {
          this.basicProfile.skillSet = profile.basic_profile.skill_set;
        });
      } catch (error) {
        this.isError = true;
        // Rollback to prev state
        runInAction(() => {
          this.basicProfile.skillSet = prevSkillset;
          this.autobrandsBySkill = prevAutoBrandsBySkill;
        });
      } finally {
        this.isLoading = false;
      }
    }
  }

  setAutobrandsForSkill(skillId, newBrands) {
    const brands = this.autobrands
      .filter((brand) => !newBrands.some((b) => b.id === brand.id))
      .map((brand) => ({ ...brand, enabled: false }));
    brands.push(...newBrands);
    this.autobrandsBySkill[skillId] = brands;
  }

  fetchAutobrands(skillId) {
    this.isLoading = true;
    this.isError = false;

    return Promise.all([
      this.apiService.getUserSkillAutobrands(this.userId, skillId),
      this.apiService.getAutoBrands(),
    ]).then(action(([userBrands, brands]) => {
      const localBrands = apiToLocalBrands(brands, userBrands);

      this.setAutobrandsForSkill(skillId, localBrands);

      return localBrands;
    })).catch(() => {
      this.isError = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  createUserSkillAutobrand(skillId, data, update = true) {
    if (!this.autobrandsBySkill[skillId]) {
      this.setAutobrandsForSkill(skillId, this.autobrands);
    }

    if (update) {
      this.setAutobrandsForSkill(
        skillId,
        this.autobrandsBySkill[skillId].map((b) => {
          if (b.id === data.brand_id) {
            return {
              ...b,
              year_from: data.year_from,
              year_to: data.year_to,
              enabled: true,
            };
          }
          return b;
        }),
      );
    }

    this.isLoading = true;
    this.isError = false;

    this.apiService.createUserSkillAutobrand(this.userId, skillId, data)
      .catch(() => {
        this.isError = true;
      }).finally(() => {
        this.isLoading = false;
      });
  }

  updateUserSkillAutobrand(skillId, brandId, data) {
    this.isLoading = true;
    this.isError = false;

    this.apiService.updateUserSkillAutobrand(
      this.userId,
      skillId,
      brandId,
      data,
    ).catch(() => {
      this.isError = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  removeUserSkillAutobrand(skillId, brandId) {
    this.setAutobrandsForSkill(
      skillId,
      this.autobrandsBySkill[skillId].map((b) => {
        if (b.id === brandId) {
          return {
            ...b,
            enabled: false,
          };
        }
        return b;
      }),
    );

    this.isError = false;

    this.apiService.removeUserSkillAutobrand(this.userId, skillId, brandId)
      .catch(() => {
        this.isError = true;
      });
  }

  clearBrands(skillId) {
    this.setAutobrandsForSkill(
      skillId,
      this.autobrandsBySkill[skillId].map((brand) => ({
        ...brand,
        enabled: false,
      })),
    );

    this.isLoading = true;
    this.isError = false;

    this.apiService.removeAllUserSkillAutobrand(this.userId, skillId)
      .catch(() => {
        this.isError = true;
      }).finally(() => {
        this.isLoading = false;
      });
  }

  selectAllBrands(skillId) {
    if (!this.autobrandsBySkill[skillId]) {
      this.setAutobrandsForSkill(skillId, this.autobrands);
    }

    const payload = this.autobrandsBySkill[skillId]
      .filter((brand) => !brand.enabled)
      .map((brand) => ({
        brand_id: brand.id,
        year_to: null,
        year_from: null,
      }));

    if (payload.length > 0) {
      this.isLoading = true;
      this.isError = false;

      this.apiService.bulkCreateSkillAutobrands(
        this.userId,
        skillId,
        payload,
      ).then(() => {
        this.setAutobrandsForSkill(
          skillId,
          this.autobrands.map((x) => ({ ...x, enabled: true })),
        );
      }).catch(() => {
        this.isError = true;
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }

  selectBrandsFromSkill(skillId, matchSkillId) {
    this.setAutobrandsForSkill(skillId, this.autobrandsBySkill[matchSkillId]);

    this.isLoading = true;
    this.isError = false;

    this.apiService.bulkCreateSkillAutobrands(
      this.userId,
      skillId,
      this.autobrandsBySkill[matchSkillId]
        .filter((brand) => brand.enabled)
        .map((brand) => ({
          brand_id: brand.id,
          year_to: brand.year_to,
          year_from: brand.year_from,
        })),
    ).catch(() => {
      this.isError = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }
}

export default BasicProfileStore;
