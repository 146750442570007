import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';

import { compose } from '../../lib/hocUtils';
import { withAnalytics } from '../../components';

import ErrorBoundary from './ErrorBoundary';
import Settings from './ConnectTechSettings';
import Location from './Location';
import Notification from './Notification';
import Skillset from './Skillset';
import Availability from './Availability';
import DayAvailability from './DayAvailability';
import ErrorPage from './ErrorPage';
import CarBrands from './CarBrands';
import CarBrandYears from './CarBrandYears';
import JobTypes from './JobTypes';
import DailyAvailabilityHours from './DailyAvailabilityHours';
import ConnectStatus from './ ConnectStatusSettings/ConnectStatus';
import ConnectDeactivate from './ ConnectStatusSettings/ConnectDeactivate';

const ConnectTechRoutes = ({
  match,
  connectTechSettingsStore,
  location,
  categories,
  setCategories,
  isOnboarding,
}) => {
  useEffect(() => {
    if (!connectTechSettingsStore.independentProfileStore) {
      connectTechSettingsStore.fetchConnectTechSettings(match.params.userId);
    }

    // reset error states when navigating across pages
    connectTechSettingsStore.resetErrors();
  }, [match, connectTechSettingsStore]);

  const createComponent = (Comp, props) => <Comp {...props} isOnboarding={isOnboarding} />;

  return (
    <ErrorBoundary userId={match.params.userId} key={location.pathname} isOnboarding={isOnboarding}>
      <Switch>
        <Route
          exact
          pageName="ConnectTechSettingsAvailabilityDayPage"
          path={`${match.path}/availability/:day`}
          component={(props) => createComponent(DayAvailability, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsDailyAvailabilityPage"
          path={`${match.path}/dailyavailability`}
          component={(props) => createComponent(DailyAvailabilityHours, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsAvailabilityPage"
          path={`${match.path}/availability`}
          component={(props) => createComponent(Availability, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsSkillsetPage"
          path={`${match.path}/skillset/:skillId/carbrands`}
          component={(props) => createComponent(CarBrands, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsSkillsetPage"
          path={`${match.path}/skillset/:occupationId/subcategory/:subcategoryId`}
          component={(props) => createComponent(Skillset, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsLocationPage"
          path={`${match.path}/location`}
          component={(props) => createComponent(Location, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsNotificationPage"
          path={`${match.path}/notifications`}
          component={(props) => createComponent(Notification, props)}
        />
        <Route
          exact
          pageName="ConnectStatusPage"
          path={`${match.path}/status`}
          component={(props) => createComponent(ConnectStatus, props)}
        />
        <Route
          exact
          pageName="ConnectDeactivatePage"
          path={`${match.path}/status/deactivate`}
          component={(props) => createComponent(ConnectDeactivate, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsCarbrandsYearPage"
          path={`${match.path}/skillset/:skillId/carbrands/:brandId`}
          component={(props) => createComponent(CarBrandYears, props)}
        />
        <Route
          exact
          pageName="ConnectTechSettingsErrorPage"
          path={`${match.path}/error`}
          component={(props) => createComponent(ErrorPage, props)}
        />
        {
          isOnboarding && (
            <Route
              exact
              pageName="ConnectTechSettingsJobsPage"
              path={match.path}
              component={(props) => (
                <JobTypes
                  {...props}
                  setCategories={setCategories}
                  categories={categories}
                  isOnboarding
                />
              )}
            />
          )
        }
        <Route
          exact
          pageName="ConnectTechSettingsPage"
          path={!isOnboarding ? match.path : `${match.path}/finalCheck`}
          component={(props) => createComponent(Settings, props)}
        />
      </Switch>
    </ErrorBoundary>
  );
};

ConnectTechRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
  basicProfileStore: PropTypes.shape({
    fetchAutobrands: PropTypes.func,
    createUserSkillAutobrand: PropTypes.func,
    removeUserSkillAutobrand: PropTypes.func,
    clearBrands: PropTypes.func,
    selectAllBrands: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    autobrandsBySkill: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    skillset: PropTypes.object,
  }),
  connectTechSettingsStore: PropTypes.shape({
    independentProfileStore: MobxPropTypes.observableObject,
    fetchConnectTechSettings: PropTypes.func,
    resetErrors: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryName: PropTypes.string,
      enabled: PropTypes.bool,
      icon: PropTypes.string,
    }),
  ),
  setCategories: PropTypes.func,
  isOnboarding: PropTypes.bool,
};

export default compose(
  withRouter,
  inject('connectTechSettingsStore'),
  observer,
  withAnalytics((props) => {
    const userId = props?.match?.params?.userId;

    return { userId };
  }),
)(ConnectTechRoutes);
