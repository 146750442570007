import React from 'react';
import PropTypes from 'prop-types';

import './EstimateReference.scss';

const EstimateReference = ({
  jobRefId,
  brandColor,
}) => (
  <div className="estimate-reference">
    <div
      className="estimate-reference__job-id"
      style={brandColor && { background: brandColor }}
    >
      Job Reference
      {' '}
      <span className="estimate-reference__job-id-reference">
        #
        {jobRefId}
      </span>
    </div>
  </div>
);
EstimateReference.propTypes = {
  jobRefId: PropTypes.string,
  brandColor: PropTypes.string,
};

export default EstimateReference;
