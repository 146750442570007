import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { find, filter } from 'lodash/fp';

import { withRouter } from 'react-router';
import {
  Box,
  ViewLayout,
  ViewLayoutContent,
  ToggleSwitch,
  List,
  ListGroup,
  ListItem,
  ListLabel,
} from '@jobox/react-components-library/mobile';

import { Head, linkAdapter } from './components';
import { withProps, compose } from '../../lib/hocUtils';

import classes from './observedCalendarSelection.module.scss';

const NATIONAL_HOLIDAYS_CALENDAR_ID = 1;

class ObservedCalendarSelection extends Component {
  componentDidMount() {
    const { holidaysStore, userId } = this.props;

    holidaysStore.fetchUserHolidays(userId);
  }

  renderCalendarItem = (calendar = {}) => {
    const { holidaysStore, location: { pathname: currentPath } } = this.props;
    const calendarHolidays = calendar.holidays || [];
    const toggledHolidaysCount = calendarHolidays.reduce(
      (acc, { toggled }) => acc + (toggled ? 1 : 0),
      0,
    );
    const calendarEnabled = toggledHolidaysCount > 0;

    return (
      <ListGroup
        id={calendar.id}
        key={calendar.id}
        className={classes['list-group']}
        title={(
          <Box className={classes['list-group-title']}>
            { calendar.name }
            &nbsp;
            <ToggleSwitch
              enabled={calendarEnabled}
              onChange={(currentEnabled) => {
                holidaysStore.toggleCalendar(calendar.id, currentEnabled);
              }}
            />
          </Box>
        )}
        collapsible={false}
      >
        <ListItem
          id={`${calendar.id}`}
          component={linkAdapter({ to: `${currentPath}/${calendar.id}` })}
          renderIndicator={() => (
            <Box className={classes['arrow-indicator']} />
          )}
        >
          { calendarHolidays.length }
          &nbsp;
          days
        </ListItem>
      </ListGroup>
    );
  };

  render() {
    const { holidaysStore, userId } = this.props;
    const nationalHoliday = find({ id: NATIONAL_HOLIDAYS_CALENDAR_ID })(
      holidaysStore.observableHolidays,
    );
    const religiousHolidays = filter(({ id }) => id !== NATIONAL_HOLIDAYS_CALENDAR_ID)(
      holidaysStore.observableHolidays,
    );

    return (
      <ViewLayout>
        <Head
          title="Observed Holidays"
          backTo={`/connectTechSettings/${userId}/holidays`}
        />
        <ViewLayoutContent>
          <List>
            <ListLabel>
              National
            </ListLabel>
            { this.renderCalendarItem(nationalHoliday) }

            <ListLabel>
              Religious
            </ListLabel>
            {
              religiousHolidays.map(this.renderCalendarItem)
            }
          </List>
        </ViewLayoutContent>
      </ViewLayout>
    );
  }
}

ObservedCalendarSelection.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  holidaysStore: PropTypes.shape({
    fetchUserHolidays: PropTypes.func,
    toggleCalendar: PropTypes.func,
    observableHolidays: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        holidays: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            startDate: PropTypes.number,
            endDate: PropTypes.number,
            toggled: PropTypes.bool,
          }),
        ),
      }),
    ),
  }),
  userId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default compose(
  withProps(({ match: { params: { userId } } }) => ({
    userId,
  })),
  withRouter,
  inject(({ holidaysStore }) => ({
    holidaysStore,
  })),
  observer,
)(ObservedCalendarSelection);
