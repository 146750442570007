import { formatPhoneNumber } from '../lib/phone';
import PartnerBrandModel from './PartnerBrandModel';

class EstimatePreviewsModel {
  constructor(estimatePreviews) {
    this.type = estimatePreviews.type;
    this.user_company_name = estimatePreviews.user_company_name;
    this.user_phone = formatPhoneNumber(estimatePreviews.user_phone);
    this.notesForCustomer = estimatePreviews.notes_for_customer;
    this.warranty = estimatePreviews.notes_for_customer;
    this.job_ref_id = estimatePreviews.job_ref_id.slice(3);
    this.customer_name = estimatePreviews.customer_name;
    this.customer_phone = formatPhoneNumber(estimatePreviews.customer_phone);
    this.customer_email = estimatePreviews.customer_email;
    this.customer_address = estimatePreviews.customer_address;
    this.description_of_work = estimatePreviews.description_of_work;
    this.subtotal = Number.parseFloat(estimatePreviews.subtotal) || 0;
    this.labor = Number.parseFloat(estimatePreviews.labor) || 0;
    this.parts_price = Number.parseFloat(estimatePreviews.parts_price) || 0;
    this.tax = Number.parseFloat(estimatePreviews.tax) || 0;
    this.occupation = estimatePreviews.occupation;
    this.partnerBrand = estimatePreviews.partner_brand
      && new PartnerBrandModel(estimatePreviews.partner_brand);
    this.marketplace_job = estimatePreviews.marketplace_job || false;
  }
}

export default EstimatePreviewsModel;
