import { makeObservable, observable, action } from 'mobx';
import TransactionModel from '../models/TransactionModel';

class TransactionStore {
  transaction = null;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      transaction: observable,
    });
  }

  loadTransactionByLast4(jobRefId, last4) {
    if (
      this.transaction
      && this.transaction.jobRefId === jobRefId
      && this.transaction.payment.last4 === last4
    ) {
      return Promise.resolve(this.transaction);
    }

    return this.apiService.getTransactionByJobAndLast4(jobRefId, last4)
      .then(action((data) => {
        this.transaction = new TransactionModel(data);
      }));
  }

  loadTransaction(jobRefId) {
    return this.apiService.getTransactionByJobRefId(jobRefId)
      .then(action((data) => {
        this.transaction = new TransactionModel(data);
      }));
  }
}

export default TransactionStore;
