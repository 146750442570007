/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';
import qs from 'qs';

import { withProps, compose } from '../../lib/hocUtils';
import config from '../../config';

import feedbackStar from '../../images/icon-star.svg';
import feedbackStarSelected from '../../images/icon-star-selected.svg';
import selectEmpty from '../../images/icon-select-empty.svg';
import selectCheck from '../../images/icon-select-check.svg';
import submittedCheck from '../../images/submitted-check.svg';

import './ReceiptFeedbackPage.scss';

const FEEDBACK_OPTIONS = {
  CUSTOMER_SERVICE: 'Customer service',
  PRICE: 'Price',
  WAIT_TIME: 'Wait time',
  OTHER: 'Other',
};

class ReceiptFeedbackPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedbackComplete: false,
      feedbackOptions: _.mapValues(FEEDBACK_OPTIONS, () => false),
      feedbackNotes: '',
      feedbackRating: null,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount = () => {
    const {
      rating,
      receiptKey,
      receiptStore,
      submitted,
    } = this.props;

    this.setState({ feedbackRating: rating });

    if (!receiptStore.receipt) {
      receiptStore.loadReceipt(receiptKey, true);
    }

    const jobId = receiptKey && Number(receiptKey.split('-')[0]);
    if (jobId && rating && !submitted) {
      receiptStore.submitFeedback(jobId, rating, [], '');
    }
  };

  setFeedbackOption = (label) => {
    const { feedbackOptions } = this.state;
    feedbackOptions[label] = !feedbackOptions[label];
    this.setState({
      feedbackOptions,
    });
  };

  sendRatingFeedback = async () => {
    const { receiptStore, receiptKey } = this.props;
    const { feedbackOptions, feedbackNotes, feedbackRating } = this.state;

    const captchaToken = await this.recaptchaRef.current.executeAsync();

    const jobId = receiptKey && Number(receiptKey.split('-')[0]);
    if (jobId && feedbackRating) {
      await receiptStore.updateFeedback(
        jobId,
        feedbackRating,
        Object.keys(feedbackOptions)
          .filter((o) => feedbackOptions[o]),
        feedbackNotes,
        captchaToken,
      );
      this.setState({ feedbackComplete: true });
    }
  };

  renderSubmitButton = () => (
    <button
      type="button"
      className={classnames('receipt-feedback-page__submit')}
      onClick={this.sendRatingFeedback}
    >
      SUBMIT
    </button>
  );

  renderFeedbackNotes = () => (
    <textarea
      className={classnames('receipt-feedback-page__feedback-notes')}
      placeholder="Tell us more about your experience..."
      onChange={(e) => this.setState({ feedbackNotes: e.target.value })}
      maxLength="255"
    />
  );

  renderFeedbackOption = (label) => {
    const { feedbackOptions } = this.state;

    return (
      <div
        className={classnames('receipt-feedback-page__feedback-option')}
        key={label}
        onClick={() => this.setFeedbackOption(label)}
      >
        {FEEDBACK_OPTIONS[label]}
        <img src={feedbackOptions[label] ? selectCheck : selectEmpty} alt="feedback_select" />
      </div>
    );
  };

  render() {
    const { receiptKey, rating, receiptStore: { receipt } } = this.props;
    const { feedbackComplete, feedbackOptions, feedbackRating } = this.state;

    if (!receipt) {
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="spinner-border" />
        </div>
      );
    }

    return (
      <div className={classnames('receipt-feedback-page-container')}>
        {feedbackComplete ? (
          <div className={classnames('receipt-feedback-page', 'feedback-full-page')}>
            <img
              className={classnames('receipt-feedback-page__image')}
              src={submittedCheck}
              alt="sent"
            />

            <h1 className={classnames('receipt-feedback-page__title')}>Feedback submitted</h1>

            <div>
              <Link to={`/receipts/${receiptKey}`}>Back to receipt</Link>
            </div>
            <div className={classnames('receipt-feedback-page__subtext')}>
              Need some help?
              <br />
              Call the number at the top of your receipt.
            </div>
          </div>
        ) : (
          <div className={classnames('receipt-feedback-page')}>
            <h2 className="receipt-feedback-page__title">
              Thank you for your feedback!
            </h2>
            <div className={classnames('feedback__stars_container')}>
              <div
                className={classnames(
                  'feedback__stars',
                  'receipt-feedback-page__stars',
                )}
              >
                {_.times(5, (i) => (
                  <div key={i}>
                    <img
                      key={i}
                      onClick={() => this.setState({ feedbackRating: i + 1 })}
                      className={classnames('feedback_star')}
                      src={
                        i < feedbackRating ? feedbackStarSelected : feedbackStar
                      }
                      alt="star"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={classnames('receipt-feedback-page__star-subtext')}>
              {rating > 3
                ? 'Please write us a short review about your service experience. Thank you in advance!'
                : 'How could your experience have been better?'}
            </div>
            <div
              className={classnames(
                'receipt-feedback-page__feedback-option-container',
              )}
            >
              {rating <= 3
                && Object.keys(feedbackOptions).map(
                  (option) => this.renderFeedbackOption(option),
                )}
            </div>
            {
                (Object.values(feedbackOptions).includes(true) || rating > 3) && (
                <>
                  {this.renderFeedbackNotes()}
                  {this.renderSubmitButton()}
                </>
                )
            }
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey={config.RECAPTCHA_SITE_KEY}
              size="invisible"
            />
          </div>
        )}
      </div>
    );
  }
}
ReceiptFeedbackPage.propTypes = {
  receiptStore: PropTypes.shape({
    submitFeedback: PropTypes.func.isRequired,
    updateFeedback: PropTypes.func.isRequired,
    loadReceipt: PropTypes.func.isRequired,
    receipt: PropTypes.shape({
      jobIsMarketplace: PropTypes.bool,
    }),
  }).isRequired,
  receiptKey: PropTypes.string,
  rating: PropTypes.number,
  submitted: PropTypes.bool,
};

export default compose(
  withProps((ownProps) => {
    const {
      match: {
        params: { receiptKey },
      },
      location: { search },
    } = ownProps;
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    return {
      receiptKey,
      receiptId: parseInt(queryParams.receipt_id, 10),
      feedback: (queryParams.feedback || '').toUpperCase(),
      rating: Number(queryParams.rating) || null,
      submitted: queryParams.submitted === 'true',
    };
  }),
  inject('receiptStore'),
  observer,
)(ReceiptFeedbackPage);
