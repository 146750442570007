import {
  observable,
  computed,
  action,
  makeObservable,
} from 'mobx';
import OccupationModel from '../models/OccupationModel';

class OccupationsStore {
  loadingOccupations = false;
  errorOccupations = false;
  occupations = [];

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      loadingOccupations: observable,
      errorOccupations: observable,
      occupations: observable,

      allOccupations: computed,

      getOccupation: action,
      loadOccupations: action,
    });
  }

  get allOccupations() {
    const occupations = Array.isArray(this.occupations)
      && this.occupations.map((occupation) => new OccupationModel(occupation));

    // Sorting in backend order but showing occupations with icon first
    return occupations
      .filter((occupation) => occupation.icon)
      .concat(
        occupations.filter((occupation) => !occupation.icon),
      );
  }

  getOccupation(occupationId) {
    const occupation = new OccupationModel(
      this.occupations.find((occ) => occ.id === occupationId),
    );
    return occupation || null;
  }

  loadOccupations() {
    this.loadingOccupations = true;
    return this.apiService.getOccupations()
      .then(action((data) => {
        this.occupations = data || [];
        this.loadingOccupations = false;
      }))
      .catch(action((error) => {
        this.errorOccupations = error;
      }));
  }
}

export default OccupationsStore;
