/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import feedbackStar from '../../../images/icon-star.svg';
import feedbackStarSelected from '../../../images/icon-star-selected.svg';

import './Feedback.scss';

const Feedback = ({
  rating,
  onSubmitRating,
  className,
  onClickRating,
  currentRating,
}) => {
  const handleSubmitRating = (i) => {
    if (onSubmitRating && !rating) {
      onSubmitRating(i + 1);
    }
  };

  useEffect(() => {
    onClickRating();
  }, [rating, onClickRating]);

  const actualRating = currentRating || rating;

  return (
    <div className={classnames('feedback', className)}>
      <div>Thank you for your business.</div>
      {!currentRating
          && <div className="feedback__service">How was your service?</div>}
      <div className={classnames('feedback__stars_container')}>
        <div className="feedback__stars">
          {_.times(5, (i) => (
            <div key={i}>
              <img
                key={i}
                className={classnames('feedback_star', { 'feedback_star--disabled': currentRating })}
                src={i < actualRating ? feedbackStarSelected : feedbackStar}
                onClick={() => handleSubmitRating(i)}
                alt="star"
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classnames('feedback__select')}>{currentRating ? 'Your rating has been shared' : 'Select your rating'}</div>
    </div>
  );
};

Feedback.propTypes = {
  rating: PropTypes.number,
  onSubmitRating: PropTypes.func,
  className: PropTypes.string,
  onClickRating: PropTypes.func,
  currentRating: PropTypes.number,
};

export default Feedback;
