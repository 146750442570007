import React from 'react';
import PropTypes from 'prop-types';

import NetworkErrorIcon from '../../images/ConnectTechSettings/icon-error-network.svg';
import ErrorTemplate from './ErrorTemplate';

const ErrorPage = ({ match: { params: { userId } } }) => (
  <ErrorTemplate
    userId={userId}
    icon={NetworkErrorIcon}
    title="Unable to load settings"
    subtitle="Please check your connection."
    btnText="Try Again"
  />
);

ErrorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
};

export default ErrorPage;
