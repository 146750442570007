/* eslint-disable no-console */
export const logger = {
  log(...messages) {
    console.log(...messages);
  },
  info(...messages) {
    console.info(...messages);
  },
  warn(...messages) {
    console.warn(...messages);
  },
  error(...messages) {
    console.error(...messages);
  },
};
