import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ModalDialog,
  ModalDialogContent,
} from '@jobox/react-components-library/mobile';

import classes from './confirmationModal.module.scss';

export const ConfirmationModal = ({
  isOpen,
  children,
  actions,
}) => (
  <ModalDialog
    open={isOpen}
    classNameBackdrop={classes.backdrop}
    className={classes.modal}
  >
    <ModalDialogContent className={classes['modal-content']}>
      <Box className={classes['modal-content__info']}>
        { children }
      </Box>
      <Box className={classes['modal-content__actions']}>
        { actions }
      </Box>
    </ModalDialogContent>
  </ModalDialog>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  actions: PropTypes.node,
  children: PropTypes.node,
};
