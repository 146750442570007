import { capitalize } from 'lodash';

import iconVisa from '../images/icon-visa512.png';
import iconDinersClub from '../images/icon-diners512.png';
import iconDiscover from '../images/icon-discover512.png';
import iconJCB from '../images/icon-jcb512.png';
import iconMastercard from '../images/icon-mastercard512.png';
import iconUnionPay from '../images/icon-unionpay512.png';
import iconAmex from '../images/icon-amex512.png';
import iconCreditCard from '../images/icon-credit-card.svg';

const BRAND_LABELS = new Map([
  ['jcb', 'JCB'],
  ['unionpay', 'UnionPay'],
  ['diners', 'Diners Club'],
  ['amex', 'American Express'],
]);

export const formatCCBrand = (brandInput) => {
  if (!brandInput) {
    return brandInput;
  }
  const brand = brandInput.toLowerCase();
  const brandLabel = BRAND_LABELS.get(brand);

  if (brandLabel) {
    return brandLabel;
  }

  return capitalize(brand);
};

const CREDIT_CARD_ICONS = new Map([
  ['visa', iconVisa],
  ['mastercard', iconMastercard],
  ['americanexpress', iconAmex],
  ['amex', iconAmex],
  ['discover', iconDiscover],
  ['diners', iconDinersClub],
  ['dinersclub', iconDinersClub],
  ['jcb', iconJCB],
  ['unionpay', iconUnionPay],
]);

export const getCCIcon = (brandInput) => {
  if (brandInput) {
    const brand = (brandInput || '').trim().toLowerCase();
    if (CREDIT_CARD_ICONS.has(brand)) {
      return CREDIT_CARD_ICONS.get(brand);
    }
  }

  return iconCreditCard;
};
