/* eslint-disable camelcase */
import React, { Component } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';

import {
  EstimateBreakdown,
  EstimateTechHeader,
  EstimateReference,
  EstimateSummary,
  EstimateLayoutWrap,
  EstimateLayout,
  EstimateLayoutBody,
  Text,
  WarrantyInfo,
  SignaturePad,
} from '../../components';
import Input from '../../components/Input';
import { withProps, compose } from '../../lib/hocUtils';
import EstimateType from '../../constants/EstimateType';
import IconInputPerson from '../../images/icon_input-person.svg';
import LoadingIcon from '../../images/loading.gif';
import { formatPhoneNumber } from '../../lib/phone';

import '../../styles/pages/estimates-common.scss';
import './RemoteApprovalPage.scss';

const adjustPdfUrl = (signedPdfUrlPath) => {
  const { pathname } = new URL(signedPdfUrlPath);
  const { REACT_APP_VIEWINVOICE_URL: viewInvoiceUrl } = process.env;

  return `${viewInvoiceUrl}${pathname}`;
};

class RemoteApprovalPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: null,
      isDisabled: false,
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    const {
      userId,
      jobId,
      remoteApprovalStore,
      jobStore,
    } = this.props;

    this.setState({ loading: true });
    Promise.all([
      remoteApprovalStore.loadEstimate(userId, jobId),
      jobStore.loadJob(jobId),
      jobStore.loadJobStatus(jobId),
    ]).catch((loadingError) => {
      this.setState({ loadingError });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleSendSignature() {
    const { remoteApprovalStore } = this.props;

    remoteApprovalStore.sendSignature();

    this.setState({ isDisabled: true });
    this.toggle();
  }

  toggle() {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  }

  render() {
    const { userId, jobId } = this.props;
    const {
      jobStore: { job, jobStatus },
      remoteApprovalStore,
    } = this.props;
    const {
      loading, loadingError, isDisabled, modal,
    } = this.state;

    if (loadingError) {
      return (<div>You can&apos;t view this estimate.</div>);
    }
    const {
      file,
      sendSignature,
    } = remoteApprovalStore;

    if (!remoteApprovalStore.estimate || !job || loading) {
      return null;
    }

    const isEstimateApprovable = jobStatus ? jobStatus.isEstimateApprovable : true;

    const {
      type,
      customer_detail,
      subtotal,
      tax,
      labor,
      parts_price,
      user_company_name,
      description_of_work,
      user_phone_number,
      signed_pdf_urlpath,
      company_license_number,
      receipt_note,
      approved,
      partner_brand,
      marketplace_job,
    } = remoteApprovalStore.estimate;
    const {
      email,
      address,
      phone_number,
    } = customer_detail;

    const { name } = customer_detail;

    if (approved && signed_pdf_urlpath) {
      window.location.replace(adjustPdfUrl(signed_pdf_urlpath));
    }

    const { reference_id } = job;
    remoteApprovalStore.setCustomerName(name);
    remoteApprovalStore.setUserId(parseInt(userId, 10));
    remoteApprovalStore.setJobId(parseInt(jobId, 10));

    const isInvoice = type === EstimateType.Invoice;
    const userPhoneNumber = formatPhoneNumber(user_phone_number);
    const phoneNumber = formatPhoneNumber(phone_number);

    return (
      <EstimateLayoutWrap>
        {
          !isEstimateApprovable && !approved && (
            <div className="estimates-common__call-containter">
              <span>
                This
                { isInvoice ? 'invoice' : 'estimate' }
                {' '}
                is out of date
              </span>
              {
                user_phone_number && (
                  <Button
                    color="primary"
                    size="lg"
                    block
                    className="estimates-common__call-button"
                  >
                    <a href={`tel:${user_phone_number}`}>
                      Call
                      {formatPhoneNumber(user_phone_number)}
                    </a>
                  </Button>
                )
              }
            </div>
          )
        }
        {isEstimateApprovable && !approved && (
          <>
            <EstimateLayout isInvoice={isInvoice}>
              <EstimateTechHeader
                logo={partner_brand?.logo_url}
                license={company_license_number}
                name={user_company_name}
                phone={userPhoneNumber}
              />

              <EstimateReference
                jobRefId={reference_id}
                brandColor={partner_brand?.hex_color}
              />

              <EstimateSummary
                customerName={name}
                customerPhone={phoneNumber}
                customerEmail={email}
                customerAddress={address}
                descriptionOfWork={description_of_work}
                maskPhone={marketplace_job}
              />

              <EstimateLayoutBody>
                <EstimateBreakdown
                  labor={labor}
                  partsPrice={parts_price}
                  tax={tax}
                  subtotal={subtotal}
                  isInvoice={isInvoice}
                />
              </EstimateLayoutBody>
              {
                // Display Warranty info or Notes based on the type
                receipt_note && (
                  isInvoice ? (
                    <WarrantyInfo
                      className="estimates-common__warranty"
                      content={receipt_note}
                    />
                  ) : (
                    <div className="estimates-common__notes">
                      <Text content={receipt_note} />
                    </div>
                  )
                )
              }

              {
                isEstimateApprovable && (
                  <>
                    <Input
                      className="remote-approval-page__name-input"
                      icon={IconInputPerson}
                      id="signature-name"
                      label="Your Name"
                      name={name}
                      setName={remoteApprovalStore.setCustomerName}
                    />
                    <div className="remote-approval-page__signature-container">
                      <SignaturePad
                        file={file}
                        setSignature={remoteApprovalStore.setSignature}
                      />
                    </div>
                    <div className="remote-approval-page__policy-notes-container">
                      <span className="remote-approval-page__footer-notes">
                        With my signature, I authorize the services described above,
                        {' '}
                        and agree to pay the full amount upon completion of the job.
                      </span>
                    </div>
                  </>
                )
              }
            </EstimateLayout>
            <div className="remote-approval-page__approve-button-container">
              {
                name && file
                  ? (
                    <Button
                      color="primary"
                      size="lg"
                      block
                      className="remote-approval-page__approve-button"
                      onClick={() => this.handleSendSignature(sendSignature, signed_pdf_urlpath)}
                      disabled={isDisabled}
                    >
                      APPROVE
                    </Button>
                  )
                  : (
                    <Button
                      color="primary"
                      size="lg"
                      block
                      className="remote-approval-page__approve-button-disabled"
                      disabled
                    >
                      APPROVE
                    </Button>
                  )
              }
            </div>
            <Modal
              isOpen={modal}
              toggle={this.toggle}
              centered
              modalClassName="remote-approval-page__loader-modal"
            >
              <img
                alt="loading"
                src={LoadingIcon}
                className="remote-approval-page__loader-modal__loader"
              />
            </Modal>
          </>
        )}
      </EstimateLayoutWrap>
    );
  }
}
RemoteApprovalPage.propTypes = {
  remoteApprovalStore: PropTypes.shape({
    estimate: MobXPropTypes.observableObject,
    file: MobXPropTypes.observableObject,
    loadEstimate: PropTypes.func.isRequired,
    setSignature: PropTypes.func.isRequired,
    sendSignature: PropTypes.func.isRequired,
    setCustomerName: PropTypes.func.isRequired,
    setUserId: PropTypes.func.isRequired,
    setJobId: PropTypes.func.isRequired,
    httpStatus: PropTypes.number,
  }).isRequired,

  jobStore: PropTypes.shape({
    job: MobXPropTypes.observableObject,
    jobStatus: MobXPropTypes.observableObject,
    loadJob: PropTypes.func.isRequired,
    loadJobStatus: PropTypes.func.isRequired,
  }).isRequired,

  userId: PropTypes.string,
  jobId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId, jobId } } } = ownProps;

    return {
      userId,
      jobId,
    };
  }),
  inject('jobStore'),
  inject('remoteApprovalStore'),
  observer,
)(RemoteApprovalPage);
