class IndependentProfileModel {
  constructor(independentProfile) {
    if (independentProfile) {
      this.agreement = independentProfile.agreement;
      this.availability = independentProfile.availability;
      this.complete = independentProfile.complete;
      this.availabilityToggle = independentProfile.availability_toggle;
      this.availabilityToggleState = independentProfile.availability_toggle_state;
      this.optInToMarketplace = independentProfile.opt_in_to_marketplace;
    }
  }
}

export default IndependentProfileModel;

export const AvailabilityToggle = {
  OVERRIDE_TO_AVAILABLE: 'OVERRIDE_TO_AVAILABLE',
  OVERRIDE_TO_UNAVAILABLE: 'OVERRIDE_TO_UNAVAILABLE',
  DEFAULT: 'DEFAULT',
};
