import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import qs from 'qs';

import { withProps, compose } from '../../lib/hocUtils';
import { formatPhoneNumber } from '../../lib/phone';
import PaymentType from '../../constants/PaymentType';
import FeedbackStatus from '../../constants/Feedback';
import {
  Intercom,
  ReceiptPageLayout,
  TotalPayment,
  Text,
  DescriptionOfWork,
  withAnalytics,
} from '../../components';
import {
  Feedback,
  PaymentLogDetails,
  PaymentDetails,
} from './components';

import './ReceiptPage.scss';

const RECEIPT_MODE = {
  PREVIEW: 'preview',
};

const hasCreditCardPayments = (receipt) => (
  receipt && receipt.paymentLog.some((payment) => payment.type === PaymentType.CARD)
);

class ReceiptPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedback: null,
      loading: false,
      loadingError: null,
      rating: null,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    const { receiptKey, isPreviewMode, receiptStore } = this.props;

    this.setState({ loading: true });
    receiptStore.loadReceipt(receiptKey, isPreviewMode)
      .catch((error) => {
        this.setState({ loadingError: error });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSubmitRating = (rating) => {
    this.setState({ rating });
  }

  onRatingChange = async () => {
    const {
      history,
      receiptKey,
      receiptStore,
      registerAnalyticsEvent,
    } = this.props;
    const { receipt, rating: currentRating } = receiptStore;
    const { rating } = this.state;

    if (currentRating && currentRating.rating) {
      return null;
    }

    if (rating && receipt) {
      await receiptStore.submitFeedback(receipt.jobId, rating, [], '');

      registerAnalyticsEvent({
        category: 'User Feedback',
        action: `Receipt feedback for job ${receipt.jobId}`,
        value: rating,
      });

      history.push(`/receipts/${receiptKey}/feedback?rating=${rating}&submitted=true`);
    }
  }

  renderPageContent() {
    const {
      receiptStore: { receipt, rating: currentRating },
      isPreviewMode,
      previewCustomerName,
      previewCustomerCompany,
      previewNotes,
    } = this.props;
    const {
      feedback,
      loading,
      loadingError,
      rating,
    } = this.state;

    if (loadingError) {
      return <div>Error trying to load receipt...</div>;
    }

    if (!receipt || loading) {
      return null;
    }

    const { techAffiliation, themeColor, logoUrl } = receipt;
    const customerName = isPreviewMode ? previewCustomerName : receipt.customerName;
    const customerCompany = isPreviewMode ? previewCustomerCompany : receipt.customerCompany;
    const notes = isPreviewMode ? previewNotes : receipt.notes;
    const descOfwork = receipt.descriptionOfwork;

    return (
      <div className="receipt-page">
        <section className="receipt-page__section receipt-page__section--centered">
          {
            !!techAffiliation && (
              <div className="receipt-page__tech_affiliation">{techAffiliation}</div>
            )
          }
          {
            !!logoUrl && (
              <div className="receipt-page__partner-brand-logo">
                <img
                  src={logoUrl}
                  className="mw-100 mh-100"
                  alt="logo"
                />
              </div>
            )
          }
          <h2 className="receipt-page__section-header">
            {receipt.serviceProviderCompany || 'N/A'}
          </h2>
          {
            receipt.license && (
              <div className="receipt-page__service-provider-license mt-2">
                License:
                {' '}
                {receipt.license}
              </div>
            )
          }
          {
            receipt.serviceProviderPhone && (
              <div className="mt-2">
                <a
                  className="receipt-page__service-provider-phone text-link"
                  href={`tel:${receipt.serviceProviderPhone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {
                    receipt.serviceProviderPhone.length > 11
                      ? receipt.serviceProviderPhone
                      : formatPhoneNumber(receipt.serviceProviderPhone)
                  }
                </a>
              </div>
            )
          }
        </section>

        <div className="receipt-page__job-ref-id" style={{ background: themeColor }}>
          Job Reference #
          {receipt.jobRefId}
        </div>
        <div className="receipt-page__divider" />

        <section className="receipt-page__section receipt-page__section--centered">
          <h2 className="receipt-page__section-header">Total Payment</h2>
          <TotalPayment className="receipt-page__total-payment" amount={receipt.payment.amount} />
          <div className="receipt-page__divider" />
        </section>

        {!!(receipt.payment.tip || receipt.payment.tax || receipt.refund) && (
          <section>
            <PaymentDetails
              subtotal={receipt.payment.subtotal}
              tax={receipt.payment.tax}
              tip={receipt.payment.tip}
              partsPrice={receipt.payment.partsPrice}
              labor={receipt.payment.labor}
              subtotalRefund={receipt.refund && receipt.refund.cost}
              taxRefund={receipt.refund && receipt.refund.tax}
              tipRefund={receipt.refund && receipt.refund.tip}
            />
            <div className="receipt-page__divider" />
          </section>
        )}

        {receipt.address && (
          <section>
            <h2 className="receipt-page__section-header">Service Location</h2>
            <div className="receipt-page__location">
              <div className="receipt-page__address">{receipt.address}</div>
              {receipt.addressMapUrl && (
                <div className="receipt-page__map-wrapper">
                  <img
                    className="receipt-page__map"
                    src={receipt.addressMapUrl}
                    alt="map"
                  />
                </div>
              )}
            </div>
          </section>
        )}
        {(customerName || customerCompany) && (
          <section>
            <h2 className="receipt-page__section-header">Billed To</h2>
            {customerName && (<div>{customerName}</div>)}
            {customerCompany && (<div>{customerCompany}</div>)}
            <div className="receipt-page__divider" />
          </section>
        )}
        {descOfwork && (
          <section>
            <div className="receipt-page__section-header">Scope of Work</div>
            <DescriptionOfWork text={descOfwork} />
            <div className="receipt-page__divider" />
          </section>
        )}
        {notes && (
          <section>
            <div className="receipt-page__section-header">Policies, guarantees, or notes</div>
            <Text content={notes} />
            <div className="receipt-page__divider" />
          </section>
        )}

        <section>
          <PaymentLogDetails
            className="receipt-page__payment-details"
            paymentLog={receipt.paymentLog}
            total={receipt.payment.amount}
            jobRefId={receipt.jobRefId}
            customerName={receipt.customerName}
            previewMode={isPreviewMode}
          />
        </section>

        <Feedback
          className="receipt-page__feedback"
          rating={rating}
          onClickRating={this.onRatingChange}
          onSubmitRating={this.handleSubmitRating}
          currentRating={currentRating && currentRating.rating}
        />
        <Intercom
          open={(
            receipt.jobIsMarketplace
            && feedback === FeedbackStatus.SAD
          )}
        />
      </div>
    );
  }

  render() {
    const { receiptStore: { receipt } } = this.props;

    if (!receipt) {
      return null;
    }

    const {
      occupation,
      createdAt,
      themeColor,
      jobRefId,
    } = receipt;

    const paymentProcessed = jobRefId && hasCreditCardPayments(receipt);

    return (
      <ReceiptPageLayout
        occupation={occupation}
        serviceDate={createdAt}
        paymentProcessed={paymentProcessed}
        themeColor={themeColor}
      >
        {this.renderPageContent()}
      </ReceiptPageLayout>
    );
  }
}
ReceiptPage.propTypes = {
  receiptStore: PropTypes.shape({
    receipt: MobXPropTypes.observableObject,
    rating: PropTypes.shape({
      rating: PropTypes.number,
    }),
    loadReceipt: PropTypes.func.isRequired,
    submitFeedback: PropTypes.func.isRequired,
  }).isRequired,
  receiptKey: PropTypes.string,
  isPreviewMode: PropTypes.bool,
  previewCustomerName: PropTypes.string,
  previewCustomerCompany: PropTypes.string,
  previewNotes: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  registerAnalyticsEvent: PropTypes.func,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { receiptKey } }, location: { search } } = ownProps;
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
    const isPreviewMode = queryParams.mode === RECEIPT_MODE.PREVIEW;

    return {
      receiptKey,
      isPreviewMode,
      previewCustomerName: queryParams.fullname,
      previewCustomerCompany: queryParams.company,
      previewNotes: queryParams.notes,
    };
  }),
  inject('receiptStore'),
  observer,
  withAnalytics(),
)(ReceiptPage);
