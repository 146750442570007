const days = {
  1: {
    letter: 'M',
    name: 'Monday',
  },
  2: {
    letter: 'T',
    name: 'Tuesday',
  },
  3: {
    letter: 'W',
    name: 'Wednesday',
  },
  4: {
    letter: 'T',
    name: 'Thursday',
  },
  5: {
    letter: 'F',
    name: 'Friday',
  },
  6: {
    letter: 'S',
    name: 'Saturday',
  },
  7: {
    letter: 'S',
    name: 'Sunday',
  },
};

export default days;
