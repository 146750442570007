/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import { withProps, compose } from '../../../lib/hocUtils';

import LoadingIcon from '../../../images/ConnectTechSettings/transparent-spinner.gif';
import BackArrowIcon from '../../../images/ConnectTechSettings/icon-left-arrow.svg';
import ErrorIcon from '../../../images/ConnectTechSettings/icon-error.svg';
import YellowForwardArrowIcon from '../../../images/ConnectTechSettings/icon-right-arrow-yellow.svg';

import './ConnectDeactivate.scss';
import DeactivateModal from '../../../components/ConnectTechSettings/DeactivateModal/DeactivateModal';

class ConnectDeactivate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  get independentProfile() {
    const { independentProfileStore } = this.props;
    return independentProfileStore ? independentProfileStore.independentProfile : null;
  }

  renderHolidaysAlert() {
    const { userId } = this.props;

    return (
      <Link
        to={`/connectTechSettings/${userId}/holidays`}
        className="text-decoration-none"
      >
        <div className="connect-tech-settings__deactivate__bottom-container__holiday-alert">
          <div className="connect-tech-settings__deactivate__bottom-container__holiday-alert__alert-icon">
            <img src={ErrorIcon} alt="edit" className="connect-tech-settings__deactivate__bottom-container__holiday-alert__alert-icon__img" />
          </div>
          <div className="connect-tech-settings__deactivate__bottom-container__holiday-alert__text">
            <p className="connect-tech-settings__deactivate__bottom-container__holiday-alert__text__primary">
              Going for a short break?
            </p>
            <p className="connect-tech-settings__deactivate__bottom-container__holiday-alert__text__secondary">
              Use holiday settings instead
            </p>
          </div>
          <div className="connect-tech-settings__deactivate__bottom-container__holiday-alert__arrow-icon">
            <img src={YellowForwardArrowIcon} alt="edit" className="connect-tech-settings__deactivate__bottom-container__holiday-alert__arrow-icon__img" />
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { history, independentProfileStore } = this.props;
    const { showModal } = this.state;

    if (!this.independentProfile) {
      return (<img className="connect-tech-settings__loading" src={LoadingIcon} alt="Loading" />);
    }

    return (
      <div className="connect-tech-settings__deactivate">
        {
          independentProfileStore.isError && (
            <div className="connect-tech-settings__error">Something went wrong, try again.</div>
          )
        }
        <img
          className="connect-tech-settings__back-arrow"
          src={BackArrowIcon}
          onClick={history.goBack}
          alt="Back"
        />
        <div className="connect-tech-settings__deactivate__title">
          <div className="connect-tech-settings__title__header">
            Connect Status
          </div>
        </div>
        <div className="connect-tech-settings__deactivate__body">
          <p>
            Deactivating Connect membership will suspend
            job offers from Jobox, regardless of your work
            schedule or Connect settings.
          </p>
          <ul>
            <li>
              Any open jobs will remain open until marked
              as completed.
            </li>
            <li>
              Any completed jobs will remain in your job
              history for review.
            </li>
            <li>
              Any completed and unpaid jobs will be paid
              out within XX days of deactivating Connect.
            </li>
          </ul>
          <p>
            You will be able to turn Connect back on at any
            time and resume receiving jobs.
          </p>
        </div>
        <div className="connect-tech-settings__deactivate__bottom-container">
          <button
            type="button"
            className="connect-tech-settings__deactivate__bottom-container__button"
            onClick={this.handleShowModal}
          >
            DEACTIVATE CONNECT
          </button>
        </div>
        <DeactivateModal
          isOpen={showModal}
          handleClose={this.handleCloseModal}
          history={history}
        />
      </div>
    );
  }
}

ConnectDeactivate.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  independentProfileStore: PropTypes.shape({
    isError: PropTypes.bool,
    independentProfile: MobxPropTypes.observableObject,
  }),
  userId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId } } } = ownProps;

    return {
      userId,
    };
  }),
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(ConnectDeactivate);
