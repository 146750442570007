import { action, observable, makeObservable } from 'mobx';

import { getTimezone } from '../lib/date';
import JobModel from '../models/JobModel';
import JobStatusModel from '../models/JobStatusModel';

class JobStore {
  job = null;
  jobStatus = null;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      job: observable,
      jobStatus: observable,

      loadJob: action,
      loadJobStatus: action,
    });
  }

  loadJob(jobId) {
    return this.apiService.getJob(jobId, getTimezone())
      .then(action((job) => {
        this.job = new JobModel(job);
      }));
  }

  loadJobStatus(jobId) {
    return this.apiService.getJobStatus(jobId, getTimezone())
      .then(action((job) => {
        this.jobStatus = new JobStatusModel(job);
      }));
  }
}

export default JobStore;
