/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';

import { withProps, compose } from '../../../lib/hocUtils';

import ForwardArrowIcon from '../../../images/ConnectTechSettings/icon-right-arrow.svg';
import LoadingIcon from '../../../images/ConnectTechSettings/transparent-spinner.gif';
import BackArrowIcon from '../../../images/ConnectTechSettings/icon-left-arrow.svg';

import './ConnectStatus.scss';
import AvailabilityModal from '../../../components/ConnectTechSettings/AvailabilityModal/AvailabilityModal';
import ConnectActivate from './ConnectActivate';
import { AvailabilityToggle } from '../../../models/ConnectTechSettings/IndependentProfileModel';

class ConnectStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  get independentProfile() {
    const { independentProfileStore } = this.props;
    return independentProfileStore ? independentProfileStore.independentProfile : null;
  }

  renderWorkingHoursSection() {
    const {
      userId,
    } = this.props;

    return (
      <Link
        to={`/connectTechSettings/${userId}/availability`}
        className="text-decoration-none"
      >
        <div className="connect-tech-settings__status__options-section__option">
          <div className="connect-tech-settings__status__options-section__title">
            I need to fix my working hours
          </div>
          <div className="connect-tech-settings__status__options-section__arrow">
            <img src={ForwardArrowIcon} alt="edit" />
          </div>
        </div>
      </Link>
    );
  }

  renderTodaySection() {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={this.handleShowModal}
      >
        <div className="connect-tech-settings__status__options-section__option">
          <div className="connect-tech-settings__status__options-section__title">
            {(this.independentProfile.availabilityToggleState === AvailabilityToggle.OVERRIDE_TO_UNAVAILABLE) ? 'I need to turn on Connect' : 'I need to turn off Connect for today'}
          </div>
          <div className="connect-tech-settings__status__options-section__arrow">
            <img src={ForwardArrowIcon} alt="edit" />
          </div>
        </div>
      </div>
    );
  }

  renderVacationSection() {
    const {
      userId,
    } = this.props;

    return (
      <Link
        to={`/connectTechSettings/${userId}/holidays`}
        className="text-decoration-none"
      >
        <div className="connect-tech-settings__status__options-section__option">
          <div className="connect-tech-settings__status__options-section__title">
            I need to take a day off/vacation
          </div>
          <div className="connect-tech-settings__status__options-section__arrow">
            <img src={ForwardArrowIcon} alt="edit" />
          </div>
        </div>
      </Link>
    );
  }

  renderDeactivateSection() {
    const {
      userId,
    } = this.props;

    return (
      <Link
        to={`/connectTechSettings/${userId}/status/deactivate`}
        className="text-decoration-none"
      >
        <div className="connect-tech-settings__status__options-section__option">
          <div className="connect-tech-settings__status__options-section__title">
            I need to deactivate Connect
          </div>
          <div className="connect-tech-settings__status__options-section__arrow">
            <img src={ForwardArrowIcon} alt="edit" />
          </div>
        </div>
      </Link>
    );
  }

  renderOptions() {
    return (
      <div className="connect-tech-settings__status__options-section">
        { this.renderWorkingHoursSection() }
        <div className="connect-tech-settings__status__options-section__divider" />
        { this.renderTodaySection() }
        <div className="connect-tech-settings__status__options-section__divider" />
        { this.renderDeactivateSection() }
        <div className="connect-tech-settings__status__options-section__divider" />
        { this.renderVacationSection() }
      </div>
    );
  }

  render() {
    const { showModal } = this.state;
    const { history, independentProfileStore } = this.props;

    if (!this.independentProfile) {
      return (<img className="connect-tech-settings__loading" src={LoadingIcon} alt="Loading" />);
    }

    if (!this.independentProfile.optInToMarketplace) {
      return (<ConnectActivate {...this.props} />);
    }

    return (
      <div className="connect-tech-settings__status">
        {
          independentProfileStore.isError && (
            <div className="connect-tech-settings__error">Something went wrong, try again.</div>
          )
        }
        <img
          className="connect-tech-settings__back-arrow"
          src={BackArrowIcon}
          onClick={history.goBack}
          alt="Back"
        />
        <div className="connect-tech-settings__status__title">
          <div className="connect-tech-settings__title__header">
            Connect Status
          </div>
        </div>
        <div className="connect-tech-settings__status__body">
          <p className="connect-tech-settings__status__body__info">
            Manage your availability.
          </p>
        </div>
        { this.renderOptions() }
        <AvailabilityModal
          isOpen={showModal}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

ConnectStatus.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  independentProfileStore: PropTypes.shape({
    isError: PropTypes.bool,
    independentProfile: MobxPropTypes.observableObject,
  }),
  userId: PropTypes.string,
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId } } } = ownProps;

    return {
      userId,
    };
  }),
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(ConnectStatus);
