import React from 'react';
import { DefaultPageLayout } from '../../components';

import './UnsubscribePage.scss';

const RequestSentPage = () => (
  <DefaultPageLayout className="unsubscribe-page">
    <div className="unsubscribe-page__content">
      <h1 className="unsubscribe-page__title">Why did I receive an email from Viewinvoice.com?</h1>

      <p className="mt-5">
        Viewinvoice.com is a service that technicians use to send receipts
        {' '}
        at the request of their customers.
        {' '}
        For more context on the service provided, note the company name
        {' '}
        or job type (e.g., Locksmith Services) on your receipt.
        {' '}
        Jobox does not use this email address for any marketing or other communication.
      </p>

      <h2 className="unsubscribe-page__title mt-5">If I didn’t request this receipt, what should I do?</h2>

      <p className="mt-5">
        If you did not request this receipt,
        {' '}
        it was likely sent by mistake to the wrong email address.
        {' '}
        In this case, you shouldn&apos;t be receiving additional emails in the future.
        {' '}
        If, for some reason, you continue to receive unwanted emails,
        {' '}
        please let us know and we can block all future receipts
        {' '}
        from any of the service technicians using
        {' '}
        <a className="text-link" href="https://viewinvoice.com">viewinvoice.com</a>
        .
      </p>

      <div className="mt-5">
        <strong>
          Still have questions? Start a conversation with us at
          {' '}
          <a className="text-link" href="mailto:help@viewinvoice.com">help@viewinvoice.com</a>
        </strong>
      </div>
    </div>
  </DefaultPageLayout>
);

export default RequestSentPage;
