const defaultTimeslot = [{
  display: '8:00-18:00',
  cross_day: null,
  start_time: '08:00',
  end_time: '18:00',
  start_hour: 8,
  end_hour: 18,
  start_min: 0,
  end_min: 0,
}];

const fullDayTimeslot = [{
  display: '0:00-23:59',
  cross_day: null,
  start_time: '00:00',
  end_time: '23:59',
  start_hour: 0,
  end_hour: 23,
  start_min: 0,
  end_min: 59,
}];

export {
  defaultTimeslot,
  fullDayTimeslot,
};
