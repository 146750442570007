/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { withProps, compose } from '../../lib/hocUtils';
import BackArrowIcon from '../../images/ConnectTechSettings/icon-left-arrow.svg';
import ActionIcon from '../../images/ConnectTechSettings/icon-action-top.svg';

import './Location.scss';

const [BACK, LEFT, UP, RIGHT, DOWN] = [8, 37, 38, 39, 40];
const ALWAYS_ENABLED_KEYS = [BACK, LEFT, UP, RIGHT, DOWN];

const handleZipKeyDown = (e) => {
  const key = e.which ? e.which : e.keyCode;
  if (e.target.value.length >= 5 && !ALWAYS_ENABLED_KEYS.includes(key)) {
    e.preventDefault();
  }
};

const handleZipPaste = (e) => {
  e.preventDefault();
  let pasteText = e.clipboardData.getData('text/plain');
  pasteText = pasteText.replace(/[^0-9]/g, '');
  let newContent = e.target.value + pasteText;
  newContent = newContent.substring(0, 5);
  e.target.value = newContent;
};

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  onZipChange(val) {
    const { connectTechSettingsStore } = this.props;
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val);
    if (isValidZip) {
      connectTechSettingsStore.updateBaseZip(val);
    }
  }

  getLocationTitle() {
    const {
      connectTechSettingsStore: {
        locationSettings: {
          locationServicesEnabled,
        },
      },
    } = this.props;

    switch (locationServicesEnabled) {
      case 'ALWAYS':
        return 'Location Always On';
      case 'WHILE_USING':
        return 'Location On While Using App';
      case 'NEVER':
        return 'Location Never On';
      default:
        return 'Location Services';
    }
  }

  renderModal() {
    return (
      <div className="connect-tech-settings__location__modal">
        <div className="connect-tech-settings__location__modal__title">
          Update location in Settings
        </div>
        <p className="connect-tech-settings__location__modal__body">
          Changing location from &quot;Always On&ldquo; may reduce
          {' '}
          the number of job matches you receive.
        </p>
        <div className="connect-tech-settings__location__modal__footer">
          <div
            className="connect-tech-settings__location__modal__footer__cancel"
            onClick={() => this.setState({ modalOpen: false })}
          >
            Cancel
          </div>
          <div
            className="connect-tech-settings__location__modal__footer__continue"
          >
            <a className="connect-tech-settings__location__modal__footer__continue__link" href="jobox://phone-location-settings">Continue </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { history, connectTechSettingsStore } = this.props;
    const { modalOpen } = this.state;

    return (
      <div className="connect-tech-settings__location">
        <img
          className="connect-tech-settings__back-arrow"
          src={BackArrowIcon}
          alt="Back"
          onClick={history.goBack}
        />
        <div className="connect-tech-settings__location__title">
          <div className="connect-tech-settings__title__header">Location</div>
        </div>
        {
          modalOpen && this.renderModal()
        }
        <div className="connect-tech-settings__location__body">
          <p className="connect-tech-settings__location__body__info">
            Jobox uses location data to match you with the closest jobs.
            {' '}
            <strong>Your exact location is never revealed, only your distance from a job.</strong>
          </p>
          <p className="connect-tech-settings__location__body__info">
            Set location to
            {' '}
            <strong>Always</strong>
            {' '}
            to ensure you get jobs even when Jobox is not open.
          </p>
          <div className="connect-tech-settings__location__body__zip">
            <div className="connect-tech-settings__location__body__zip__label">Base Zip</div>
            <input
              id="zip"
              name="zip"
              type="number"
              onKeyDown={handleZipKeyDown}
              onPaste={handleZipPaste}
              pattern="[0-9]*"
              className="connect-tech-settings__location__body__zip__input"
              defaultValue={connectTechSettingsStore.locationSettings.baseZip}
              onBlur={(e) => this.onZipChange(e.target.value)}
            />
          </div>
          <div className="connect-tech-settings__location__body__location-data">
            <div className="connect-tech-settings__location__body__location-data__header">
              <div className="connect-tech-settings__location__body__location-data__header__label">
                {this.getLocationTitle()}
              </div>
              <img
                src={ActionIcon}
                alt="go to settings"
                onClick={() => this.setState({ modalOpen: true })}
              />
            </div>
            <p className="connect-tech-settings__location__body__location-data__subheader">
              Jobox Connect collects location data to
              {' '}
              <strong>match you with jobs</strong>
              {' '}
              even when the app is closed or not in use.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
Location.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  connectTechSettingsStore: PropTypes.shape({
    locationSettings: PropTypes.shape({
      locationServicesEnabled: PropTypes.bool,
      baseZip: PropTypes.string,
    }),
    updateBaseZip: PropTypes.func,
  }),
};

export default compose(
  withProps((ownProps) => {
    const { match: { params: { userId } }, history } = ownProps;

    return {
      userId,
      history,
    };
  }),
  withRouter,
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
  })),
  observer,
)(Location);
