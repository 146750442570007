import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field } from 'formik';
import cn from 'classnames';

import config from '../../config';

const ReCaptchaInput = forwardRef(({ name, validateMessage, ...recaptchaProps }, ref) => (
  <Field
    name={name}
    validate={(requestToken) => (requestToken ? undefined : validateMessage)}
  >
    {
      ({ form, meta }) => (
        <ReCAPTCHA
          ref={ref}
          sitekey={config.RECAPTCHA_SITE_KEY}
          size="normal"
          onChange={(requestToken) => {
            form.setFieldValue(name, requestToken, true);
          }}
          className={cn({ 'is-invalid': (meta.touched || form.isSubmitting) && meta.error })}
          {...recaptchaProps}
        />
      )
    }
  </Field>
));

ReCaptchaInput.propTypes = {
  ...ReCAPTCHA.propTypes,
  sitekey: PropTypes.string,
  name: PropTypes.string.isRequired,
  validateMessage: PropTypes.string,
};
ReCaptchaInput.defaultProps = {
  validateMessage: 'Please check the Captcha box',
};

export default ReCaptchaInput;
