/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Label,
  Input,
  InputMask,
  CurrencyInput,
  Formik,
  Form,
  FormGroup,
  FormFeedback,
  ReCaptchaInput,
} from '../../../components';
import Yup from '../../../lib/yup';
import { parseDate } from '../../../lib/date';

import './HelpForm.scss';

const DATE_FORMAT = 'MM/DD/YYYY';

const validationSchema = Yup.object().shape({
  name: Yup.string().label('Name').required(),
  email: Yup.string().label('Email').email().required(),
  amount: Yup.string().label('Amount').required(),
  phone: Yup.string()
    .label('Phone')
    .matches(/\d{3}-\d{3}-\d{4}/i, '${path} should be in format ###-###-####'),
  date: Yup.string()
    .label('Date')
    .matches(/\d{2}\/\d{2}\/\d{4}/i, `\${path} should be in format ${DATE_FORMAT}`)
    .required()
    .test('valid-date', '${path} is invalid', (value) => !Number.isNaN(parseDate(value, DATE_FORMAT).toDate().getTime()))
    .test('no-future-dates', '${path} needs to be in the past', (value) => parseDate(value, DATE_FORMAT).toDate().getTime() < Date.now()),
  last4: Yup.string()
    .label('Last 4 digits')
    .matches(/\d{4}/i, '${path} mush be 4 digits')
    .required('${path} are required'),
  message: Yup.string().label('Message').required(),
});

class HelpForm extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
  }

  handleFormSubmit = async (formValues) => {
    const { onSubmit } = this.props;

    onSubmit({
      ...formValues,
      amount: parseFloat(formValues.amount, 10),
      date: parseDate(formValues.date, DATE_FORMAT).toDate(),
    });
  }

  render() {
    const { className, submitting } = this.props;
    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          amount: '',
          date: '',
          last4: '',
          sendCopy: true,
          message: '',
          captchaToken: undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={this.handleFormSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className={classnames('help-form', className)} noValidate>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Full Name"
                value={values.name}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.name && errors.name)}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                placeholder="email@example.com"
                value={values.email}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.email && errors.email)}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone">Phone (optional)</Label>
              <InputMask
                id="phone"
                name="phone"
                type="tel"
                className="help-form__phone"
                placeholder="###-###-####"
                mask="999-999-9999"
                value={values.phone}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.phone && errors.phone)}
              />
              <FormFeedback>{errors.phone}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="amount">Amount of charge</Label>
              <CurrencyInput
                id="amount"
                name="amount"
                className="help-form__amount"
                placeholder="$"
                value={values.amount}
                autoComplete="off"
                onValueChange={(value, name) => setFieldValue(name, value)}
                onBlur={handleBlur}
                invalid={!!(touched.amount && errors.amount)}
              />
              <FormFeedback>{errors.amount}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="date">Date of charge</Label>
              <InputMask
                id="date"
                name="date"
                type="tel"
                className="help-form__date"
                placeholder={DATE_FORMAT}
                mask="99/99/9999"
                value={values.date}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.date && errors.date)}
              />
              <FormFeedback>{errors.date}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="last4">Last 4 digits of credit card</Label>
              <InputMask
                id="last4"
                type="tel"
                pattern="[0-9]*"
                mask="9999"
                name="last4"
                className="help-form__last4"
                placeholder="####"
                value={values.last4}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.last4 && errors.last4)}
              />
              <FormFeedback>{errors.last4}</FormFeedback>
            </FormGroup>
            <FormGroup className="help-form__receipt-copy-group">
              <Label check>
                <Input
                  id="sendCopy"
                  type="checkbox"
                  name="sendCopy"
                  checked={values.sendCopy}
                  onChange={handleChange}
                />
                {' '}
                Send me a copy of my receipt
              </Label>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="message">Message</Label>
              <Input
                id="message"
                type="textarea"
                rows="3"
                name="message"
                placeholder="Please describe your question or issue in detail"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!(touched.message && errors.message)}
              />
              <FormFeedback>{errors.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="mt-3">
              <ReCaptchaInput
                ref={this.recaptchaRef}
                name="captchaToken"
              />
              <FormFeedback>{errors.captchaToken}</FormFeedback>
            </FormGroup>
            <Button type="submit" color="green" disabled={submitting} className="help-form__submit-button">
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        )}
      />
    );
  }
}

HelpForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default HelpForm;
