import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Header from '../Header';

import './DefaultPageLayout.scss';

const DefaultPageLayout = ({
  className,
  title,
  text,
  children,
}) => (
  <div className={classnames('default-page-layout', className)}>
    <Header title={title} text={text} />
    <div className="default-page-layout__content">
      {children}
    </div>
  </div>
);

DefaultPageLayout.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default DefaultPageLayout;
