import { pipe, find, get } from 'lodash/fp';
import { HolidayType } from '../../constants/HolidayType';

const createObservedHolidayMapper = (observedHolidays) => (
  (holiday) => ({
    id: holiday.id,
    calendarId: holiday.calendar_id,
    calendarName: pipe(
      find({ id: holiday.calendar_id }),
      get('name'),
    )(observedHolidays),
    name: holiday.name,
    startDate: holiday.start,
    endDate: holiday.end,
    type: HolidayType.Observed,
    toggled: holiday.toggled,
  })
);

const createUpcomingHolidayMapper = (mapObservedHoliday) => (
  (holiday) => {
    if (holiday.holiday_type === HolidayType.Observed) {
      return mapObservedHoliday(holiday);
    }
    return {
      id: holiday.id,
      startDate: holiday.start,
      endDate: holiday.end,
      type: holiday.holiday_type,
    };
  }
);

export default class UserHolidays {
  constructor(holidays) {
    const mapObservedHoliday = createObservedHolidayMapper(holidays.observed_holidays);

    this.observedHolidays = holidays.observed_holidays.map((holidayGroup) => ({
      id: holidayGroup.id,
      name: holidayGroup.name,
      holidays: holidayGroup.holidays.map(mapObservedHoliday),
    }));
    this.upcomingHolidays = holidays.upcoming_holidays.map(
      createUpcomingHolidayMapper(mapObservedHoliday),
    );
  }
}
